﻿import { global, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";

export interface SaopAttachmentListParams {
    validationFingerprintController: string,
    validationFingerprintAction: string,
    validationFingerprintBannerDivId: string,
    validationController: string,
    validationAction: string,
    validationBannerDivId: string,
    downloadController: string,
    downloadAction: string,
    deletedAttachmentListId?: string;
}

export class SaopAttachmentList {
    private _clibName: string;
    private _viewId: string;
    private _params: SaopAttachmentListParams;
    private _validationBanner: JQuery<HTMLElement>;

    constructor(viewId: string, libName: string = ""){
        this._clibName = libName;
        this._viewId = viewId;
    } 

    initComponent(params: SaopAttachmentListParams){ 
        global.ConsoleLogDegug("SaopAttachmentList.initComponent");
        this._params = params;

        this.initWebControls()

        this.initWebGridListners();
    }

    initWebControls(): void {
        // spraznimo html element, ki vsebuje ID priponk, ki se jih zbrise, 
        // se jih mora, ce se izvede post validacija edit forma in so napake na edit formu
        if (!isUnDefined(this._params.deletedAttachmentListId) && !isEmpty(this._params.deletedAttachmentListId)) {
            let element = $(this._params.deletedAttachmentListId);
            if (element.length) {
                element.val("");
            }
        }
    }

    initWebGridListners() {
        global.ConsoleLogDegug("SaopAttachmentList.initWebGridListners");

        let _this = this;

        $(this._viewId + " .attachment_download").off("click");
        $(this._viewId + " .attachment_download").on('click', function () {
            global.ConsoleLogDegug("SaopAttachmentList.attachment_download.click");

            let idDokumenta = this.getAttribute("data-iddokumenta");
            let fingerprint = this.getAttribute("data-iddokumentafingerprint");

            if (!isUnDefined(idDokumenta) && !isEmpty(idDokumenta)) {
                _this.downloadValidateAttachmentFingerPrint(idDokumenta, fingerprint);
            }
        });

        $(this._viewId + " .attachment_delete").off("click");
        $(this._viewId + " .attachment_delete").on('click', function () {
            global.ConsoleLogDegug("SaopAttachmentList.attachment_delete.click");

            let idDokumenta = this.getAttribute("data-iddokumenta");
            let fingerPrint = this.getAttribute("data-iddokumentafingerprint");

            if (!isUnDefined(idDokumenta) && !isEmpty(idDokumenta)) {
                let htmlDeleteElement = $(this).closest("tr")[0];
                _this.deleteValidateAttachmentFingerPrint(htmlDeleteElement, idDokumenta, fingerPrint);
            }
        });
    }

    downloadValidateAttachmentFingerPrint(idDokumenta: string, fingerPrint: string): void {
        global.ConsoleLogDegug("SaopAttachmentList.downloadValidateAttachmentFingerPrint");

        let _this = this;
        let params = { documentID: idDokumenta, fingerPrint: fingerPrint };
        this._validationBanner = $(this._params.validationFingerprintBannerDivId);

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            if (response.success == false) {
                _this._validationBanner.html(response.viewContent);
                _this._validationBanner.focus();

                return;
            }

            // ni napake, validate priponko
            _this.downloadValidateAttachment(idDokumenta);
        };
        saopServicesCore.ExecuteAction({
            action: this._params.validationFingerprintController + "/" + this._params.validationFingerprintAction
            , data: params
            , type: "POST"
        });
    }

    downloadValidateAttachment(idDokumenta: string): void {
        global.ConsoleLogDegug("SaopAttachmentList.downloadValidateAttachment");

        let _this = this;
        let params = { documentID: idDokumenta };
        this._validationBanner = $(this._params.validationBannerDivId);

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            if (response.success == false) {
                _this._validationBanner.html(response.viewContent);
                _this._validationBanner.focus();

                return;
            }

            // ni napake, download priponko
            _this.downloadAttachment(idDokumenta);
        };
        saopServicesCore.ExecuteAction({
            action: this._params.validationController + "/" + this._params.validationAction
            , data: params
            , type: "POST"
        });
    }

    downloadAttachment(idDokumenta: string): void {
        global.ConsoleLogDegug("SaopAttachmentList.downloadAttachment");

        let params = { documentID: idDokumenta };
        let saopServicesCore = new SaopServicesCore();
        
        saopServicesCore.ExecuteBlobAction({
            action: this._params.downloadController + "/" + this._params.downloadAction
            , data: params
        });
    }

    deleteValidateAttachmentFingerPrint(htmlElement: HTMLElement, idDokumenta: string, fingerPrint: string): void {
        global.ConsoleLogDegug("SaopAttachmentList.deleteValidateAttachmentFingerPrint");

        let _this = this;
        let params = { documentID: idDokumenta, fingerPrint: fingerPrint };
        this._validationBanner = $(this._params.validationFingerprintBannerDivId);

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            if (response.success == false) {
                _this._validationBanner.html(response.viewContent);
                _this._validationBanner.focus();

                return;
            }

            // ni napake
            _this.deleteValidateAttachment(htmlElement, idDokumenta);
        };
        saopServicesCore.ExecuteAction({
            action: this._params.validationFingerprintController + "/" + this._params.validationFingerprintAction
            , data: params
            , type: "POST"
        });   
    }

    deleteValidateAttachment(htmlElement: HTMLElement, idDokumenta: string): void {
        global.ConsoleLogDegug("SaopAttachmentList.deleteValidateAttachment");

        htmlElement.remove();

        if (!isUnDefined(this._params.deletedAttachmentListId) && !isEmpty(this._params.deletedAttachmentListId)) {
            // id dokumenta dodamo v seznam brisanih priponk,
            // da se lahko pri shranjevanjnu dokumenta(komponenta je lahko na edit formah) lahko na serverju pobrisejo 
            let element = $(this._params.deletedAttachmentListId);
            if (element.length) {
                let elemetValue = element.val() + "," + idDokumenta;
                element.val(elemetValue);
            }
        }
    }
}






