import { LIKVCakalnaVrstaGrid } from "../LIKV/LIKVCakalnaVrsta-grid";
import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";

export class DashboardLIKVConfirmationView {
    private _libName:string;
    private _saopServicesCore: SaopServicesCore;
    private likvCakalnaVrstaGrid: LIKVCakalnaVrstaGrid;
    //

    constructor(libName: string = "") {
        this._libName = libName;
        //
        this._saopServicesCore = new SaopServicesCore();
        this.likvCakalnaVrstaGrid = new LIKVCakalnaVrstaGrid(libName +".likvCakalnaVrstaGrid");
        this.likvCakalnaVrstaGrid.refreshData = this.refreshGridData.bind(this);
    }

    refreshGridData(): void {
        global.ConsoleLogDegug("DashboardLIKVConfirmationView.refreshGridData");
        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            let _grid = $(response).find("#LIKVCakalnaVrstaGrid")[0];
            $(_grid).find("#dt-checkboxes-cell").hide;
          
            let _body = $(_grid).find("#LIKVCakalnaVrstaGridBodyAjax"); //poiscem tbody
           // $(_body).find("tr:first").html('lalala');
            $(_body).find("tr").each(function() { //za vsak tr v prvem td pobrisem value
                $(this).find("td:eq(0)").html('');
                // $(this).find(".LIKVCakalnaVrstaGrid_confirm").html('');
                // $(this).find(".LIKVCakalnaVrstaGrid_deny").html('');
                $(this).find(".td-icon-col").html('');     
            });
            $("#card6 .card-body").html(_grid);
            _this.likvCakalnaVrstaGrid._dashboard = true;
            _this.likvCakalnaVrstaGrid.initView();            
        };
        this._saopServicesCore.ExecuteAction({
            action:"Dashboard/LIKVConfirmationPartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingcard6"
        });   
    }    
   
    //
}