﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopRightSidebar,SaopRightSidebarView } from '../Components/saop-right-sidebar';
import { SaopRegKeZapDopustiView } from "./saop-reg-keZapDopusti-view";

export class SaopRegAbsenceCalendarEventsView extends SaopRegKeZapDopustiView {
    private _saopServicesCore :SaopServicesCore;
    private _yearCaption: string = "yearCaption";
    private _btnPreviousId: string = "btnYearPrevious";
    private _btnNextId: string = "btnYearNext";
    private _addKEZapDopustiId: string = "addKEZapDopustiVnos";

    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);
        this.refreshData = this.refreshAllData.bind(this);
        //
        this._saopServicesCore = new SaopServicesCore();
    }

    initView(): void {
        global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.initView");
    }

    bindGridEvents(){
        global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.bindGridEvents");
        let _this = this;

        $("#" + this._btnPreviousId).off("click");
        $("#" + this._btnPreviousId).click(function () {
            global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.btnPrevious.click");
            let leto = _this.getCurrentCalendarYear() - 1;
            _this.refreshCalendarAllMonthsData(leto);
        });

        $("#" + this._btnNextId).off("click");
        $("#" + this._btnNextId).click(function () {
            global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.btnNext.click");
            let leto = _this.getCurrentCalendarYear() + 1;
            _this.refreshCalendarAllMonthsData(leto);
        });

        $("#" + this._addKEZapDopustiId).off("click");
        $("#" + this._addKEZapDopustiId).on('click', function(row){
            global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.addKEZapDopustiVnos.click");
            _this.addRecord({});
        });                
    }

    refreshAllData(trigger: boolean = true): void {
        global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.refreshAllData");
        let leto = this.getCurrentCalendarYear();

        this.refreshCalendarAllMonthsData(leto);
        this.refreshRightSidebarData();

        if (trigger && this.afterRefreshAllData != null) {
            this.afterRefreshAllData();
        }
    }

    refreshCalendarAllMonthsData(leto: number): void {
        global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.refreshCalendarAllMonthsData");
        let _this = this;

        this._saopServicesCore.afterDone = function(response){
            $("#regAbsenceCalendar").html(response);
            _this.bindGridEvents();
        };
        this._saopServicesCore.ExecuteAction({
            action:"REGAbsence/MyAbsencesYearCalendarPartial"
            ,type: "GET"
            ,data: { leto: leto }
            ,spinnerDivId:"#saopLoading"
        });         
    }

    refreshRightSidebarData(): void {
        global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.refreshRightSidebarData");
        let _this = this;

        let _saopRightSidebarDopust: SaopRightSidebar = new SaopRightSidebar();
        _saopRightSidebarDopust.refreshDopustZaKoriscenjeSummary("#myAbsenceCalendarDopustZaKoriscenjeSummary", "saopLoadingSidebarrMyAbsencesCalendar");

        let _saopRightSidebarUre: SaopRightSidebar = new SaopRightSidebar();
        _saopRightSidebarUre.refreshUreZaKoriscenjeSummary("#myAbsenceCalendarUreZaKoriscenjeSummary", "saopLoadingSidebarrMyAbsencesCalendar");
    }

    afterRefreshAllData(): void {
        global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.afterRefreshAllData");
    }

    getCurrentCalendarYear(): number {
        global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.getCurrentCalendarYear");
        return $("#" + this._yearCaption).data("year");
    }
}


