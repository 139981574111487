﻿import { global, isUnDefined, setModalFormZIndex } from "../Common/saop-common";
//import { SaopRightSidebar,SaopRightSidebarView } from '../Components/saop-right-sidebar';
import { NDKInternalOrdersActiveGrid } from "./NDKInternalOrders-Active-grid";
import { NDKInternalOrdersArchivedGrid } from "./NDKInternalOrders-Archived-grid";

export class NDKInternalOrders {
    private libName: string;

    private ndkInternalOrdersActiveGrid: NDKInternalOrdersActiveGrid;
    private ndkInternalOrdersArchivedGrid: NDKInternalOrdersArchivedGrid;
    //public rightSidebar: SaopRightSidebar;
    
    constructor(libName: string) {
        this.libName = libName; 

        this.ndkInternalOrdersActiveGrid = new NDKInternalOrdersActiveGrid(this.libName + ".ndkInternalOrdersActiveGrid");
        this.ndkInternalOrdersActiveGrid.afterRefreshDataAfterEditViewExternal = this.refreshDataAfterEditView.bind(this);

        this.ndkInternalOrdersArchivedGrid = new NDKInternalOrdersArchivedGrid(this.libName + ".ndkInternalOrdersArchivedGrid");
        this.ndkInternalOrdersArchivedGrid.copyArchiveInternalOrderExternal = this.copyInternalOrder.bind(this);
    }  

    initView() {
        global.ConsoleLogDegug("NDKInternalOrders.initView");

        // active internal orders
        this.ndkInternalOrdersActiveGrid.initView();

        // archived internal orders
        this.ndkInternalOrdersArchivedGrid.initView();

        // init sidebar right
        //this.initRightSidebar();
    }

    //initRightSidebar() {
    //    global.ConsoleLogDegug("NDKInternalOrders.initRightSidebar");

    //    this.rightSidebar = new SaopRightSidebar();
    //    this.rightSidebar.bindCarouselDiv("#right-sidebar-ajax",SaopRightSidebarView.InternalOrders);
    //    this.rightSidebar.refreshNdkInternalOrdersSummary("#ndkInternalOrdersSummary","#saopLoadingSidebarr");    
    //}

    refreshDataAfterEditView(): void { 
        global.ConsoleLogDegug("NDKInternalOrders.refreshDataAfterEditView");

        //this.rightSidebar.refreshNdkInternalOrdersSummary("#ndkInternalOrdersSummary", "#saopLoadingSidebarr");  
    }

    // metoda iz archived grid klasa
    copyInternalOrder(checkbox: JQuery<HTMLInputElement>): void {
        global.ConsoleLogDegug("NDKInternalOrders.copyInternalOrder");

        this.ndkInternalOrdersActiveGrid.copyInternalOrder(checkbox);
    }
  //
}






