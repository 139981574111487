﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global, relodeCurrentPage } from "../Common/saop-common";
import { SaopViewForm } from "../core/saop-view-form";
import { OPNRelacijeSelect } from "../OPN/OPNRelacije-select";
import { OPNNalogeSelect } from "../OPN/OPNNaloge-select";

export class SaopAppSettingsView extends SaopViewForm { 
    //private _viewForm:SaopViewForm;
    public _opnRelacijeSelect:OPNRelacijeSelect;
    public _opnNalogeSelect:OPNNalogeSelect;

    constructor(viewId:string,libName:string = ""){
        super(viewId,libName);
        //this.title = this.translationService.translate("");
        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "";
        this.deleteRecordEndpoint = "";          
        //  
        this._opnRelacijeSelect = new OPNRelacijeSelect(this._libName+"._opnRelacijeSelect",{inputWidthPX:"360px"});
        this._opnNalogeSelect = new OPNNalogeSelect(this._libName+"._opnNalogeSelect",{inputWidthPX:"360px"});
    }

    setDictionary(){
        let dictionary:string[] = ["Nastavitve"];
        this.setTerminologyList(dictionary);
    }  

    initView(): void {
        global.ConsoleLogDegug("SaopAppSettingsView.initView");
        //
        this._opnRelacijeSelect.init("#SettingsOPN_PrivzetaSifraRelacije");
        this._opnNalogeSelect.init("#SettingsOPN_PrivzetaSifraNaloge");
        //
        this.bindViewEvents();   
        this.setWebDodajanjePnZaSopotnika();
    }

    bindViewEvents(){
        global.ConsoleLogDegug("SaopAppSettingsView.bindEvents");
        let _this = this;
        //
        $(".saop-sidebar-container .saop-sb-item").off("click");
        $(".saop-sidebar-container .saop-sb-item").on('click', function (e) {
            //
            let _currentTarget = e.currentTarget.id;
            var item = $('#'+_currentTarget);

            $(".saop-sidebar-container .saop-sb-item-selected").removeClass("saop-sb-item-selected");//'un-color' previous item

            item.addClass("saop-sb-item-selected");//color item

            if (_currentTarget == "AppSettingsCommon") {
                $("#AppSettingsCommonPan").css("display", "");
                $("#AppSettingsREGPan").css("display", "none");
                $("#AppSettingsKEPan").css("display", "none");
                $("#AppSettingsOPNPan").css("display", "none");
            } 
            else if (_currentTarget == "AppSettingsREG") {
                $("#AppSettingsCommonPan").css("display", "none");
                $("#AppSettingsREGPan").css("display", "");
                $("#AppSettingsKEPan").css("display", "none");
                $("#AppSettingsOPNPan").css("display", "none");                
            }
            else if (_currentTarget == "AppSettingsKE") {
                $("#AppSettingsCommonPan").css("display", "none");
                $("#AppSettingsREGPan").css("display", "none");
                $("#AppSettingsKEPan").css("display", "");
                $("#AppSettingsOPNPan").css("display", "none");                 
            }
            else if (_currentTarget == "AppSettingsOPN") {
                $("#AppSettingsCommonPan").css("display", "none");
                $("#AppSettingsREGPan").css("display", "none");
                $("#AppSettingsKEPan").css("display", "none");
                $("#AppSettingsOPNPan").css("display", "");                
            }
        });  
        
        $("#headerCloseButton").click(function () {
            $("#btnCancel").click();
        });        

        $('#SettingsOPN_WebDodajanjeSopotnika').change(function() {
            _this.setWebDodajanjePnZaSopotnika();
        });        
  
        $('#SettingsOPN_WebEmailObvescanje').change(function() {
            _this.setWebEmailZaObvescanje();
        });           

    }

    setWebDodajanjePnZaSopotnika():void {
        if($('#SettingsOPN_WebDodajanjeSopotnika').is(":checked")) {
            $("#WebDodajanjePnZaSopotnikaGroup").css("visibility","unset");
        } else {
            $("#SettingsOPN_WebDodajanjePnZaSopotnika").prop('checked', false);
            $("#WebDodajanjePnZaSopotnikaGroup").css("visibility","hidden");                
        }        
    }

    setWebEmailZaObvescanje():void {
        if($('#SettingsOPN_WebEmailObvescanje').is(":checked")) {
            $("#WebEmailZaObvescanjeGroup").css("visibility","unset");
        } else {
            $("#WebEmailZaObvescanjeGroup").css("visibility","hidden");                
        }        
    }

    //
}


