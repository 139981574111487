﻿import { global, isUnDefined, isEmpty } from "../Common/saop-common";
import { SaopWfAction } from "../Core/saop-view-form";
import { SaopMultiselectOptions } from "../Components/saop-multiselect";
import { SaopSelectDropDown } from "../Components/saop-select-dropdown";
import { OPNRelacijeView } from "../OPN/OPNRelacije-view";
import { SaopServicesCore, SaopServicesCoreParams } from "../Core/saop-services-core";

export class OPNRelacijeSelect extends SaopSelectDropDown {
  // private __target:string;
  // private __caption:string;  
  private __libName:string;
  public _opnRelacijeView:OPNRelacijeView;

  constructor(libName:string = "", options:SaopMultiselectOptions = null) {
    global.ConsoleLogDegug("OPNRelacijeSelect");
    super(libName,options);
    this.__libName = libName;   
    //
    this._opnRelacijeView = new OPNRelacijeView("#opnRelationsPopupForm",this.__libName  + "._opnRelacijeView");
    this._opnRelacijeView.fullScreen = false;   
    this._opnRelacijeView.afterSubmitSuccess = this.refreshDataAfterSubmit.bind(this);     
  } 

  init(target: string = "", caption = "" ){
    // this._target = target;
    // this._caption = caption;
    //
    if (target.length == 0){
      target = "SifraRelacije";
    }
    if (caption.length == 0){
      caption = "Relacije";
    }
    super.init(target, caption);
    //
  }

  addRecord(){
    let _fullTitle = this._opnRelacijeView.translationService.translate("Relacije");
    this._opnRelacijeView.fullTitle = _fullTitle;
    this._opnRelacijeView.showViewForm(SaopWfAction.wfaAddRecord,{ });     
  }  

  editRecord(_sifraRelacije:string){
    let _fullTitle = this._opnRelacijeView.translationService.translate("Relacije");
    this._opnRelacijeView.fullTitle = _fullTitle;
    this._opnRelacijeView.showViewForm(SaopWfAction.wfaEditRecord,{ sifraRelacije: _sifraRelacije, fullView: false });     
  }  

  public onAddBtnClick(sender:any):void{
      global.ConsoleLogDegug("onAddBtnClick...SifraLokacije");
      this.addRecord();
  }  

  public onEditBtnClick(sender:any):void{
      global.ConsoleLogDegug("onEditBtnClick...SifraLokacije");
      let _sifraRelacije = this.selectedCB.val();
      this.editRecord(_sifraRelacije);    
  }  

  public refreshDataAfterSubmit(result: any): void {
    global.ConsoleLogDegug("submit.afterExecuteSuccess");
    //
    if ( (isUnDefined(result.success) == false) && (result.success == true) ) {
      let _sifraRelacije = "";
      if (isUnDefined(result.data) == false) {
        let jsonData = JSON.parse(result.data);
        _sifraRelacije = jsonData.SifraRelacije;
      }    
      //
      let _servicesParams:SaopServicesCoreParams = {
        action: "OpnRelations/GetList"
        , data: {sifraRelacije:_sifraRelacije}
        , type: "GET"
      };
  
      this.getDropDownItemsData(_servicesParams, _sifraRelacije);
    } 
  }

  //
}






