﻿import { SaopViewForm } from "../core/saop-view-form";
import { SaopWfSubmitResult } from "../core/saop-view-form";
import { SaopMessageDlg, SaopMsgDlgButton } from '../Components/saop-message-dlg';
import { global} from "../Common/saop-common";


export class SaopRegKeZapDopustiView extends SaopViewForm {
    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);
        this.setDictionary();                
        this.title = this.translationService.translate("Odsotnost");        
        this.addRecordEndpoint = "KeZapDopusti/CreateKeZapDopusti";
        this.editRecordEndpoint = "";
        this.deleteRecordEndpoint = "KeZapDopusti/DeleteKeZapDopusti";
    }  
    
    setDictionary(){
        let dictionary: string[] = ["Odsotnost"
            , "Ali ste prepričani, da želite izbrisati odsotnost?"
            , "Potrdi"
            , "Pozor, število dni predlaganega dopusta je večje kot število dni, ki ga imate na razpolago. Želite nadaljevati?"
            , "Pozor, vpisano število ur predlaganega koriščenja je večje od števila ur, ki jih imate na razpolago. Ali želite nadaljevati?"];
        this.setTerminologyList(dictionary);
    }     

    public afterSubmitSuccess(result: SaopWfSubmitResult): void {
        let prikaziOpozoriloStr = result.alertMessage.toString();
        let prikaziOpozorilo = prikaziOpozoriloStr == "#PrekoracitevOdsotnostiOpozorilo" ? true : false;

        let opozoriloStr = $("#PrekoracitevOdsotnostiOpozorilo").val();// ne obstaja v primeru ko se dopust doda in niso nastavljene potrebne nastavitve za avtomatsko potrjevanje
        if (opozoriloStr !== undefined) {
            let opozorilo = opozoriloStr === "" ? false : true;

            if (opozorilo && prikaziOpozorilo) {
                let title = this.translationService.translate("Potrdi");

                let message = "";
                if ($("#editFormVrstaDopusta").val() == 'T') {
                    message = this.translationService.translate("Pozor, število dni predlaganega dopusta je večje kot število dni, ki ga imate na razpolago. Želite nadaljevati?");
                }
                else if ($("#editFormVrstaDopusta").val() == 'K') {
                    message = this.translationService.translate("Pozor, vpisano število ur predlaganega koriščenja je večje od števila ur, ki jih imate na razpolago. Ali želite nadaljevati?");
                }

                let saopMessageDlg = new SaopMessageDlg({ data: null, parent: null })
                saopMessageDlg.onYes = this.proceedYes.bind(this);
                saopMessageDlg.onNo = this.proceedNo.bind(this);
                saopMessageDlg.Show(message, title, 1, [SaopMsgDlgButton.mbYes, SaopMsgDlgButton.mbNo]);
            }
        }
    }
    
    proceedYes(data: any): void {
        global.ConsoleLogDegug("CreateKeZapDopusti.Alert.proceedYes");
        $("#PrekoracitevOdsotnostiOpozorilo").val("");
        $('#regKeZapDopustiView').first().trigger("submit");
    }

    proceedNo(data: any): void {
        global.ConsoleLogDegug("CreateKeZapDopusti.Alert.proceedNo");
        $("#PrekoracitevOdsotnostiOpozorilo").val("true");
    }
}


