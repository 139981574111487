﻿import { global } from "../Common/saop-common";

export class SaopCheckboxSwitch {

    init(parentElement: string = "") {
        this.checkboxSwitchOnClick(parentElement);
        this.switchLabelOnClick(parentElement);
    }

    checkboxSwitchOnClick(parentElement: string) {
        global.ConsoleLogDegug("saop-switch-checkbox...");
        global.ConsoleLogDegug("saop-switch-checkbox parentElement: " + parentElement);

        let _frame = $("" + parentElement + " *>.saop-switch-checkbox");
        _frame.off("click");
        _frame.click(function (event: any) {
            let _checkBox = event.target;
            let _atrParent = "#"+$(_checkBox).attr("for");
            let _checkBoxP = $("" + parentElement + " *>" + _atrParent);
            let _label1 = _checkBoxP.find("#saop-switch-label1");
            let _label2 = _checkBoxP.find("#saop-switch-label2");
            if (_checkBox.checked) {
                global.ConsoleLogDegug("checked");
                _label1.removeClass('switch-label-on');
                _label1.addClass('switch-label-off');
                _label2.removeClass('switch-label-off');
                _label2.addClass('switch-label-on');
            } else {
                global.ConsoleLogDegug("unchecked");
                _label1.removeClass('switch-label-off');
                _label1.addClass('switch-label-on');
                _label2.removeClass('switch-label-on');
                _label2.addClass('switch-label-off');
            }
        });
    }

    switchLabelOnClick(parentElement: string) {
        global.ConsoleLogDegug("saop-switch-label...");
        global.ConsoleLogDegug("saop-switch-label parentElement: " + parentElement);

        let _frame = $("" + parentElement + " *>.saop-switch-label");
        _frame.off("click");
        _frame.click(function (event: any) {
            let _switchLabel = event.target;
            let _atrCheckBox = "#"+$(_switchLabel).attr("for");
            let _checkBox = $(_atrCheckBox);
            $(_checkBox).prop( 'checked', 'flase');
        });
    }

    //
}






