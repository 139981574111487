﻿/// <reference path="../@types/saop-core/saopSelectizeSelect.d.ts" />

import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";

export class SaopSelectizeSelectWraper {
    private _viewId : string;    
    private _libName : string;

    constructor(viewId: string = "",libName: string = "") {
        global.ConsoleLogDegug("SaopSelectizeSelectWraper.constructor");
        this._viewId = viewId;
        this._libName = libName;
    }

    public initAll(className:string = ".selectizeSelect"):void {
        global.ConsoleLogDegug("SaopSelectizeSelectWraper.initAll");
        var allDivs = document.querySelectorAll<HTMLElement>(className);

        allDivs.forEach(element => {
            global.ConsoleLogDegug("element.init......................" + element.id);
            if (isEmpty(element.id) == false){
                let _elementId = "#"+element.id;
                let _saopSelectizeSelect = new icSaopCore.SaopSelectizeSelectEx(this._viewId,_elementId,this._libName);
                _saopSelectizeSelect.initControlByHtml(element);
            }
        });
    }

    //
}





