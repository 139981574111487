﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopViewFormBindings } from "../Core/saop-view-form-bindings";
import { SaopWfSubmitResult } from "../core/saop-view-form";


export class SaopRegCurrentEventView extends SaopViewFormBindings {

    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);
    }

    onSubmitSuccess(data: SaopWfSubmitResult): void {
        global.ConsoleLogDegug("SaopRegCurrentEventView.onAddDataSuccess");
        if (data.success) {
            alertify.message(data.alertMessage);
        }
        else{
            alertify.error(data.alertMessage);
        }
        this.afterSubmitSuccess(data);
    }

    public afterSubmitSuccess(result: SaopWfSubmitResult): void {
        global.ConsoleLogDegug("SaopRegCurrentEventView.afterExecuteSuccess");
    }    

    //
}


