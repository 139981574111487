﻿/// <reference path="../@types/bootstrap-multiselect/index.d.ts" />
import { SaopTranslationService } from '../Core/saop-translate';
import { global,isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopPopover } from './saop-popover';

export class ObdobjefilterValues {
    leto: number;
    mesec: number;
    mesecNaziv: string;
}

export class SaopSelectMonthYear {
    private _viewId: string;
    private _controlId: string;
    private _libName: string;

    private _obdobjeId: string;
    private _calendarIconId: string;
    private _obdobjeFilterLetoId: string;
    private _obdobjeFilterMesecId: string;
    private _obdobjeCurrentBtnId: string;
    private _popOverObdobjeBodyId: string;
    private _previousMonthBtnId: string;
    private _nextMonthBtnId: string;

    private _translationService: SaopTranslationService;
    private _saopPopover: SaopPopover;

    public _refreshingRecordsInProgress: boolean;

    constructor(viewId: string, controlId: string, libName: string = "") {
        global.ConsoleLogDegug("SaopSelectMonthYear");

        this._viewId = viewId;
        this._controlId = controlId;
        this._libName = libName;

        this._refreshingRecordsInProgress = false;
        this._obdobjeId = "#Obdobje";
        this._calendarIconId = "#calendarIcon";
        this._obdobjeFilterLetoId = "#letoObdobje";
        this._obdobjeFilterMesecId = "#MesecString";
        this._obdobjeCurrentBtnId = "#currentMonthBtnFilter";
        this._popOverObdobjeBodyId = "#popOverObdobjeBody";
        this._previousMonthBtnId = "#previousMonthBtnFilter";
        this._nextMonthBtnId = "#nextMonthBtnFilter";

        this._translationService = new SaopTranslationService();

        this._saopPopover = new SaopPopover();
        this._saopPopover.afterPopoverShow = this.afterPopoverShow.bind(this); 
    } 

    private afterPopoverShow(): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.afterPopoverShow");

        let obdobjeData = this.getObdobjeDataAttributes(this._obdobjeId);

        // osvezimo vrednosti filtrov iz data attributes
        this.setObdobjeFilterValues(obdobjeData);

        // prikazi okno
        var filterBody = $("div.popover.show").find(this._popOverObdobjeBodyId);
        filterBody.show();

        // bind popup events
        this.bindPopupEvents();
    }

    private getObdobjeDataAttributes(id: string): ObdobjefilterValues {
        global.ConsoleLogDegug("SaopSelectMonthYear.getObdobjeDataAttributes");

        let obdobjeElement = $(this._obdobjeId);

        let dataAttributes = new ObdobjefilterValues();
        dataAttributes.leto = parseInt(obdobjeElement.attr("data-leto"));
        dataAttributes.mesec = parseInt(obdobjeElement.attr("data-mesec"));
        dataAttributes.mesecNaziv = obdobjeElement.attr("data-mesec-naziv");

        return dataAttributes;
    }

    private setObdobjeFilterValues(filterValues: ObdobjefilterValues): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.setObdobjeFilterValues");

        let oldfilterValues = this.getObdobjefilterValues();

        // leto
        if (oldfilterValues.leto != filterValues.leto) {
            let letoElement = $("div.popover.show").find(this._obdobjeFilterLetoId);
            if (letoElement.length) {
                letoElement.val(filterValues.leto);
            }
        }

        // mesec
        if (oldfilterValues.mesec != filterValues.mesec) {
            let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
            if (mesecElement.length) {
                mesecElement.val(filterValues.mesec.toString());
            }
        }
    }

    private getObdobjefilterValues(): ObdobjefilterValues {
        global.ConsoleLogDegug("SaopSelectMonthYear.getObdobjefilterValues");

        let filterValues = new ObdobjefilterValues();
        filterValues.leto = null;
        filterValues.mesec = null;
        filterValues.mesecNaziv = "";

        // dolocimo leto
        let letoElement = $("div.popover.show").find(this._obdobjeFilterLetoId);
        if (letoElement.length) {
            let letoStr = String(letoElement.val());
            if (letoStr.length == 4 || letoStr.length == 0) {
                if (letoStr.length == 4) {
                    filterValues.leto = +letoStr;
                }
            }
        }

        // dolocimo mesec
        let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
        if (mesecElement.length) {
            let selMesecNaziv = $(mesecElement).find(":selected").text();
            let selMesec = $(mesecElement).find(":selected").val();

            if (!isNaN(+selMesec)) {
                filterValues.mesec = +selMesec
                filterValues.mesecNaziv = selMesecNaziv
            }
        }

        return filterValues;
    }

    private bindPopupEvents(): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.bindPopupEvents");

        let _this = this;

        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._obdobjeCurrentBtnId).off("click");
        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._obdobjeCurrentBtnId).on('click', function (element) {
            _this.obdobjeCurrentClick();
        });

        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._previousMonthBtnId).off("click");
        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._previousMonthBtnId).on('click', function (element) {
            _this.obdobjefiltersMonthStepChange(-1);
        });

        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._nextMonthBtnId).off("click");
        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._nextMonthBtnId).on('click', function (element) {
            _this.obdobjefiltersMonthStepChange(1);
        });

        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._obdobjeFilterMesecId).off("change");
        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._obdobjeFilterMesecId).on('change', function (element) {
            _this.obdobjefiltersChanged();
        });

        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._obdobjeFilterLetoId).off("change");
        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._obdobjeFilterLetoId).on('change', function (element) {
            _this.obdobjefiltersChanged();
        });

        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._obdobjeFilterLetoId).off("keyup");
        $("div.popover.show " + this._popOverObdobjeBodyId + " " + this._obdobjeFilterLetoId).on('keyup', function (element) {
            _this.obdobjefiltersChanged();
        });
    }

    public init(){
        global.ConsoleLogDegug("SaopSelectMonthYear.init");

        // poiscemo prevode
        this.setTerminologyList();

        this._saopPopover.bindPopoverEvents();

        this.setObdobjeText(this._obdobjeId)


        // bind events
        this.bindEvents();

        this.afterInitializedControl(this);
    }

    private setTerminologyList(): void {
        let _terminology: string[] = ['Išči'];
        this._translationService.setTranslationsList(_terminology);
    }

    private setObdobjeText(id: string): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.setObdobjeText");

        let obdobjeElement = $(this._obdobjeId);

        let mesecNaziv = obdobjeElement.attr("data-mesec-naziv");
        let leto = obdobjeElement.attr("data-leto");

        obdobjeElement.val(mesecNaziv + " " + leto);
    }

    private bindEvents(): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.bindEvents");

        let _this = this;

        $(this._viewId + " " + this._controlId + " " + this._obdobjeId).off("click");
        $(this._viewId + " " + this._controlId + " " + this._obdobjeId).on('click', function (element) {
            _this.obdobjeClick();
        }); 

        $(this._viewId + " " + this._controlId + " " + this._obdobjeCurrentBtnId).off("click");
        $(this._viewId + " " + this._controlId + " " + this._obdobjeCurrentBtnId).on('click', function (element) {
            _this.obdobjeCurrentClick();
        }); 
    }

    private obdobjeClick(): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.obdobjeClick");
        $(this._calendarIconId).trigger("click");
    }


    private obdobjeCurrentClick(): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.obdobjeCurrentClick");

        let currentMonthFilterValues = new ObdobjefilterValues();

        currentMonthFilterValues.leto = new Date().getFullYear();
        currentMonthFilterValues.mesec = new Date().getMonth() + 1;
        currentMonthFilterValues.mesecNaziv = this.getMonthName(currentMonthFilterValues.mesec);

        // zapisemo vrednost v filter leto
        this.setObdobjeFilterValues(currentMonthFilterValues);

        // osvezimo obdobje element z novimi podatki
        this.setObdobjeDataAttributes(this._obdobjeId, currentMonthFilterValues);

        this.monthYearValuesChanged(currentMonthFilterValues.leto, currentMonthFilterValues.mesec);
    }

    private getMonthName(month: number): string {
        global.ConsoleLogDegug("SaopSelectMonthYear.getMonthName");

        let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
        if (mesecElement.length) {
            return $(mesecElement).find(" option[value = '" + month + "']").text();
        }

        return "";
    }

    private setObdobjeDataAttributes(id: string, values: ObdobjefilterValues): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.setObdobjeDataAttributes");

        let obdobjeElement = $(id);

        if (values.leto != null && values.mesec != null) {
            obdobjeElement.attr("data-leto", values.leto);
            obdobjeElement.attr("data-mesec", values.mesec);
            obdobjeElement.attr("data-mesec-naziv", values.mesecNaziv);

            this.setObdobjeText(this._obdobjeId);
        }
    }

    // na popover filtru za obdobje pomik za en mesec naprej, nazaj
    private obdobjefiltersMonthStepChange(step: number): void {
        if (this._refreshingRecordsInProgress == true) {
            // poteka postopek osvezevanja podatkov, novi zahtevek ni dovoljen 
            return;
        }

        global.ConsoleLogDegug("SaopSelectMonthYear.obdobjefiltersMonthStepChange");

        // preberemo vrednosti leto in mesec
        let filterValues = this.getObdobjefilterValues();

        let newFilterValues = new ObdobjefilterValues();
        newFilterValues.mesec = filterValues.mesec + step;
        newFilterValues.leto = filterValues.leto;
        if (newFilterValues.mesec == 13) {
            // za december je januar
            newFilterValues.mesec = 1;
            newFilterValues.leto = newFilterValues.leto + 1;
        }
        else if (newFilterValues.mesec == 0) {
            // pred januarjem je december
            newFilterValues.mesec = 12;
            newFilterValues.leto = newFilterValues.leto - 1;
        }

        // poiscemo se naziv meseca
        newFilterValues.mesecNaziv = this.getMonthName(newFilterValues.mesec);

        // zapisemo novo vrednost v filter leto
        this.setObdobjeFilterValues(newFilterValues);

        // osvezimo obdobje element z novimi podatki
        this.setObdobjeDataAttributes(this._obdobjeId, newFilterValues);

        this.monthYearValuesChanged(newFilterValues.leto, newFilterValues.mesec);
    }

    // vrednosti v filtru leto, mesec so se spremenile
    private obdobjefiltersChanged(): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.obdobjefiltersChanged");

        // preberemo vrednosti leto in mesec
        let filterValues = this.getObdobjefilterValues();

        // osvezimo obdobje element z novimi podatki
        this.setObdobjeDataAttributes(this._obdobjeId, filterValues);

        this.monthYearValuesChanged(filterValues.leto, filterValues.mesec);
    }

    private monthYearValuesChanged(leto: number, mesec: number): void {
        if (this._refreshingRecordsInProgress == true) {
            // poteka postopek osvezevanja podatkov, novi zahtevek ni dovoljen 
            return;
        }

        if (isUnDefined(leto) || isUnDefined(leto)) {
            return;
        }

        global.ConsoleLogDegug("SaopSelectMonthYear.monthYearValuesChanged");

        // disable kontrole, ker se izvaja refresh
        this.enableDisableObdobjeFilters(true);

        this.afterMonthYearValuesChanged(leto, mesec);
    }

    private enableDisableObdobjeFilters(disable: boolean): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesAnalysisView.enableDisableObdobjeFilters");

        this._refreshingRecordsInProgress = disable;

        // leto
        let letoElement = $("div.popover.show").find(this._obdobjeFilterLetoId);
        if (letoElement.length) {
            letoElement.prop("disabled", disable);
        }

        // mesec
        let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
        if (mesecElement.length) {
            mesecElement.prop("disabled", disable);
        }

        // gumb trenutni
        let _obdobjeCurrentBtnElement = $("div.popover.show").find(this._obdobjeCurrentBtnId);
        if (_obdobjeCurrentBtnElement.length) {
            _obdobjeCurrentBtnElement.prop("disabled", disable);
        }
    }

     // za externi bind event
    public afterInitializedControl(element: any): void{
        global.ConsoleLogDegug("SaopSelectMonthYear.afterInitializedControl");
    }

    public getSelectedMonthYear(): ObdobjefilterValues {
        global.ConsoleLogDegug("SaopSelectMonthYear.getSelectedMonthYear");

        // preberemo vrednosti leto in mesec
        return this.getObdobjeDataAttributes(this._obdobjeId);
    }

    // za externi bind event
    public afterMonthYearValuesChanged(leto: number, mesec: number): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.afterMonthYearValuesChanged");
    }

    // za externi uporabo
    public setRefreshingRecordsComplete(): void {
        global.ConsoleLogDegug("SaopSelectMonthYear.setRefreshingRecordsComplete");

        // enable kontrole, ker se je refresh koncal
        this.enableDisableObdobjeFilters(false);
    }
}






