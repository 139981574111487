﻿import { global } from "../Common/saop-common";

export class SaopExport {
    private _libName: string;
    //
    
    constructor(libName: string = "") {
        global.ConsoleLogDegug("SaopExport");
        this._libName = libName;
    }

    public exportData2Csv(data: string[][], fileName: string = "ExportData.csv") {
        global.ConsoleLogDegug("SaopExport.exportData2Csv");

        let csv = this.arrayToCsv(data);

        this.downloadBlob("\uFEFF" + csv, fileName, 'text/csv;charset=utf-8;');
    }

    public exportTableData2Csv(tableSelector: string, fileName: string = "ExportData.csv") {
        global.ConsoleLogDegug("SaopExport.exportTableData2Csv");

        let data = this.getTableData(tableSelector);

        this.exportData2Csv(data, fileName);
    }

    private arrayToCsv(data: string[][], csvDelimiter: string = ";") {
        global.ConsoleLogDegug("SaopExport.arrayToCsv");

        let csvData = data.map(row =>
            row
                .map(String)  // convert every value to String
                .map(v => v.replace('"', '""'))  // escape double colons
                .map(v => `"${v}"`)  // quote it
                .join(csvDelimiter)  // comma-separated
        ).join('\r\n');  // rows starting on new lines

        return csvData;
    }

    private downloadBlob(content: string, filename: string, contentType: string) {
        global.ConsoleLogDegug("SaopExport.downloadBlob");

        // Create a blob
        var blob = new Blob([content], { type: contentType });
        var url = URL.createObjectURL(blob);

        // Create a link to download it
        var pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', filename);
        pom.click();
        pom.remove();
    }

    private getTableData(tableSelector: string): string[][] {
        global.ConsoleLogDegug("SaopExport.getTableData");

        let result: string[][] = [];

        // najprej dodamo headerje
        let headerRow: string[] = [];
        let tedenCaptionheaderDivs = $(tableSelector + ' thead tr th:not(.element-hidden) div.saop-table-header-caption');
        tedenCaptionheaderDivs.each(function (index, element) {
            headerRow.push(element.innerText.trim());
        });
        result.push(headerRow);

        // dodamo vrstice z podatki
        let tableRows = $(tableSelector + ' tbody tr:not(.element-hidden)'); 
        tableRows.each(function (index, rowElement) {
            // za vsako vrstico poiscemo podatke iz stopcev vrstice
            let oneRowData: string[] = [];
            $(rowElement).find('td:not(.element-hidden)').each(function (index, colElement) {
                oneRowData.push(colElement.innerText.trim())
            });

            result.push(oneRowData);
        });

        return result;
    }
}






