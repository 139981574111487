﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopViewFormBindings } from "../Core/saop-view-form-bindings";
import { SaopViewForm } from "../core/saop-view-form";

export interface SaopRegUraDogodekDeleteOneParamsters {
    IdStart:string;
    IdEnd: string;
    AuditTrail: boolean;
} 

export class SaopRegUraDogodekDeleteOneView extends SaopViewFormBindings {
    private _viewForm:SaopViewForm;

    constructor(viewId:string,libName:string = ""){
        super(viewId,libName);
        this._viewForm = new SaopViewForm(viewId,libName+"._viewForm");       
        this._viewForm.fullTitle = this._viewForm.translationService.translate("Brisanje");
        this._viewForm.title = "";
        this._viewForm.addRecordEndpoint = "REGUraDogodek/DeleteRegUraDogodekOnePartial";
        this._viewForm.editRecordEndpoint = "";
        this._viewForm.deleteRecordEndpoint = "";           
        //
        this._viewForm.afterViewShowSuccess = this.afterViewShowSuccess.bind(this);   
    }

    public showForm(params: SaopRegUraDogodekDeleteOneParamsters = null):void {
        global.ConsoleLogDegug("showForm...");
        this._viewForm.addRecord(params);
    }

    public afterViewShowSuccess(sender:any):void{
        global.ConsoleLogDegug(this.id + ".afterViewShowSuccess()");
        let _this = this;
        //
        this._viewForm.refreshData = this.refreshData.bind(this);
        this._viewForm.onBtnOkClick = this.onDeleteRecord.bind(this);
        this._viewForm.afterSubmitSuccess = this.onValidationError.bind(this); 
    }

    public onDeleteRecord(sender: any): void {
        $("#deleteRegUraDogodekOnePartial").submit();
    }     

    public refreshData(sender:any):void {
        global.ConsoleLogDegug("SaopRegUraDogodekDeleteOneView.refreshData");
        this.afterDeleteSuccess(sender);
    }      

    public afterDeleteSuccess(sender:any):void {
        global.ConsoleLogDegug("SaopRegUraDogodekDeleteOneView.afterDeleteSuccess");
    }    

    public onValidationError(sender:any):void {
        global.ConsoleLogDegug("SaopRegUraDogodekDeleteOneView.onDeleteError");
        //this._viewForm.bindViewEvents(); 
        this.afterValidationError(this);
    }   

    public afterValidationError(sender:any):void {
        global.ConsoleLogDegug("SaopRegUraDogodekDeleteOneView.AfterValidationError");
    }      
    //
}