﻿import { SaopViewForm } from "../Core/saop-view-form";
import { global, convertString2Date } from "../Common/saop-common";

export class SaopAbsenceConfirmationPartiallyApproveView extends SaopViewForm {
    private _potrjenoOdId: string
    private _potrjenoDoId: string;
    private _potrjenoDniId: string;

    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);
        this.setDictionary();  
        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "AbsenceConfirmation/GetPartiallyApproveForm";
        this.deleteRecordEndpoint = "";

        this._potrjenoOdId = "#PotrjenoOd";
        this._potrjenoDoId = "#PotrjenoDo";
        this._potrjenoDniId = "#PotrjenoDni";
    }

    setDictionary() {
        this.setTerminologyList();
    }  

    initView(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPartiallyApproveView.initView");

        this.initControls();
    }

    isVrstaDopustaKompenzacija(): boolean {
        var vrstaDopusta = $("#VrstaDopusta option:selected").val();

        return vrstaDopusta == 'K' || vrstaDopusta == 'V' ? true : false;
    }

    initControls(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPartiallyApproveView.initControls");
        const _this = this;

        $(this._potrjenoOdId).on('changeDate', function () {
            if (!_this.isVrstaDopustaKompenzacija()) {
                $(_this._potrjenoDoId).datepicker('update', $(_this._potrjenoOdId).datepicker('getFormattedDate'));
                $(_this._potrjenoDniId).val("1");
            }
        });

        $(this._potrjenoDoId).on('changeDate', function () {
            if (!_this.isVrstaDopustaKompenzacija()) {
                let datumOd = convertString2Date($(_this._potrjenoOdId).datepicker('getFormattedDate'), "dd.mm.yyyy");
                let datumDo = convertString2Date($(_this._potrjenoDoId).datepicker('getFormattedDate'), "dd.mm.yyyy");

                var diff = Math.abs(datumOd.getTime() - datumDo.getTime());
                var diffDays = Math.ceil(diff / (1000 * 3600 * 24)) + 1; 

                $(_this._potrjenoDniId).val(diffDays);
            }
        });
    }
}


