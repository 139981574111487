﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopRightSidebar } from '../Components/saop-right-sidebar';
import { SaopRegAbsenceEventsView } from '../REG/saop-regAbsenceEvents-view';

export class DashboardAbsencesView {
    private _libName:string;
    private _saopServicesCore :SaopServicesCore;
    //
    public regAbsenceEventsView:SaopRegAbsenceEventsView;

    constructor(libName: string = "") {
        this._libName = libName;
        //
        this._saopServicesCore = new SaopServicesCore();
        this.regAbsenceEventsView = new SaopRegAbsenceEventsView("#regKeZapDopustiView0",libName+".regAbsenceEventsView");
        this.regAbsenceEventsView.refreshData = this.refreshGridData.bind(this);
    }

    refreshGridData(): void {
        global.ConsoleLogDegug("DashboardCard2.refreshGridData");
        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            $("#card2 .card-body").html(response);
            _this.regAbsenceEventsView.bindGridEvents();
        };
        this._saopServicesCore.ExecuteAction({
            action:"Dashboard/AbsencePartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingcard2"
        });   

        let _saopRightSidebar: SaopRightSidebar = new SaopRightSidebar();
        _saopRightSidebar.refreshDopustZaKoriscenjeSummary("#dopustZaKoriscenjeSummary");
        
        let _saopRightSidebarUre: SaopRightSidebar = new SaopRightSidebar();
        _saopRightSidebarUre.refreshUreZaKoriscenjeSummary("#ureZaKoriscenjeSummary");
    }    
   
    //
}


