﻿import { global } from "../Common/saop-common";

export class SaopFloatingLabel {

    init() {
        let _this = this;
        //$('.has-float-label > .form-control').off("focus",_this.saopInputOnFocus);
        //$('.has-float-label > .form-control').on("focus",_this.saopInputOnFocus);
        $('.has-float-label > .form-control:not([readonly="readonly"])').off("focus", _this.saopInputOnFocus);
        $('.has-float-label > .form-control:not([readonly="readonly"])').on("focus", _this.saopInputOnFocus);

        $('.has-float-label > .form-control').off("blur",_this.saopInputOnBlur);
        $('.has-float-label > .form-control').on("blur",_this.saopInputOnBlur);
    
        $('.has-float-label > .form-control').off("focusout",_this.saopInputOnChange);
        $('.has-float-label > .form-control').on("focusout",_this.saopInputOnChange);

    }

    saopInputOnChange(e:any) {
        global.ConsoleLogDegug("saop-floating-label...saopInputOnChange");
        let htmlElement = e.target;
        let _node = $("#"+htmlElement.id);
        if (_node.length > 0){
            let _parentN = _node.parent();
            if (_parentN.length > 0){
                _parentN.removeClass("saop-error-input");
                let _label = _parentN.find(".text-danger");
                if (_label.length > 0){
                    _label.remove();
                }
            }
        }
    }    

    saopInputOnFocus(e:any) {
        global.ConsoleLogDegug("saop-floating-label...");
        //barvanje "floating label"
        let htmlElement = e.target;
        htmlElement.parentElement.querySelector('.saop-floating-label').classList.add("saop-txt-primary");
    }
        
    saopInputOnBlur(e:any) {
        global.ConsoleLogDegug("saop-floating-label...");
        //barvanje "floating label" on input blur (focus lost) nazaj v sivo (remove razred "saop-txt-primary")
        let htmlElement = e.target;
        htmlElement.parentElement.querySelector('.saop-floating-label').classList.remove("saop-txt-primary");
    }


    setCaptionAsRequired(labelId:string,required:boolean):void{
        let _element = $(labelId);
        let _elementVal = _element.text().toString();
        _elementVal = _elementVal.replace("*","");
        if (required) {
            _element.text(_elementVal+"*");
        } else {
            _element.text(_elementVal);
        }
    }  

    setValidationErrorById(labelId:string, valMessage:string):void{
        let _inputElement = $(labelId)[0] as HTMLInputElement; //input
        this.setValidationError(_inputElement, valMessage);
    }

    setValidationError(inputElement:HTMLInputElement, valMessage:string):void{
        let _inputElement = $(inputElement);
        let _spanElement = _inputElement.parent(); //has-float-label
        if (_spanElement.hasClass("saop-error-input") == false) {
            let _placeholder = _inputElement.attr("placeholder");
            _spanElement.addClass("saop-error-input");
            if (_inputElement.hasClass("input-validation-error") == false) {
                _inputElement.addClass("input-validation-error");
            }
            let _message = valMessage.replace("{0}",_placeholder);
            _spanElement.append('<div class="text-danger saop-txt-7">'+_message+'</div>');
        }
    }


    //
}