import { SaopViewForm } from "../Core/saop-view-form";
import { SaopSifranti } from "../Components/saop-sifranti";


export class OPNNalogeView extends SaopViewForm {
  private _saopSifranti:SaopSifranti;

    constructor(viewId:string,libName:string = ""){
        super(viewId,libName);
        this.title = this.translationService.translate("Naloge");
        this.addRecordEndpoint = "OpnTasks/Create";
        this.editRecordEndpoint = "OpnTasks/Edit";
        //this.deleteRecordEndpoint = "OpnTasks/Delete"; 
        //
        this._saopSifranti = new SaopSifranti();
        //
        this.afterViewShowSuccess = this.initView;
        this.afterSubmitSuccess = this.initView;        
      }


      setDictionary(){
        let dictionary:string[] = ["Naloge"];
        this.setTerminologyList(dictionary);
    } 

      public initView():void{
        this.setDictionary();  
        this.LoadViewEvents();
        this._saopSifranti.init();
      }

      public LoadViewEvents ():void{

      }

      //
}