/// <reference path="../../node_modules/@types/google.maps/index.d.ts"/>
import { global ,isUnDefined } from "../Common/saop-common";
import { SaopMessageBanner } from '../Components/saop-message-banner';
import { SaopFloatingLabel } from '../Components/saop-floating-label';

export class SaopGoogleMaps {
    private _saopFloatingLabel:SaopFloatingLabel = new SaopFloatingLabel();
    private _routes : string;
    private _msgRequred :string; 
    private _viewElement : JQuery<HTMLElement>;
    //###
    private _geocoder = new google.maps.Geocoder(); 
    private _map:google.maps.Map;
    private _marker:google.maps.Marker;
    private _directionsDisplay = new google.maps.DirectionsRenderer();           
    private _directionsService = new google.maps.DirectionsService();  
    //
    private _saopMessageBanner: SaopMessageBanner;

    constructor(libName: string = "") {
        global.ConsoleLogDegug("SaopGoogleMaps.constructor");  
    }

    private initFieldAutocomplete(elementIs:string,countryCode:string):void{
        // Create the autocomplete object, restricting the search to geographical
        // location types.   
        //
        let _this = this;
        let _inputField = $(elementIs)[0] as HTMLInputElement;
        let _inputFieldAutocomplete = new google.maps.places.Autocomplete(
          _inputField, {
            fields: ["formatted_address", "geometry", "name"],
            strictBounds: false,
            // types: ['geocode'],
            // fields: ["name", "address_component", "formatted_address", "geometry.location"],
            // componentRestrictions: { 'country': ['si','hr'] },
           });
        // // Set initial restrict to the greater list of countries.
        // _inputFieldAutocomplete.setComponentRestrictions({
        //     'country': ["si","hr"]
        // });

       _inputFieldAutocomplete.addListener('place_changed', function() {
        _this.refreshMap();
       }.bind(this));

       if (isUnDefined(this._map) == false) {
        _inputFieldAutocomplete.bindTo("bounds", this._map);
       }     

    }    

    getLocation():void {
      let _this = this;
      if (navigator.geolocation) {
        //navigator.geolocation.getCurrentPosition((position: any) => _this.showPosition(position));
        //navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => console.log(position.coords.latitude));
        //
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    }    
    
    showPosition(position:any) {
      var lat = position.coords.latitude;
      var lng = position.coords.longitude;
      this._map.setCenter(new google.maps.LatLng(lat, lng));
    }

    public initView():void {
      global.ConsoleLogDegug("SaopGoogleMaps.initView");  
      //
      this._viewElement = $('#GoogleMapsRoutes'); 
      this._msgRequred = this._viewElement.find('#msgRequred').text();
      //
      let _this = this;
      window.onresize = function () {
        _this.setRowHeight();
      };        
      this.setRowHeight();
      //
      this._saopMessageBanner = new SaopMessageBanner();  
      //
      var address = "Slovenija";
      //###
      this._geocoder.geocode( { 'address': address, language:'sl'}, function(results:any, status:any) {
          if (status == google.maps.GeocoderStatus.OK)
          {
              let _map_canvas = _this._viewElement.find('#map_canvas')[0];

              let _lat = results[0].geometry.location;
              var myOptions = {
                  zoom: 10,
                  center: _lat
              }

              _this._map = new google.maps.Map(_map_canvas, myOptions);

              // const myLatLng = { lat: 45.953662, lng: 13.648237 };

              // _this._marker = new google.maps.Marker(
              //   {
              //     position: myLatLng,
              //     map: _this._map
              //   }                
              // );

              // _this._marker.setPosition(results[0].geometry.location);
              // _this._marker.setMap(_this._map);

              _this._directionsDisplay.setMap(_this._map);    
          }
      });
      //
      //this.getLocation();
      //
      this.LoadViewEvents();  
      //this.initAutocomplete();  
    }
      
    private setRowHeight():void {
      global.ConsoleLogDegug("setRowHeight...");
      //let _height = $('#grid-toolbar-processed').height();
      let remaining_height = window.innerHeight - 200;
      this._viewElement.find('#GoogleMapsRoutesRow').height(remaining_height);        
    }

    private LoadViewEvents():void {
      global.ConsoleLogDegug("SaopGoogleMaps.LoadViewEvents");  
      let _this = this;

      //Dodaj naslov na seznam
      let _btnAddWaypoint = this._viewElement.find('#btnAddWaypoint');
      _btnAddWaypoint.off("click");
      _btnAddWaypoint.on("click",_this.addWaypoint.bind(_this));
   
      //Funkcija, ki se prikliče ob kliku gumba za sledenje poti
      let _btnCalculateDistance = this._viewElement.find('#btnCalculateDistance');
      _btnCalculateDistance.off("click");
      _btnCalculateDistance.on("click",_this.calculateDistance.bind(_this));

      let _cbchkStartEqualEnd = this._viewElement.find('#cbchkStartEqualEnd');
      _cbchkStartEqualEnd.off("change");
      _cbchkStartEqualEnd.on("change",_this.oncbchkStartEqualEndChange.bind(_this));      

      this.onSortableChange();
      
    };

    public delay(time:number):any {
      return new Promise(resolve => setTimeout(resolve, time));
    }    

    private getNextId():string{
      let _id = Date.now().toString();
      let _e = this._viewElement.find('#waypoint_'+_id);
      global.ConsoleLogDegug("SaopGoogleMaps.getNextId:"+'#waypoint_'+_id);  
      while (_e.length > 0) {
        global.ConsoleLogDegug("SaopGoogleMaps.getNextId:"+'#waypoint_'+_id+':duplicate');
        _id = Date.now().toString();
        _e = this._viewElement.find('#waypoint_'+_id);
      }
      return _id;
    } 

    public addWaypoint(waypointDescription:string = ""):void{
      //control_panel
      global.ConsoleLogDegug("SaopGoogleMaps.addWaypoint");  
      let _control_panel = this._viewElement.find('#control_panel');        
      let _waypointTemplate = _control_panel.find('#waypointTemplate');
      let _waypoint = _waypointTemplate.find('#waypoint');
      if (_control_panel.length > 0 && _waypointTemplate.length > 0 && _waypoint.length > 0) {
        let _placeholderText = _waypoint.attr("placeholder");
        //let _length = _control_panel.children().length+1;
        let _wpId = this.getNextId();    
        let _cloneId = "waypoint_" + _wpId.toString();
        //this.delay(100);
        let _inputClone = 
        _waypointTemplate
        .clone()
        .html(function(i, oldHTML) {
          let _newText = oldHTML.replace(/waypoint/g,_cloneId);
          var regex = new RegExp(_placeholderText, 'g');
          _newText = _newText.replace(regex,_placeholderText+_wpId.toString());
          return _newText;
        });

        _inputClone.attr("id","waypoint_"+_wpId.toString()+"_row");
        _inputClone.attr("delete-id","waypoint_"+_wpId.toString()+"_del");
        _inputClone.css("display","flex");

        _control_panel.append(_inputClone);

        //let _waypoint_del = _control_panel.find("#waypoint_"+_length.toString()+"_del");
        // if (_waypoint_del.length > 0){
        //   _waypoint_del.css("display","block");
        // }

        let _waypointClone = _control_panel.find("#waypoint_"+_wpId.toString()+"");
        if (_waypointClone.length > 0) {
          this.initFieldAutocomplete("#"+_waypointClone[0].id,"si"); 
          if (waypointDescription.length > 0) {
            _waypointClone.val(waypointDescription.trim());
          }
          
        }
      }
      //
      this._saopFloatingLabel.init();
      this.onSortableChange();
      this.getRoutes();
    }

    public getRoutes():any{
      global.ConsoleLogDegug("SaopGoogleMaps.getRoutes");  
      this._routes = "";

      let _list = this.getWaypoints();
      let _i = 0;
      for(var _index in _list)
      { 
          let _element = _list[_index] as HTMLInputElement ;
          let _lableId = "#lbl"+_element.id;
          let _labelCaption = "";
          if (_i == 1) {
            _labelCaption = "Začetna lokacija*";
          } else if (_i == 2) {
            _labelCaption = "Cilj*";
          } else {
            _labelCaption = "Cilj"+(_i-1).toString()+"*";  
          }
          $(_lableId).text(_labelCaption);
          //console.log("val:"+_element.value);  
          //console.log($("#"+_element.id).val()); 
          let _wp = _element.value.replace(', Slovenija','');
          _wp = _wp.replace(', Slovenia','').trim();
          //


          if (_element.value.length > 0) {
            if (this._routes.length > 0) {
              _wp = "; "+_wp
            }
            this._routes = this._routes +_wp ;
          }
          _i = _i + 1;
      }

      return this._routes;
    }

    public getWaypoints(): HTMLElement[] {
      var _result : HTMLElement[] = [];
      let _control_panel = $('#GoogleMapsRoutes').find('#control_panel');    
      _control_panel.children().each(function () {
        let _input = $(this).find(":input");
        if (_input.length > 0) {
          if (_input[0] != null) {
            _result.push(_input[0]);            
          }
        }
      });
      return _result;
    }

    public deleteWaypoint(e:any):void{
      let _control_panel = $('#GoogleMapsRoutes').find('#control_panel');   

      if (_control_panel.children().length == 3) {
        return;
      }

      let _btnDel = $(e as HTMLElement);
      let _rowId = _btnDel.attr("row-id");

      if (_rowId != "waypointTemplate") {
        let _row = $("#"+_rowId);
        if (_row.length > 0) {
          _row.remove();
          this.calculateDistance();
        }
      }
    }

    public calculateDistance():void{
      global.ConsoleLogDegug("SaopGoogleMaps.calculateDistance");  
      this.getRoutes();
      //
      let _this = this;
      let _startWaypoint = "";
      let _endWaypoint = "";
      let _waypoints:any = [];
      let _validationErrors:boolean = false;
      
      let _list = this.getWaypoints();

      for (let i = 1; i < _list.length; i++) {
        let _element = _list[i] as HTMLInputElement ;
        if (_element.value.length > 0 ) {
          if (i == 1) {
            _startWaypoint = _element.value;
          } else if (i == _list.length-1) {
            _endWaypoint = _element.value;
          } else {
            _waypoints.push({location:_element.value,stopover:true});            
          }
        } else {
          _validationErrors = true; 
        }
        // else if(_element.id != "waypoint" ) {
        //   this._saopFloatingLabel.setValidationError(_element,this._msgRequred);
        //   _validationErrors = true;
        // }
      }

      if (_validationErrors == true) {
        this._directionsDisplay.set('directions', null); ; 
      }

      let _dobleDistance = false;
      let _cbchkStartEqualEnd = $('#GoogleMapsRoutes').find("#cbchkStartEqualEnd");
      if (_cbchkStartEqualEnd.length > 0) {
        if (_cbchkStartEqualEnd.is(":checked")){
          _dobleDistance = true;
        }
      }

      if (_dobleDistance) {
        _waypoints.push({location:_endWaypoint,stopover:true}); 
        _endWaypoint = _startWaypoint;    
      }

      if (_startWaypoint.length > 0 && _waypoints.length > 0 && _waypoints[0].location.length > 0 ) {
        var request:google.maps.DirectionsRequest = {
          origin: _startWaypoint,
          destination: _endWaypoint,
          waypoints: _waypoints,
          optimizeWaypoints: false,
          travelMode: google.maps.TravelMode.DRIVING
        };
  
        this.renderDirectionsRequest(request);
      }



    }

    public renderDirectionsRequest(request:google.maps.DirectionsRequest){
      global.ConsoleLogDegug("SaopGoogleMaps.renderDirectionsRequest");        
      global.ConsoleLogDegug("SaopGoogleMaps.request: "+JSON.stringify(request));     
      //naredi zahtevo
      let _this = this;
      _this._directionsService.route(request, function(response:any, status:any) {
        if (status == google.maps.DirectionsStatus.OK) {
            _this._directionsDisplay.setDirections(response); // Če se uspešno vrne, upodobi zemljevid
            //
            var routes = response.routes;
            var distances = [];                
            for (var i = 0; i < routes.length; i++) {

                var distance = 0;
                for (var j = 0; j < routes[i].legs.length; j++) {
                  distance = distance + 1;
                  let _rootDist = routes[i].legs[j].distance.value;
                  distance = _rootDist + distance;
                  //for each 'leg'(route between two waypoints) we get the distance and add it to 
                }
                //Convert into kilometer
                distances.push(distance / 1000);
                //
                var maxDistance = distances.sort(function(a, b) {
                    return a - b;
                  });
                  //Display distance having highest value.
                  var outputDiv = $('#GoogleMapsRoutes').find('#distance');
                  let __distance = Math.round(maxDistance[routes.length - 1]);
                  // if (_dobleDistance) {
                  //   __distance = __distance * 2;
                  // }
                  outputDiv.val(__distance);
              }                
        } 
        else {
          //alert('Nekaj ​​je šlo narobe, preverite naslove.');
          let messages: string[] = [];
          messages.push("Prišlo je do napake pri iskanju poti")
          _this._saopMessageBanner.show(messages);                
        }
      }); 
    }

    public refreshMap(){
      global.ConsoleLogDegug("SaopGoogleMaps.refreshMap");
      this.delay(1000).then(() => $('#GoogleMapsRoutes').find('#btnCalculateDistance').click());     
    }

    public loadRoutesList(): void {
      this._viewElement = $('#GoogleMapsRoutes'); 
      let _routesList = this._viewElement.find('#RoutesList');
      let _routesListString = _routesList.val() as string;
      
      if (_routesListString.length > 0) {
        let _routesArray = _routesListString.split(";");
        this.loadWaypoints(_routesArray);
      } else {
        this.loadWaypoints(null);
      }
    }

    public loadWaypoints(list:string[]):void {
      global.ConsoleLogDegug("SaopGoogleMaps.loadWaypoints");
      this._viewElement = $('#GoogleMapsRoutes'); 
      let _allWaypoints = this._viewElement.find("[delete-id]");
      _allWaypoints.remove();
      //
      let _distance = this._viewElement.find('#distance');
      if (_distance.length > 0) {
        _distance.val("");
      }      
      //
      this._viewElement.find("#startpoint").val("");
      this._viewElement.find("#waypoint").val("");
      //
      let _initStartEndWaypoint = false;
      if (list == null) {
        _initStartEndWaypoint = true;
        list = [];
        list.push("");
        list.push("");
      }
      let _elementOrder = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i].length > 0 || _initStartEndWaypoint) {
          // if (_elementOrder == 0) {
          //   this._viewElement.find("#startpoint").val(list[i]);  
          // } else if (_elementOrder == 1) {
          //   this._viewElement.find("#waypoint").val(list[i]);  
          // } else {
             this.addWaypoint(list[i]);
          // }
          _elementOrder = _elementOrder + 1;
        }
      }
      //
      this.refreshMap();
    }

    public getDistance():string{
      this._viewElement = $('#GoogleMapsRoutes'); 
      let _distanceString =  this._viewElement.find('#distance').val() as string;
      return _distanceString;
    }

    public onmouseoverWaypoint(e:any):void{
      this._viewElement = $('#GoogleMapsRoutes'); 
      let _control_panel = $('#GoogleMapsRoutes').find('#control_panel');   

      if (_control_panel.children().length == 3) {
        return;
      }

      let _waypointRow = $(e);  
      let _deleteButtonId = _waypointRow.attr("delete-id");
      $('#'+_deleteButtonId).css("display","block");
    }    

    public onmouseoutWaypointFocus(e:any):void{
      this._viewElement = $('#GoogleMapsRoutes'); 
      let _control_panel = $('#GoogleMapsRoutes').find('#control_panel');   

      if (_control_panel.children().length == 3) {
        return;
      }

      let _waypointRow = $(e);  
      let _deleteButtonId = _waypointRow.attr("delete-id");
      $('#'+_deleteButtonId).css("display","none");
    }  

    private oncbchkStartEqualEndChange(e:any):void {
      // if (e.currentTarget.checked) {
      //   alert('checked');
      // } else {
      //   alert('not checked');
      // }
      this.calculateDistance();
    }

    private onSortableChange():void {
      this._viewElement.find('#control_panel').sortable({axis:'y',stop:this.calculateDistance.bind(this)});      
    }

    //
}
