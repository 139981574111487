﻿/// <reference path="../@types/data-tables/types.d.ts" />
import { isUnDefined, global } from "../Common/saop-common";
import { SaopWebGridFilter } from "../Components/saop-grid-filter";
import { DataTables } from "../@types/data-tables/types";
import { SaopTranslationService } from '../Core/saop-translate';
// function statusFormatter(row: number, cell: number, value: any, columnDef: Slick.Column<Slick.SlickData>, dataContext: Slick.SlickData) {
//   if (value == null || value === "") {
//     return "-";
//   } else if (value < 50) {
//     return "<span style='color:red;font-weight:bold;'>" + value + "%</span>";
//   } else {
//     return "<span style='color:green'>" + value + "%</span>";
//   }
// }

export interface SaopWebGridToolbar {
  id?:string,
  caption:string,
  buttons?:SaopWebGridToolbarButton[]
  itemsTemplateId?: string;
} 

export interface SaopWebGridToolbarButton {
  id:string,
  caption :string,  
  class? :string,
  attributes?:string,
  onClickEvent?:string,
  template?: string;
} 

export interface SaopWebGridTogglePanel {
  enabled:boolean
} 

export interface SaopWebGridProperties {
  gridId:string
  ,css?:string
  ,togglePanel?:SaopWebGridTogglePanel
  ,toolbar?:SaopWebGridToolbar  
  ,dataTable?:SaopWebDataTable
}

export interface SaopWebDataTable {
  enabled:boolean
  ,columnDefsSettings?: DataTables.ColumnDefsSettings[]
  ,ordering?:boolean
  ,columns?:any[]
  ,data?: any
  ,order?: Array<(number | string)> | Array<Array<(number | string)>>
  ,colResizeOnly?:boolean 
  ,colSettings?:boolean 
}

export interface SaopWebGridColDialogProperties {
  dialogId:string
  btnResetColId:string
}

export class SaopWebGrid {
  private _properties:SaopWebGridProperties
  private _tableDiv:HTMLTableElement;
  private _translationService: SaopTranslationService;
  private _dialog:any;
  private _colDialogProperties : SaopWebGridColDialogProperties;
  private _saveState:boolean = false;
  //
  public libName: string;  
  public webGridFilter:SaopWebGridFilter;
  public dataTable: DataTables.Api<any>;
  public markedColumnCheckbox: JQuery<HTMLInputElement>;

  constructor(libName: string) {
    this.libName = libName;
    this.webGridFilter = new SaopWebGridFilter();
    this._translationService = new SaopTranslationService();
    this._colDialogProperties =  {
      dialogId:""
      ,btnResetColId:""
    };
    this.setTerminologyList();
  }  

  setTerminologyList(){
    let _terminology: string[] = ['Ni podatkov za prikaz.','Urejanje stolpcev','Ponastavi stolpce','Shrani','Prekliči'];
    this._translationService.setTranslationsList(_terminology);
  }

  //
  init(gridProp:SaopWebGridProperties) {
    this._properties = gridProp;
    this._tableDiv = document.querySelector(gridProp.gridId) as HTMLTableElement;
    //
    let _togglePanelDefault = {enabled:true};
    gridProp.togglePanel = {..._togglePanelDefault, ...gridProp.togglePanel}
    if (isUnDefined(gridProp.togglePanel)){
      gridProp.togglePanel = _togglePanelDefault;
    }      
    //

    this.webGridFilter.id = gridProp.gridId+"filter";
    this.generateHtmlTable();
    this.addGridToolbar();
    // det defaults
    let _Msg_emptyTable = this._translationService.translate("Ni podatkov za prikaz.")

    let _dataTableDef = {enabled:true,ordering:true,colReorder:false,colSettings:false};
    gridProp.dataTable = {..._dataTableDef, ...gridProp.dataTable}

    if (isUnDefined(gridProp.dataTable)){
      gridProp.dataTable = gridProp.dataTable;
    }    

    let _columnDefsSettingsDef:DataTables.ColumnDefsSettings[] = [{orderable: true, targets:  "no-sort"}];
    if (isUnDefined(gridProp.dataTable.columnDefsSettings)){
      gridProp.dataTable.columnDefsSettings = _columnDefsSettingsDef;
    } else {
      gridProp.dataTable.columnDefsSettings = Array.from(new Set([..._columnDefsSettingsDef,...gridProp.dataTable.columnDefsSettings]));
    }    

    //
    
    //
    if (!isUnDefined(this._properties.dataTable) 
      && this._properties.dataTable.enabled
      ){
      let _this = this;
      //
      let _colReorder = {};
      _colReorder = {
        allowReorder:this._properties.dataTable.colResizeOnly
        ,allowResize:this._properties.dataTable.colResizeOnly
      };
      //
      this._saveState = this._properties.dataTable.colResizeOnly ||this._properties.dataTable.colSettings;
      //
      if (this._saveState == false) {
        this.dataTable = 
          $(gridProp.gridId).DataTable(
            {
                "paging": false
                , "searching": true
                ,"autoWidth": true
                ,orderCellsTop: true
                //, "dom": "Rlfrtip"
                , initComplete: function () {
                  if (gridProp.dataTable.ordering){
                    _this.webGridFilter.configSimpleFilter(this);                  
                    _this.webGridFilter.configSimpleSort(_this._properties.gridId);   
                  }
                  //if(!isUnDefined(_this._properties.dataTable.filters)) {
                  //   _this.webGridFilter.configFilter(_this.libName,this, _this._properties.dataTable.filters);
                  //}
                }
                ,order: _this._properties.dataTable.order
                // ,ajax: function (data, callback, settings) {
                //   callback({ data: data }) //reloads data 
                // } 
                ,columnDefs: _this._properties.dataTable.columnDefsSettings 
                ,ordering : gridProp.dataTable.ordering 
                ,language : {emptyTable:_Msg_emptyTable,zeroRecords:_Msg_emptyTable}
            }
          );    
        //
        $(gridProp.gridId+"_filter").remove();
        $(gridProp.gridId+"_info").remove(); 
        this.webGridFilter.setResizeEvent(this._properties.gridId);
        //
      } else {
        
        this.dataTable = 
        $(gridProp.gridId).DataTable(
          {
              "paging": false
              ,"searching": true
              //,"autoWidth": true
              //,scrollX: true
              ,orderCellsTop: true
              ,colReorder: _colReorder
              //, "dom": "Bfrtip"
              , stateSave: this._saveState
              , initComplete: function () {
                if (gridProp.dataTable.ordering){
                  _this.webGridFilter.configSimpleFilter(this);                  
                  _this.webGridFilter.configSimpleSort(_this._properties.gridId);   
                }
                // if(!isUnDefined(_this._properties.dataTable.filters)) {
                //   _this.webGridFilter.configFilter(_this.libName,this, _this._properties.dataTable.filters);
                // }
              }
              //,stateSaveCallback : _this.gridColSize.stateSaveCallback.bind(_this.gridColSize)
              ,order: _this._properties.dataTable.order
              // ,ajax: function (data, callback, settings) {
              //   callback({ data: data }) //reloads data 
              // } 
              ,columnDefs: gridProp.dataTable.columnDefsSettings 
              ,ordering : gridProp.dataTable.ordering 
              ,language : {emptyTable:_Msg_emptyTable,zeroRecords:_Msg_emptyTable}    
              // ,buttons: [                
              //   {
              //   extend: 'pdfHtml5',
              //   orientation: 'landscape',
              //   pageSize: 'LEGAL'
              //   }
              // ] 
              }
        );    
        //
        $(gridProp.gridId+"_filter").remove();
        //
        $(gridProp.gridId+"_info").remove(); 
        //
        this.setResizableColumn();
        //
        this.initEventListners();
      }
      //

    }
   
    //

  }

  addToolbarButtons(gridPanel: JQuery<HTMLElement>,buttons:SaopWebGridToolbarButton[]){
    if(isUnDefined(buttons) == false){
      buttons.forEach(_button => {
        var buttonTemplate = 
        `
        <div class="col-auto my-auto" style="padding-left:21px;">
          <button class="btn saop-button-primary mx-auto d-block" id="${_button.id}" type="submit" onclick="${_button.onClickEvent}" style="padding-left:10px;"><span class="saop-img-plus"></span>${_button.caption}</button>
        </div>    
        `;

        if (_button.template != null) {
          buttonTemplate = _button.template;
        }

        gridPanel.find("#toolbarButtons").append(buttonTemplate);      
      });
    }
  }

  generateHtmlTable() {
    this.generateTableBody();        
    this.generateTableHead();
  }

  generateTableHead() {
    if (!isUnDefined(this._properties.dataTable)){
      if(!isUnDefined(this._properties.dataTable.columns)){
        let thead = this._tableDiv.createTHead();
        let row = thead.insertRow();
        for (let key of this._properties.dataTable.columns) {
          let th = document.createElement("th");
          let text = document.createTextNode(key.name);
          th.appendChild(text);
          row.appendChild(th);
        }
      }
    }
  }
  
  generateTableBody() {
    if (!isUnDefined(this._properties.dataTable)){
      if (!isUnDefined(this._properties.dataTable.data)){
        for (let element of this._properties.dataTable.data) {
          let row = this._tableDiv.insertRow();
          for (let key in element) {
            let cell = row.insertCell();
            let text = document.createTextNode(element[key]);
            cell.appendChild(text);
          }
        }
      }
    }
  }

  addGridToolbar() {
    let _this = this;
    let gridPanel: JQuery<HTMLElement>;
    let _css = isUnDefined(this._properties.css) == false ? this._properties.css :"";

    let gridPanelTemplate = `
        <div class="saop-table-fixhead ${_css}">
          <table id="#slickGrid#"></table>
        </div>
    `;
    if (!isUnDefined(this._properties.toolbar)) {
      if (isUnDefined(this._properties.toolbar.id)) {
        this._properties.toolbar.id = this._properties.gridId+"TB";
      }
      let gridTogglePanel = ``;
      let gridTogglePanelClass = "";
      let gridBorderBottom = "";
      if(this._properties.togglePanel.enabled) {
        gridTogglePanelClass = "collapse show";
        gridBorderBottom = "saop-border-bottom";

        gridTogglePanel = 
          `
          <div class="saop-form-panel-heading saop-toggle-panel">
            <p class="saop-txt-3 m-0" data-toggle="collapse" aria-expanded="true" data-target="#collapse${this._properties.toolbar.id}">
            ${this._properties.toolbar.caption} <span class="saop-img-down si-m saop-toggle-arrow right"></span>
            </p>
          </div>
          `;      
      }

      let _toolbarItems = ``;
      if (!isUnDefined(this._properties.toolbar.itemsTemplateId)){
        let _toolbarItemsTemplate = $(this._properties.toolbar.itemsTemplateId);
        if (_toolbarItemsTemplate.length > 0){
          _toolbarItems = _toolbarItemsTemplate.html();
          _toolbarItemsTemplate.remove();
        }
      }

      let _btnSettings = ``;
      if (this._properties.dataTable.colSettings == true || this._properties.dataTable.colResizeOnly == true) {
        _btnSettings = 
        `
          <div id="gridSettings" style="float:right; position: relative; top: -25px; left: -20px; width:5px; padding-top:5px;">
          <div class="btn-group dropleft position-static show" role="group"> 
            <div class="btn p-0 saop-toggle-panel align-middle" id="${this._properties.gridId}_DropdownMenu" data-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="true"> 
              <div> 
                <span class="d-block saop-img-settings si-xs" style="cursor: pointer;"> 
                </span> 
              </div> 
            </div> 
            <div class="dropdown-menu" data-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded= "false" aria-labelledby="${this._properties.gridId}_DropdownMenu"> 
        `;
        if (this._properties.dataTable.colSettings == true){
          _btnSettings = _btnSettings +  `<div class="dropdown-item saop-txt-4 saop-text-link gridColSettings">${_this._translationService.translate("Urejanje stolpcev")}</div>`;
        }
        if (this._properties.dataTable.colResizeOnly == true){
          _btnSettings = _btnSettings +  `<div class="dropdown-item saop-txt-4 saop-text-link gridRessetColSettings">${_this._translationService.translate("Ponastavi stolpce")}</div>`;
        }

        //_btnSettings = _btnSettings +  `<div class="dropdown-item saop-txt-4 saop-text-link gridExportToPDF">${_this._translationService.translate("Izvozi v PDF")}</div>`;

        _btnSettings = _btnSettings +    
        `
            </div> 
          </div>
        </div>         
        `;
      }

      this._colDialogProperties.btnResetColId = this._properties.gridId.replace("#","btn")+"ResetCol";
      let _dialogHTML = ``;
      _dialogHTML = 
      `
      <style>
        .ui-dialog {
            z-index:100;
        }
        .ui-widget-header {
          background-color:#FCFCFC;
          border:1px solid #dee2e6!important;
          margin:10px;         
        }
        .ui-dialog-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px; 
          font-family:'Open sans';
        }
        .ui-dialog .ui-dialog-buttonpane button {
          font-family:'Open sans';
        }
        .ui-dialog-titlebar-close {
          content: "\\e911";
          display:none;
        }
        .ui-sortable {
          padding:0px;
        }
        .ui-sortable li {
          margin: 10px;
        }
        .ui-dialog .ui-dialog-buttonpane { 
          text-align: center;
          margin:0px;
          padding:0px;
        }
        .ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset { 
            float: none;
        }    
        li::marker {
          content:""
        }               
      </style>      
     
      <div id="dialog" style="display:none;" title="${_this._translationService.translate("Urejanje stolpcev")}">
        <ol id="sortable"></ol>
        <div style="padding-left:10px;">
          <button class="btn btn saop-text-link saop-text-link-blue saop-txt-5 m-0 p-0" id="${this._colDialogProperties.btnResetColId}" tabindex="0" type="button">${_this._translationService.translate("Ponastavi stolpce")}</button>
        </div>
      </div>      
      `;

      gridPanelTemplate = `
        ${_dialogHTML}
        <div id="${this._properties.toolbar.id}" class="panel panel-default ${gridBorderBottom}" style="min-height:150px;">
          ${gridTogglePanel}
          <div id="collapse${this._properties.toolbar.id}" class="saop-form-panel-body ${gridTogglePanelClass}">
            ${_btnSettings}
            <div id="grid-toolbar" class="saop-table-toolbar">
              <div id="toolbarButtons" class="row mr-0 ml-0 justify-content-start" style="height:100%;">
              ${_toolbarItems}
              </div>
            </div>
            <div class="saop-table-fixhead ${_css}">
              <table id="#slickGrid#"></table>
            </div>
          </div>
        </div>
      `;
    }
    gridPanelTemplate = gridPanelTemplate.replace("#slickGrid#",this._properties.gridId.replace("#",""));
    gridPanel = $(gridPanelTemplate);
    if (!isUnDefined(this._properties.toolbar)) {
      this.addToolbarButtons(gridPanel,this._properties.toolbar.buttons);
    }

    let _tableHtml = this._tableDiv;

    $(this._properties.gridId).replaceWith(gridPanel);

    $(this._properties.gridId).replaceWith(_tableHtml);
  }

  refreshData(data:any) {
    let _this = this;
    this.dataTable.clear();
    data.each(function( index:any, tr:string ) {
      _this.dataTable.row.add($(tr));
    });
    this.dataTable.draw();    
  }

  reorderColumns(htmlContent:string):JQuery<HTMLElement>{
    let order = this.dataTable.colReorder.GetCurrentOrder();
    //###
    let rows = $(htmlContent).find('tbody > tr');
    let _tbodyNew =  $(htmlContent).find('tbody').clone();
    _tbodyNew = _tbodyNew.empty();

    //$('body').append( $(htmlContent).find('tbody').clone());

    rows.each(function(index:any,tr:any) {
      let cols = jQuery(this).children('td');
      let _newTr = $(tr).clone().empty();
      for (let _order of order) {
        _newTr.append(cols.eq(_order));
      }
      _tbodyNew.append(_newTr);
    });

    //$('body').append(_tbodyNew);
    
    return _tbodyNew;
  }  

  replaceGridRows(htmlContent:string) {
    let _this = this;
    this.dataTable.clear();
    //
    let _tbodyNew;
    if (this._saveState){
      _tbodyNew =_this.reorderColumns(htmlContent);
      _tbodyNew.find("tr").each(
        function(index:any,tr:any) {
          _this.dataTable.row.add($(tr));
        }
      );        
    } else {
      _tbodyNew = $(htmlContent);
      $(this._properties.gridId+" > tbody > tr", $(htmlContent)).each(
        function(index:any,tr:any) {
          _this.dataTable.row.add($(tr));
        }
      );      
    }   
    //this.dataTable.colReorder.order([0, 2, 3, 4, 5, 6, 7, 8, 9, 1, 10, 11]);
    this.dataTable.draw(false);    
  }  

  setTabelFoother():void {
    $(this._properties.gridId+' body')  
  }

  initWebGridColumnCheckboxBindClickEvent() {
    let _this = this;
    this.markedColumnCheckbox = null;

    if (isUnDefined(this._tableDiv)) {
      return;
    }

    $("#" + this._tableDiv.id + " .saop-td-input-checkbox").off("change");
    $("#" + this._tableDiv.id + " .saop-td-input-checkbox").on('change', function (row) {
      _this.markedColumnCheckbox = $("#" + _this._tableDiv.id + " #" + row.currentTarget.id);

      // odznacimo vse ostale checkboxe
      $("#" + _this._tableDiv.id + " input.saop-td-input-checkbox:not(#" + row.currentTarget.id + ")").each(
        function () {
          $("#" + _this._tableDiv.id + " #" + this.id).prop("checked", false);
        }
        );

      _this.afterColumnCheckboxChanged();
    });
  }

  afterColumnCheckboxChanged(): void {

  }

  markSelectedRow(selectedRow: HTMLElement): void {
    // odstranimo css "selected_row" iz vseh vrstic 
    $(this._properties.gridId + " > tbody > tr").each(
      function (index: any, tr: any) {
        $(tr).removeClass("saop-table-selected-row");
      }
    );
    // dodamo css "selected_row" izbrani vrstici
    $(selectedRow).addClass("saop-table-selected-row");
  }

  getSelectedRows(colID:number = 0):string[] {
    let _result : string[] = [];
    let rows_selected =  this.dataTable.column(colID).checkboxes.selected();
    if (rows_selected != null) {
      $.each(rows_selected, function(index:any, rowId:any){
        _result.push(rowId);
      })  
    }
    return _result; 
  }

  getSelectedRowsV2():string[] {
    global.ConsoleLogDegug("getSelectedRowsV2");
    let _result : string[] = [];
    $(this._properties.gridId+" tr td.dt-checkboxes-cell input[type='checkbox'].dt-checkboxes").each(function (index:any, e:any) {
        let checked = $(this).is(":checked");
        if (checked) {
            _result.push(index);
        }
    });   
    return _result;   
  }

  selectAllRows():void {
    this.dataTable.column(0).checkboxes.select();
  }  

  deselectAllRows():void {
    this.dataTable.column(0).checkboxes.deselect();
  }  

  setDefaultColumnsState():void{
    localStorage.removeItem(this._properties.gridId);
    //
    let _instanceID = "DataTables_"+this._properties.gridId.replace("#","")+"_"+location.pathname;
    localStorage.removeItem(_instanceID);     
    //
    location.reload();
  }

  createDialog():void {
    let _this = this;
    let dt = this.dataTable as any;

    var ul = $('#sortable').sortable({axis:'y'});
    ul.empty();

    $.each(dt.colReorder.order(), function (idx, val) {
      var col = dt.column(idx);
      let _th = col.header();
      let _isVisible = true;
      let _className = _th.className.toLowerCase() as string;
      if (screen.width > 400){
        if (_className.includes("d-none-desktop".toLowerCase())){
          _isVisible = false;
        }
      } else {
        if (_className.includes("d-none-mobile".toLowerCase())){
          _isVisible = false;
        }        
      }
     
      let _fieldCaption = "?";
      if (_th.children.length > 0) {
        _fieldCaption = _th.firstChild.innerText;
      } else {
        _fieldCaption = _th.innerText;
      }
      _fieldCaption = _fieldCaption.trim();
      
      let _checked = ' checked';      
      if (_isVisible == false) {
        _fieldCaption = "";
        _checked = '';
      }

      
      let _display = "";
      if (_fieldCaption.length == 0){
        _display = "display:none;";
      }

      let _ul = 
        '<li style="'+_display+'" data-sort="' + val + '">'
        +'<span class=\"saop-img-grip si-s\" style=\"margin-right:10px; cursor: grab; font-size:16px;\"></span>'
        +'<input type="checkbox" value="' + _fieldCaption + '"' 
        + (col.visible() ? _checked : '') + '>&nbsp;'
        +'<span style="cursor:grab">' + _fieldCaption + '</span>'
        +'</li>';

      ul.append(_ul);      
    }); 

    this.updateDialog();    
    this._dialog.dialog('open');

    let _btnResetCol = $("#"+this._colDialogProperties.btnResetColId);
    _btnResetCol.off("click",_this.setDefaultColumnsState.bind(_this));
    _btnResetCol.on("click",_this.setDefaultColumnsState.bind(_this));              

  }

  updateDialog():void {

    let _this = this;
    var ul = $('#sortable').sortable();

    this._dialog = $('#dialog').dialog({
      modal: true,
      autoOpen: false,
      buttons: [
        {
          text: this._translationService.translate('Shrani'),
          class: "btn saop-button-primary",
          click: function() {
            
            let dt = _this.dataTable as any;

            var cols = ul.sortable('toArray', { attribute: 'data-sort' });

            // //
            // dt.columns().header().each(function(value:any, index:any){
            //   if ( $(value).hasClass('no-sort') ) {
            //      // Set column width here
            //      global.ConsoleLogDegug('value:'+value);
            //      cols.unshift(index.toString());
            //   }
            // })       
            // //

            // cols.unshift("0");
            // cols.push("10");

            global.ConsoleLogDegug(cols);
            global.ConsoleLogDegug(dt.colReorder.transpose( cols ));
            cols = dt.colReorder.transpose( Array.from(cols.map(Number)) );
            global.ConsoleLogDegug(dt.colReorder.transpose( cols ));
            dt.colReorder.order(cols);

            var show = $('#sortable li > input:checked').map(function () {
              return this.parentElement.dataset.sort;
            });

            let show1 = Array.from(Array.from(show, item => Number(item)));

            // show1.unshift(0);
            // show1.push(10);                

            show1 = dt.colReorder.transpose( show1 );
            global.ConsoleLogDegug(show1);
            dt.columns( show1 ).visible(true);

            //var hide = $(cols).not(show);
            var hide = cols.filter((x:any) => show1.indexOf(x)===-1);
            global.ConsoleLogDegug(hide);
            dt.columns( hide ).visible(false);

            $(this).dialog('close');

            location.reload();
          }
        },
        {
          id:"btnCancel",
          text: _this._translationService.translate("Prekliči"),
          class: "btn saop-button-secondary",
          click: function() {
            $(this).dialog('close');
          }
        },        
      ],
      close: function() {
        //document.activeElement.blur();    
      } 
    });    

  }

  initEventListners():void{
    let _this = this;
    if (this._properties.dataTable.colResizeOnly == true){
      let _btnSettings = $("#"+this._properties.toolbar.id+"").find(".gridRessetColSettings");
      _btnSettings.off("click",_this.setDefaultColumnsState.bind(_this));
      _btnSettings.on("click",_this.setDefaultColumnsState.bind(_this));    
    }
    if (this._properties.dataTable.colSettings == true){
      //
      let _btnSetSettings = $("#"+this._properties.toolbar.id+"").find(".gridColSettings");
      _btnSetSettings.off("click",_this.createDialog.bind(_this));
      _btnSetSettings.on("click",_this.createDialog.bind(_this));       
      //       
    }
    //
    // let _gridExportToPDF = $("#"+this._properties.toolbar.id+"").find(".gridExportToPDF");
    // _gridExportToPDF.off("click",_this.exportToPdf.bind(_this));
    // _gridExportToPDF.on("click",_this.exportToPdf.bind(_this));           
  }

  setResizableColumn():void{
    //add phantom column in case of hidden columns
    $(this._properties.gridId+' tr').find("th:last").removeClass("saop-table-th-last");
    $(this._properties.gridId+' tr').find("th:last").addClass("saop-table-th-last-res");
  }

  //
}






