﻿/// <reference path="../@types/alertify/index.d.ts" />
import { filterTableRows, global, isUnDefined, setModalFormZIndex } from "../Common/saop-common";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import { SaopWfAction } from "../Core/saop-view-form";
import { SaopServicesCore } from "../Core/saop-services-core";
import { NDKInternalOrdersView } from "./NDKInternalOrders-view";
import { SaopTranslationService } from "../Core/saop-translate";

class InternaNarocilaData {
    letoNarocila: number;
    stevilkaNarocila: number;
    stevilkaVrstice: number;
    deleteFingerprint: string;
}

export class NDKInternalOrdersActiveGridOptions {
    dashboard: boolean = false;
    showToolbar: boolean = true;
    showFilter: boolean = true;
}

export class NDKInternalOrdersActiveGrid {
    private libName: string;
    private _gridOptions: NDKInternalOrdersActiveGridOptions;
    //
    private buttonCopyDisabledDivId: string = "#copyActiveInternalOrderDisabledDiv";
    private buttonCopyDivId: string = "#copyActiveInternalOrderDiv";
    private divAktivnaId: string = "#internaNarocilaAktivnaDiv";
    private webGridAktivnaId: string = "#NDKInternaNarocilaAktivnaGrid";
    private webGridAktivnaRowsBodyId: string = "#NDKInternaNarocilaAktivnaGridBodyAjax";
    public webGrid: SaopWebGrid;
    public ndkInternalOrdersView: NDKInternalOrdersView;
    public ndkInternalOrdersFullView: NDKInternalOrdersView;
    public formTranslationService: SaopTranslationService;
    private _searchValueId: string = "#ActiveTableSearchValue";
    private _mainFormDiv: JQuery<HTMLElement>;
    private _searchValue: JQuery<HTMLElement>;

    constructor(libName: string, gridOptions: NDKInternalOrdersActiveGridOptions = null) {
        if (isUnDefined(gridOptions)) {
            gridOptions = new NDKInternalOrdersActiveGridOptions();
        }
        this._gridOptions = gridOptions;

        this.libName = libName;  
        this.formTranslationService = new SaopTranslationService();
    }  

    initView() {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.initView");

        this._mainFormDiv = $(this.divAktivnaId);

        // prevajanje
        this.setFormTerminologyList();

        // refresh active internal orders
        this.refreshActiveInternalOrdersGridData();

        // init edit view
        this.initEditView();
    }

    private setFormTerminologyList() {
        let terminology = ["Interno naročilo za kopiranje ni izbrano."];

        this.formTranslationService.setTranslationsList(terminology);
    }     

    initEditView(): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.initEditView");

        this.ndkInternalOrdersView = new NDKInternalOrdersView("#ndkInternalOrdersView001", this.libName + ".ndkInternalOrdersView");
        this.ndkInternalOrdersView.refreshData = this.refreshDataAfterEditView.bind(this);

        this.ndkInternalOrdersFullView = new NDKInternalOrdersView("#ndkInternalOrdersView002", this.libName + ".ndkInternalOrdersFullView");
        this.ndkInternalOrdersFullView.fullScreen = true;   
        this.ndkInternalOrdersFullView.refreshData = this.refreshDataAfterEditView.bind(this);
    }

    // metoda se izvede po edit, add ali delete metodi v this.ndkInternalOrdersView
    refreshDataAfterEditView(sender: any): void { 
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.refreshDataAfterEditView");

        // osvezi se tabela
        this.refreshActiveInternalOrdersGridData(true);
        // klice se external metoda, da se lahko osvezijo se drugi podatki npr. right summary data
        this.afterRefreshDataAfterEditViewExternal(sender);
    }

    public afterRefreshDataAfterEditViewExternal(sender: any): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.afterRefreshDataAfterEditViewExternal");
    }

    refreshActiveInternalOrdersGridData(samoVrstice: boolean = false): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.refreshActiveInternalOrdersGridData");

        let _this = this;
        let params = { samoVrstice: samoVrstice, dashboard: this._gridOptions.dashboard };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.setCopyButtonsVisibility(false);
            _this.refreshGridData(response);
        };
        saopServicesCore.ExecuteAction({
            action:"InternalOrder/GetActiveInternalOrdersTable" 
            ,data:params
            ,type:"GET"
        });     
    }

    refreshGridData(htmlContent: string) {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.refreshGridData");

        if ($(this.webGridAktivnaId).length) {
            $(this.webGridAktivnaRowsBodyId).html(htmlContent);
            this.initWebGridListners();
            if (!isUnDefined(this._searchValue) && this._searchValue.length) {
                this.executeFilterSearch(this._searchValue.val().toString());
            }
        }
        else {
            $(this.divAktivnaId).html(htmlContent);
            this.getWebGridToolbarAndInitWebGrid(); 
        }
    }

    getWebGridToolbarAndInitWebGrid(): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.getWebGridToolbarAndInitWebGrid");

        let _this = this;
        let params = { javascriptKnjiznica: _this.libName };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.setCopyButtonsVisibility(false);
            _this.initWebGrid(response);
            _this.initFilterControls();  
        };
        saopServicesCore.ExecuteAction({
            action: "InternalOrder/GetActiveTableToolbar"
            , data: params
            , type: "GET"
        });
    }

    initWebGrid(toolbarTemplate: string = "") {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.initWebGrid");

        let captionToolbar = $(this.webGridAktivnaId).attr("caption-toolbar");
        let captionAddButton = $(this.webGridAktivnaId).attr("caption-addButton");
        //
        this.webGrid = new SaopWebGrid(this.libName + ".webGrid");
        this.webGrid.afterColumnCheckboxChanged = this.webGridAfterColumnCheckboxChanged.bind(this);
        let gridProperties: SaopWebGridProperties;
        let gridToolbar: SaopWebGridToolbar;
        let toolbarButtons: SaopWebGridToolbarButton[] = [];
        let dataTable: SaopWebDataTable;
        //
        if (this._gridOptions.showToolbar) {
            toolbarButtons.push({ id: "", caption: "", template: toolbarTemplate });
            gridToolbar = { id: "saopWebGridTB1", caption: captionToolbar, buttons: toolbarButtons };
        }

        dataTable = {enabled: true};

        gridProperties = {
            gridId: this.webGridAktivnaId
            , toolbar: gridToolbar
            , dataTable: dataTable
        };
        //
        this.webGrid.init(gridProperties);

        this.initWebGridListners();

        this.setDashboardSpecificView();
    }

    initWebGridListners() {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.initWebGridListners");

        let _this = this;

        // prikaz full screen ko klikne na vrstico
        $("body").off("click", this.webGridAktivnaId + " tr td:not(.td-action,.td-action-checkbox)");
        $("body").on("click", this.webGridAktivnaId + " tr td:not(.td-action,.td-action-checkbox)", function () {
            let selectedRow = <HTMLElement>$(this).closest("tr")[0];
            let data = _this.getInternaNarocilaDataAttribute(selectedRow);

            if (!isUnDefined(data.letoNarocila) && !isUnDefined(data.stevilkaNarocila) && !isUnDefined(data.stevilkaVrstice)) {
                _this.ndkInternalOrdersFullView.showViewForm(SaopWfAction.wfaEditRecord, { letoNarocila: data.letoNarocila, stevilkaNarocila: data.stevilkaNarocila, stevilkaVrstice: data.stevilkaVrstice });
            }
        });

        $(this.webGridAktivnaId + " .NDKInternaNarocilaGrid_edit").off("click");
        $(this.webGridAktivnaId + " .NDKInternaNarocilaGrid_edit").on('click', function (row) {
            global.ConsoleLogDegug("NDKInternaNarocilaGrid_edit.click");
            let _btnEdit = row.currentTarget;
            let btnEnabled = _btnEdit.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let data = _this.getInternaNarocilaDataAttribute(_btnEdit);
                _this.ndkInternalOrdersFullView.showViewForm(SaopWfAction.wfaEditRecord, { letoNarocila: data.letoNarocila, stevilkaNarocila: data.stevilkaNarocila, stevilkaVrstice: data.stevilkaVrstice }); 
            }
        });

        $(this.webGridAktivnaId + " .NDKInternaNarocilaGrid_delete").off("click");
        $(this.webGridAktivnaId + " .NDKInternaNarocilaGrid_delete").on('click', function (row) {
            global.ConsoleLogDegug("NDKInternaNarocilaGrid_delete.click");
            let _btnDelete = row.currentTarget;
            let btnEnabled = _btnDelete.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let data = _this.getInternaNarocilaDataAttribute(_btnDelete);
                _this.ndkInternalOrdersView.deleteRecord({ letoNarocila: data.letoNarocila, stevilkaNarocila: data.stevilkaNarocila, stevilkaVrstice: data.stevilkaVrstice, deleteFingerprint: data.deleteFingerprint });
            }
        });

        this.webGrid.initWebGridColumnCheckboxBindClickEvent();
    }

    webGridAfterColumnCheckboxChanged(): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.webGridAfterColumnCheckboxChanged");

        let checked = this.getCheckboxCheckValue(this.webGrid.markedColumnCheckbox);;
        this.setCopyButtonsVisibility(checked);
    }

    setCopyButtonsVisibility(checked: boolean): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.setCopyButtonsVisibility");

        let buttonCopyDisabledDivElement = this._mainFormDiv.find(this.buttonCopyDisabledDivId);
        let buttonCopyDivElement = this._mainFormDiv.find(this.buttonCopyDivId);

        if (checked == false) {
            buttonCopyDisabledDivElement.show();
            buttonCopyDivElement.hide();
        } else {
            buttonCopyDisabledDivElement.hide();
            buttonCopyDivElement.show();
        }
    }

    getInternaNarocilaDataAttribute(element: HTMLElement): InternaNarocilaData {
        let data = new InternaNarocilaData();

        data.letoNarocila = parseInt(element.getAttribute("data-letonarocila"));
        data.stevilkaNarocila = parseInt(element.getAttribute("data-stevilkanarocila"));
        data.stevilkaVrstice = parseInt(element.getAttribute("data-stevilkavrstice"));
        data.deleteFingerprint = element.getAttribute("data-deletefingerprint");

        return data;
    }

    initFilterControls(): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.initFilterControls");

        this._searchValue = this._mainFormDiv.find(this._searchValueId);
        let _this = this;

        this._searchValue.off("keyup");
        this._searchValue.on("keyup", function () {
            _this.executeFilterSearch($(this).val().toString());
        });
    }

    executeFilterSearch(searchVal: string): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.executeFilterSearch");

        // tole ni delovalo: ko se je spremenilo leto, je se vedno filtriral stare podatke od prejsnjega leta
        //let oTable = this.webGrid.dataTable;
        //oTable.search(searchVal).draw();

        filterTableRows(searchVal, this.divAktivnaId);
    }

    addRecord() {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.addRecord");
        this.ndkInternalOrdersView.showViewForm(SaopWfAction.wfaAddRecord, null, null);
    }  

    copyRecord() {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.copyRecord");
        this.copyInternalOrder(this.webGrid.markedColumnCheckbox);
    }

    copyInternalOrder(checkbox: JQuery<HTMLInputElement>): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.copyInternalOrder");

        let checked = this.getCheckboxCheckValue(checkbox);

        if (checked) {
            let data = this.getInternaNarocilaDataAttribute(checkbox[0]);
            this.ndkInternalOrdersView.showViewForm(SaopWfAction.wfaAddRecord, { letoNarocilaInput: data.letoNarocila, stevilkaNarocilaInput: data.stevilkaNarocila, stevilkaVrsticeInput: data.stevilkaVrstice }, null);
        }
        else {
            //potrebno izbrati vrstico "Interno naročilo za kopiranje ni izbrano."
            alertify.message(this.formTranslationService.translate("Interno naročilo za kopiranje ni izbrano."));
        }
    }

    getCheckboxCheckValue(checkbox: JQuery<HTMLInputElement>): boolean {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.getCheckboxCheckValue");

        let checked = false;

        if (isUnDefined(checkbox)) {
            checked = false;
        } else {
            checked = checkbox.is(':checked');
        }

        return checked;
    }

    setDashboardSpecificView(): void {
        global.ConsoleLogDegug("NDKInternalOrdersActiveGrid.setDashboardSpecificView");

        let isDashBNoardView = $(this.webGridAktivnaId).attr("data-dashboardview").toLowerCase() == "true" ? true : false;

        if (isDashBNoardView) {
            $(this.webGridAktivnaId + "_wrapper").addClass("saop-table-dashboard-odsotnost");
        }
    }
}






