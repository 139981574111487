﻿import { global, isUnDefined } from "../Common/saop-common";
import { SaopRegUraDogodekView } from "../REG/saop-regUraDogodek-view";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopWebGrid, SaopWebGridProperties, SaopWebGridToolbar, SaopWebGridToolbarButton, SaopWebDataTable } from "../Components/saop-grid";
import { SaopRegUraDogodekDeleteOneView } from "../REG/saop-regUraDogodekDeleteOne-view"
import * as moment from '../../node_modules/moment/moment';


export class SaopRegUraDanView extends SaopRegUraDogodekView {
    private _libNameP:string;
    private _saopServicesCore :SaopServicesCore;
    private _selectedDate:string;  
    private webGrid1:SaopWebGrid;
    private webGrid2:SaopWebGrid;
    private _addRecordEnabledId: string;
    private _addButton1Id: string;
    private _sifraZaposleni:string;
    private _revizijskaSled: string;
    private _regUraDogodekDeleteOneView: SaopRegUraDogodekDeleteOneView;

    constructor(viewId:string,libName:string = "") {
        global.ConsoleLogDegug("SaopRegUraDanView.constructor");
        super(viewId,libName);
        this._libNameP = libName;
        this._addRecordEnabledId = "#addRecordEnabled";
        this._addButton1Id = "#addButton1";
        //
        this.setDictionary();
        //
        this._saopServicesCore = new SaopServicesCore();
        //
        this.refreshData = this.refreshListData.bind(this);
        //
        this._regUraDogodekDeleteOneView = new SaopRegUraDogodekDeleteOneView("#regDogodekView0", libName + "._regUraDogodekDeleteOneView");
        this._regUraDogodekDeleteOneView.afterDeleteSuccess = this.afterDeleteEventsSuccess.bind(this);
        this._regUraDogodekDeleteOneView.afterValidationError = this.onDeleteValidationError.bind(this);
    }

    initView():void{
        this.initWebGrid1();
        this.initWebGrid1Listners();
        this.initWebGrid2();
        this.initWebGrid2Listners();
        this.bindViewData();
    }

    bindViewData():void {
        this._selectedDate = <string>$("#SelectedDate1").val();
        this._sifraZaposleni = <string>$("#reg-grid #SifraZaposleni").val();
        this._revizijskaSled = <string>$("#RevizijskaSled").val();
        global.ConsoleLogDegug("selectedDate:" + this._selectedDate);
        global.ConsoleLogDegug("RevizijskaSled:" + this._revizijskaSled);
    }

    setDictionary():void{
        let dictionary:string[] = ["Brisanje","Ali ste prepričani, da želite izbrisati dogodek?"];
        this.setTerminologyList(dictionary);
    }     

    initWebGrid1():void{
        let captionToolbar = $("#saopWebGrid1").attr("caption-toolbar");
        let captionAddButton = $("#saopWebGrid1").attr("caption-addButton");
        //
        this.webGrid1 = new SaopWebGrid(this._libNameP);
        let gridProperties: SaopWebGridProperties;
        let gridToolbar:SaopWebGridToolbar;
        let toolbarButtons:SaopWebGridToolbarButton[] = [];
        //
        toolbarButtons.push({id: this._addButton1Id.replace("#",""),caption:captionAddButton,onClickEvent:this._libName+".addRecordEx();"});        
        gridToolbar = {id:"saopWebGridTB1",caption:captionToolbar,buttons:toolbarButtons};
        gridProperties = {
            gridId:"#saopWebGrid1"
            ,css:"table-column-holder-last"
            ,toolbar:gridToolbar
            ,dataTable:{enabled:true,ordering:false}
          };        
        //
        this.webGrid1.init(gridProperties);   

        this.refreshButtonsState();
    }

    initWebGrid1Listners():void{
        let _this = this;
        $(".regDayEvents_edit").off("click");
        $(".regDayEvents_edit").on('click', function(row){
            global.ConsoleLogDegug("regDayEvents_edit.click");
            let _btnEdit = row.currentTarget;
            let btnEnabled = _btnEdit.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let data_idStart = _btnEdit.getAttribute("data-idstart");
                let data_idEnd = _btnEdit.getAttribute("data-idend");
                _this.editRecord({ idStart: data_idStart, idEnd: data_idEnd });
            }            
        });     
        
        $(".regDayEvents_delete").off("click");
        $(".regDayEvents_delete").on('click', function(row){
            global.ConsoleLogDegug("regDayEvents_delete.click");
            let _btnEdit = row.currentTarget;
            let btnEnabled = _btnEdit.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let data_idStart = _btnEdit.getAttribute("data-idstart");
                let data_idEnd = _btnEdit.getAttribute("data-idend");
                if (_this._revizijskaSled.toString().toLowerCase() == "true") {
                    let trail_parms = {
                        idStart: data_idStart,
                        idEnd: data_idEnd,
                        auditTrail: _this._revizijskaSled.toString().toLowerCase() == "true"
                    }
                    _this._regUraDogodekDeleteOneView.showForm({ IdStart: trail_parms.idStart, IdEnd: trail_parms.idEnd, AuditTrail: trail_parms.auditTrail });
                }
                else{
                    _this.deleteRecord(
                        _this.translationService.translate("Brisanje")
                        , _this.translationService.translate("Ali ste prepričani, da želite izbrisati dogodek?")
                        , { idStart: data_idStart, idEnd: data_idEnd }
                    );
                }
            }            
        });         

        $("#showPreviousDayData").off("click");
        $("#showPreviousDayData").on('click', function(row){
            global.ConsoleLogDegug("showPreviousDayData.click");
           _this.showPreviousDayData();
        });  

        $("#showNextDayData").off("click");
        $("#showNextDayData").on('click', function(row){
            global.ConsoleLogDegug("showNextDayData.click");
           _this.showNextDayData();
        });          

    }

    initWebGrid2Listners():void {
        global.ConsoleLogDegug("initWebGrid2Listners...");
        let _this = this;
        let _btnRefresh = $(".form-today-events").find("#btnRefresh");
        if (_btnRefresh.length > 0) {
            _btnRefresh.off("click");
            _btnRefresh.on("click", function () {
                global.ConsoleLogDegug("btnRefresh.click...");
                _this.refreshChainingEvents();                
            });
        } else {
            console.log("error:.form-today-events.btnRefresh not found...");
        }  
    }

    addRecordEx():void{
        this.addRecord({ SelectedDay:this._selectedDate, sifraZaposleni:this._sifraZaposleni });
    }      

    initWebGrid2(){
        let captionToolbar = $("#saopWebGrid2").attr("caption-toolbar");
        let toolbarItemsTemplateId = $("#saopWebGrid2").attr("toolbar-items-templateId");
        //
        this.webGrid2 = new SaopWebGrid(this._libNameP);
        let gridProperties: SaopWebGridProperties;
        let gridToolbar:SaopWebGridToolbar;
        //
        gridToolbar = {id:"saopWebGridTB2",caption:captionToolbar, itemsTemplateId:toolbarItemsTemplateId};
        gridProperties = {
            gridId:"#saopWebGrid2"
            ,toolbar:gridToolbar
            ,dataTable:{enabled:true,ordering:false}
          };        
        //
        this.webGrid2.init(gridProperties);        
    }

    showNextDayData():void{
        global.ConsoleLogDegug("SaopRegUraDanView.showNextDayData");
        let parsedDate = moment(this._selectedDate,"DD.MM.YYYY").add(1, 'days');

        //premikamo se lahko samo od prvega do zadnjega v mesecu _selectedDate
        if (moment(this._selectedDate,"DD.MM.YYYY").endOf('month').isBefore(moment(parsedDate,"DD.MM.YYYY"))){
            $('#showNextDayData').prop("disabled", true);
        }
        else {
            $('#showNextDayData').prop("disabled", false);
            let parsedDateF = parsedDate.format("DD.MM.YYYY");
            this._selectedDate = parsedDateF;
            this.refreshListData(); 
        }
    }

    showPreviousDayData():void{
        global.ConsoleLogDegug("SaopRegUraDanView.showPreviousDayData");
        let parsedDate = moment(this._selectedDate,"DD.MM.YYYY").add(-1, 'days');

        //premikamo se lahko samo od prvega do zadnjega v mesecu _selectedDate
        if (moment(this._selectedDate,"DD.MM.YYYY").startOf('month').isAfter(moment(parsedDate, "DD.MM.YYYY"))) {
            $('#showPreviousDayData').prop("disabled", true);
        }
        else {
            $('#showPreviousDayData').prop("disabled", false);
            let parsedDateF = parsedDate.format("DD.MM.YYYY");
            this._selectedDate = parsedDateF;
            this.refreshListData();
        }
    }    

    afterRefreshData():void{
        global.ConsoleLogDegug("SaopRegUraDanView.afterRefreshListData");
    }

    refreshListData(trigger:boolean = true):void{
        global.ConsoleLogDegug("SaopRegUraDanView.refreshListData");
        global.ConsoleLogDegug(trigger);
        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            $("#fullscreenModalBody").html(response);

            if (trigger && _this.afterRefreshData != null){ 
                _this.afterRefreshData(); 
            }
        };
        this._saopServicesCore.ExecuteAction({
            action:"REGDayEvents/GetRegUraBrowseViewPV?SelectedDay=" + _this._selectedDate+"&employeeId="+_this._sifraZaposleni
            ,data:null
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingSidebarr"
        });    
    }

    refreshButtonsState(): void {
        global.ConsoleLogDegug("SaopRegUraDanView.refreshButtonsState");

        if (typeof $(this._addRecordEnabledId).val() === 'undefined') {
            return;
        }
        let _btnAddRecordEnabled = $(this._addRecordEnabledId).val().toString();
        if (!isUnDefined(_btnAddRecordEnabled) && _btnAddRecordEnabled.toLowerCase() == "true") {
            $(this._addButton1Id).removeAttr("disabled");
        } else {
            $(this._addButton1Id).attr("disabled", "true");
        }
    }

    public afterDeleteEventsSuccess(result: any): void {
        global.ConsoleLogDegug("afterDeleteEventsSuccess...");
        this.refreshListData();
    }

    public onDeleteValidationError(result: any): void {
        global.ConsoleLogDegug("onDeleteValidationError...");
        this._regUraDogodekDeleteOneView.afterViewShowSuccess(result);
    }

    refreshChainDataTable(leto: number, mesec: number, dan :number, IdZaposleni:string): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.refreshAllEmployeesTable");

        let _this = this;
        let saopServicesCore = new SaopServicesCore();
        let params = { leto: leto, mesec: mesec, dan : dan, IdZaposleni : IdZaposleni };

        saopServicesCore.afterDone = function (response) {

        };
        saopServicesCore.ExecuteAction({
            action: "REGAttendanceAllEmployees/GetAllEmployeesTable"
            , data: params
            , type: "GET"
            , spinnerDivId: "#attendanceAllEmployeesLoading"
        });    

    }

    refreshChainingEvents():void{
        global.ConsoleLogDegug("SaopRegUraDanView.refreshListData");
        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            $("#fullscreenModalBody").html(response);
            if (_this.afterRefreshData != null){ 
                _this.afterRefreshData(); 
            }
        };
        this._saopServicesCore.ExecuteAction({
            action:"REGDayEvents/GetRegUraBrowseViewPV?SelectedDay=" + _this._selectedDate+"&employeeId="+_this._sifraZaposleni+"&refresh=y"
            ,data:null
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingSidebarr"
        });    
    }    

    //
}


