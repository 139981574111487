﻿import { global, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopMultiselect } from "../Components/saop-multiselect";
import { SaopUploadFiles } from "../Components/saop-upload-files";
import { SaopAttachmentList } from "../Components/saop-attachment-list";

class TipInternegaNarocilaServis {
    sifra: string;
    naziv: string;
    sifreVrsteInternegaNarocilaServis: string;
}

class SifraVrsteInternegaNarocilaPriponke {
    sifreVrsteInternegaNarocila: string;
}

class ArtikelEnotaMereViewModel {
    SifraArtikla: string;
    MerskaEnota: string;
}

export class NDKInternalOrderEditForm {
    private libName: string;
    private _viewId: string;
    private _formId: string;
    private _vrstaInternegaNarocilaId: string = "#SifraVrsteInternihNarocil";
    private _tipInternegaNarocilaId: string = "#TipInternegaNarocila";
    private _sifraArtiklaDivId: string = "#sifraArtiklaDiv";
    private _kolicinaDivId: string = "#kolicinaDiv";
    private _sifraNapraveDivId: string = "#sifraNapraveDiv";
    private _artikelOpisDivId: string = "#artikelOpisDiv";
    private _priponkeBrisaneId: string = "#PriponkeBrisane";
    private _dataTipServisInputId: string = "#dataTipServisInput";
    private _merskaEnotaHiddenId: string = "#ArtikelEnotaMere";
    private _merskaEnotaDisplayId: string = "#ArtikelEnotaMereDisplay";
    private _artikelEnotaMereListJsonId: string = "#ArtikelEnotaMereListJson";
    private _selectArtikel: SaopMultiselect;
    private _selectNaprava: SaopMultiselect;
    private _saopUploadFiles: SaopUploadFiles;
    private _saopAttachmentList: SaopAttachmentList;
    private _tipInternegaNarocilaServisData: TipInternegaNarocilaServis;
    private _sifraVrsteInternegaNarocilaPriponkeId: string = "#vrsteInternegaNarocilaPriponkeInput";
    private _sifraVrsteInternegaNarocilaPriponke: SifraVrsteInternegaNarocilaPriponke;
    private _uploadAttachmentElementId = "#uploadAttachment";
    private _artikelEnotaMereViewModelList: ArtikelEnotaMereViewModel[] = [];

    constructor(viewId: string, formId: string, libName: string = ""){
        this.libName = libName; 
        this._viewId = viewId;
        this._formId = formId;

        this._saopUploadFiles = new SaopUploadFiles(viewId, libName + "._saopUploadFiles"); 
        this._saopAttachmentList = new SaopAttachmentList("#internalOrdersAttachments", libName + "._saopAttachmentList");
    }

    init() {
        global.ConsoleLogDegug("NDKInternalOrderEditForm.init");

        let _this = this;

        this._artikelEnotaMereViewModelList = $.parseJSON($(this._viewId + ' ' + this._artikelEnotaMereListJsonId).attr("data-artikelenotamereList"));

        this._tipInternegaNarocilaServisData = this.getTipInternegaNarocilaServis();
        this._sifraVrsteInternegaNarocilaPriponke = this.getVrsteInternegaNarocilaPriponke();

        this._saopUploadFiles.initComponent({ controller: "InternalOrder", action: "ValidateFiles", validateAjax: true, formId: this._formId, validationDivId: this._viewId + " #uploadFilesValidationsAjax", allowSelectMultiFiles: false });
        this._saopUploadFiles.afterRefreshFilesList = this._saopUploadFiles.ajaxValidate;
        this._saopAttachmentList.initComponent(
            {
                validationFingerprintController: "SPLDocument",
                validationFingerprintAction: "ValidateAttachmentFingerPrint",
                validationFingerprintBannerDivId: this._viewId + " #uploadFilesValidationsAjax",
                validationController: "SPLDocument",
                validationAction: "ValidateDownloadingDocumentAttachment",
                validationBannerDivId: this._viewId + " #uploadFilesValidationsAjax",
                downloadController: "SPLDocument",
                downloadAction: "DownloadDocumentAttachment",
                deletedAttachmentListId: this._viewId + " " + this._priponkeBrisaneId
            });

        $(this._viewId + ' #btnOk').off('click');
        $(this._viewId + ' #btnOk').bind('click',
            function () {
                let _result = _this._saopUploadFiles.ajaxValidate();
                return _result;
            }
        );    

        // glede na vrsto internega narocila se doda/odstrani "Zahtevek za servis naprave"
        this.vrstaInternegaNarocilaChange()

        let vrstaInternegaNarocilaElement = $(this._viewId + " " + this._vrstaInternegaNarocilaId);
        $(vrstaInternegaNarocilaElement).off("change");
        $(vrstaInternegaNarocilaElement).on('change', function () {
            _this.vrstaInternegaNarocilaChange();
        });

        // inicializiramo elemente glede na tip
        this.tipInternegaNarocilaChange();

        let tipInternegaNarocilaElement = $(this._viewId + " " + this._tipInternegaNarocilaId);
        $(tipInternegaNarocilaElement).off("change");
        $(tipInternegaNarocilaElement).on('change', function (row) {
            _this.tipInternegaNarocilaChange();
        });

        this._selectNaprava = new SaopMultiselect();
        this._selectNaprava.afterInitializedControl = this.afterInitializedControlSelectNaprava.bind(this);
        this._selectNaprava.init(this._viewId + " #sifraNapraveSelect");

        this._selectArtikel = new SaopMultiselect();
        this._selectArtikel.afterInitializedControl = this.afterInitializedControlSelectArtikel.bind(this);
        this._selectArtikel.init(this._viewId + " #sifraArtiklaSelect");
        this._selectArtikel.afterChangeCommonEvent = this.showArtikelMerskaEnota.bind(this);
    }

    afterInitializedControlSelectNaprava(element: any) {
        global.ConsoleLogDegug("NDKInternalOrderEditForm.afterInitializedControlSelectNaprava");
        this.inicializeEditSelectControl(this._selectNaprava);
    }

    afterInitializedControlSelectArtikel(element: any) {
        global.ConsoleLogDegug("NDKInternalOrderEditForm.afterInitializedControlSelectArtikel");
        this.inicializeEditSelectControl(this._selectArtikel);
    }

    inicializeEditSelectControl(multiSelect: SaopMultiselect) {
        global.ConsoleLogDegug("NDKInternalOrderEditForm.inicializeEditSelectControl");

        multiSelect.msContainer.css('width', '260px');
    }

    tipInternegaNarocilaChange(): void {
        global.ConsoleLogDegug("NDKInternalOrderEditForm.tipInternegaNarocilaChange");

        let tipInternegaNarocilaElement = $(this._viewId + " " + this._tipInternegaNarocilaId);
        let izbranTip = <string>tipInternegaNarocilaElement.val();

        let sifraArtiklaDivElement = $(this._viewId + " " + this._sifraArtiklaDivId);
        let kolicinaDivElement = $(this._viewId + " " + this._kolicinaDivId);
        let sifraNapraveDivElement = $(this._viewId + " " + this._sifraNapraveDivId);
        let artikelOpisDivElement = $(this._viewId + " " + this._artikelOpisDivId);

        if (this.isTipInternegaNarocilaOpisno(izbranTip)) {
            sifraArtiklaDivElement.hide();
            kolicinaDivElement.hide();
            sifraNapraveDivElement.hide();
            artikelOpisDivElement.show();
        }
        else if (this.isTipInternegaNarocilaArtikel(izbranTip)) {
            sifraArtiklaDivElement.show();
            kolicinaDivElement.show();
            sifraNapraveDivElement.hide();
            artikelOpisDivElement.hide();

        }
        else if (this.isTipInternegaNarocilaServis(izbranTip)) {
            sifraArtiklaDivElement.hide();
            kolicinaDivElement.hide();
            sifraNapraveDivElement.show();
            artikelOpisDivElement.show();
        }
        else {
            sifraArtiklaDivElement.hide();
            kolicinaDivElement.hide();
            sifraNapraveDivElement.hide();
            artikelOpisDivElement.hide();
        }
    }

    isTipInternegaNarocilaOpisno(tip: string) {
        return tip.toUpperCase() == 'O' ? true : false;
    }

    isTipInternegaNarocilaArtikel(tip: string) {
        return tip.toUpperCase() == 'A' ? true : false;
    }

    isTipInternegaNarocilaServis(tip: string) {
        return tip.toUpperCase() == 'S' ? true : false;
    }

    vrstaInternegaNarocilaChange(): void {
        global.ConsoleLogDegug("NDKInternalOrderEditForm.vrstaInternegaNarocilaChange");

        if (this._tipInternegaNarocilaServisData.sifra == "") {
            return;
        }

        let vrstaInternegaNarocilaElement = $(this._viewId + " " + this._vrstaInternegaNarocilaId);
        let izbranaVrsta = <string>vrstaInternegaNarocilaElement.val();

        let tipInternegaNarocilaHTMLElement = <HTMLSelectElement>document.querySelector(this._viewId + " " + this._tipInternegaNarocilaId);
        let tipInternegaNarocilaJqueryElement = $(this._viewId + " " + this._tipInternegaNarocilaId);

        let jeIzbranaVrstaZaServis = this._tipInternegaNarocilaServisData.sifreVrsteInternegaNarocilaServis.includes(izbranaVrsta);

        if (isEmpty(izbranaVrsta) || isUnDefined(izbranaVrsta) || izbranaVrsta == "?" || !jeIzbranaVrstaZaServis) {
            // prazen izbor
            // preverimo, če je slucajno izbran zahtevek za servis, 
            // potem je potrebno tudi spremeniti izbrano vrstaInternegaNarocila select element
            let izbranTip = <string>tipInternegaNarocilaJqueryElement.val();

            if (this.isTipInternegaNarocilaServis(izbranTip)) {
                // izbrani tip je servis, tip internega narocila select element postavimo na prazen izbor
                $(this._viewId + " " + this._tipInternegaNarocilaId).val("?").change();
            }

            // preverimo, ce je zahtevek za servis v seznamu tipov internega narocila in ga odstranimo
            if ($(this._viewId + " " + this._tipInternegaNarocilaId + " option[value='" + this._tipInternegaNarocilaServisData.sifra + "']").length > 0) {
                tipInternegaNarocilaHTMLElement.options[tipInternegaNarocilaHTMLElement.options.length - 1] = null;
            }
        }
        else {
            // ni prazen
            // preverimo, ce je zahtevek za servis ni v seznamu tipov internega narocila in ga dodamo
            if ($(this._viewId + " " + this._tipInternegaNarocilaId + " option[value='" + this._tipInternegaNarocilaServisData.sifra + "']").length == 0) {
                let servisOption = new Option(this._tipInternegaNarocilaServisData.naziv, this._tipInternegaNarocilaServisData.sifra);
                tipInternegaNarocilaHTMLElement.options[tipInternegaNarocilaHTMLElement.options.length] = servisOption;
            }
        }

        // preverimo, ce je izbrana vrsta za priponke
        let jeIzbranaVrstaZaPriponke = this._sifraVrsteInternegaNarocilaPriponke.sifreVrsteInternegaNarocila.includes(izbranaVrsta);
        let attachmentElement = $(this._viewId + " " + this._uploadAttachmentElementId);

        if (isEmpty(izbranaVrsta) || isUnDefined(izbranaVrsta) || izbranaVrsta == "?" || !jeIzbranaVrstaZaPriponke) {
            attachmentElement.hide();
        }
        else {
            attachmentElement.show();
        }
    }

    getTipInternegaNarocilaServis(): TipInternegaNarocilaServis {
        global.ConsoleLogDegug("NDKInternalOrderEditForm.getTipInternegaNarocilaServis");

        let data = new TipInternegaNarocilaServis();
        let element = $(this._viewId + " " + this._dataTipServisInputId);

        data = element.data('tipservis');

        return data;
    }

    getVrsteInternegaNarocilaPriponke(): SifraVrsteInternegaNarocilaPriponke {
        global.ConsoleLogDegug("NDKInternalOrderEditForm.getVrsteInternegaNarocilaPriponke");

        let data = new SifraVrsteInternegaNarocilaPriponke();
        let element = $(this._viewId + " " + this._sifraVrsteInternegaNarocilaPriponkeId);

        data = element.data('vrsteinterneganarocilapriponke');

        return data;
    }

    showArtikelMerskaEnota(element: any) {
        global.ConsoleLogDegug("NDKInternalOrderEditForm.showArtikelMerskaEnota");

        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraniArtikel = (<string>(element.val().toString())).trim();
        let izbraniArtikellMerskaEnota = "";
        if (!isEmpty(izbraniArtikel)) {
            // poisci mersko enoto za artikel
            this._artikelEnotaMereViewModelList.forEach((item) => {
                console.log("item", item);
                if (item.SifraArtikla.trim() === izbraniArtikel.trim()) {
                    izbraniArtikellMerskaEnota = item.MerskaEnota;
                }
            });
        }

        // zapisi mersko enoto v text kontrole
        $(this._viewId + ' ' + this._merskaEnotaHiddenId).val(izbraniArtikellMerskaEnota);
        $(this._viewId + ' ' + this._merskaEnotaDisplayId).val(izbraniArtikellMerskaEnota);
    }
}






