﻿/// <reference path="../@types/alertify/index.d.ts" />
import { filterTableRows, global, isUnDefined, isEmpty } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopAbsenceConfirmationProcessedView } from "./saop-absenceConfirmation-processed-view";
import { SaopAbsenceConfirmationRejectView } from "./saop-absenceConfirmation-reject-view";
import { SaopAbsenceConfirmationPartiallyApproveView } from "./saop-absenceConfirmation-partially-approve-view";
import { SaopAbsenceConfirmationDetailsView } from "./saop-absenceConfirmation-details-view";
import { SaopMessageBanner } from '../Components/saop-message-banner';
import { SaopTranslationService } from '../Core/saop-translate';
import { SaopMessageDlg, SaopMsgDlgButton } from '../Components/saop-message-dlg';
import { SaopWebGridUnitOrgFilter } from "../Components/saop-grid-filter-UnitOrg";

class KePlanDopustovPK {
    leto: number;
    sifraZaposleni: string; 
    zaporednaStevilka: number;
}

class DataColumnAttributes extends KePlanDopustovPK {
    title: string;
}

class AutoShowAbsenceDetailsData extends KePlanDopustovPK {
    hasPKValue: boolean;
    valid: boolean;
    invalidMessage: string;
}

export class SaopAbsenceConfirmationPendingView {
    private _viewId: string
    private _libName: string;
    private _rejectViewId: string
    private _partiallyApproveViewId: string;
    private _detailsViewId: string
    private _saopServicesCore: SaopServicesCore;
    private _searchAllFieldsValueId: string;
    private _selectOrgFilter: SaopWebGridUnitOrgFilter;
    private _tableId: string;
    private _autoShowAbsenceDetailsId: string;
    private _autoShowAbsenceDetailsInvalidMessageId: string;


    private saopAbsenceConfirmationRejectView: SaopAbsenceConfirmationRejectView;
    private saopAbsenceConfirmationPartiallyApproveView: SaopAbsenceConfirmationPartiallyApproveView;
    private saopAbsenceConfirmationDetailsView: SaopAbsenceConfirmationDetailsView;
    private saopMessageBanner: SaopMessageBanner;

    private saopMessageDlg: SaopMessageDlg;
    private _translationService: SaopTranslationService;

    constructor(viewId: string, libName: string = "") {
        this._viewId = viewId;
        this._libName = libName;
        this._rejectViewId = "#absenceConfirmationRejectView0";
        this._partiallyApproveViewId = "#absenceConfirmationPartiallyApproveView0";
        this._detailsViewId = "#absenceConfirmationDetailsView0";
        this._saopServicesCore = new SaopServicesCore();
        this._searchAllFieldsValueId = "#OdsotnostiCakajoceSearchValue";

        this._selectOrgFilter = new SaopWebGridUnitOrgFilter(libName, "Pending");
        this._selectOrgFilter.filterZaposleniTableRows = this.filterZaposleniTableRows.bind(this);

        this._tableId = "#absenceConfirmationPendingTable";
        this._autoShowAbsenceDetailsId = "#autoShowAbsenceDetails";
        this._autoShowAbsenceDetailsInvalidMessageId = "#autoShowAbsenceDetailsInvalidMessage";

        this.saopAbsenceConfirmationRejectView = new SaopAbsenceConfirmationRejectView(this._rejectViewId, this._libName + ".saopAbsenceConfirmationRejectView");
        this.saopAbsenceConfirmationRejectView.refreshData = this.refreshData.bind(this);

        this.saopAbsenceConfirmationPartiallyApproveView = new SaopAbsenceConfirmationPartiallyApproveView(this._partiallyApproveViewId, this._libName + ".saopAbsenceConfirmationPartiallyApproveView");
        this.saopAbsenceConfirmationPartiallyApproveView.refreshData = this.refreshData.bind(this);

        this.saopAbsenceConfirmationDetailsView = new SaopAbsenceConfirmationDetailsView(this._detailsViewId, this._libName + ".saopAbsenceConfirmationDetailsView");
        this.saopAbsenceConfirmationDetailsView.refreshDataExtended = this.refreshData.bind(this);

        this.saopMessageBanner = new SaopMessageBanner();

        this.saopMessageDlg = new SaopMessageDlg({ data: null, parent: null })
        this._translationService = new SaopTranslationService();
        this.setTerminologyList();
    }

    setTerminologyList() {
        let dictionary: string[] = ["Potrdi"
            , "Pozor, število dni predlaganega dopusta je večje kot število dni, ki ga imate na razpolago. Želite nadaljevati?"
            , "Pozor, vpisano število ur predlaganega koriščenja je večje od števila ur, ki jih imate na razpolago. Ali želite nadaljevati?"];
        this._translationService.setTranslationsList(dictionary);
    }   

    initView(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.initView");

        this.initControls();
    }

    initControls(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.initControls");
        let _this = this;

        $(this._searchAllFieldsValueId).keyup(function () {
            let filterVal = String($(_this._searchAllFieldsValueId).val());
            filterTableRows(filterVal, _this._tableId);
        });

        this._selectOrgFilter.initFilterControls();
    }

    bindGridEvents() {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.bindGridEvents");
        let _this = this;

        $(".btnRejectKEPlanDopustov").off("click");
        $(".btnRejectKEPlanDopustov").on('click', function (row) {
            global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.btnDetailsKEPlanDopustov.click");
            let leto = row.target.getAttribute("data-leto");
            let sifraZaposleni = row.target.getAttribute("data-sifrazaposleni");
            let zaporednaStevilka = row.target.getAttribute("data-zaporednastevilka");
            let title = row.target.getAttribute("data-formtitle");

            _this.showRejectForm({ leto: leto, sifraZaposleni: sifraZaposleni, zaporednaStevilka: zaporednaStevilka }, title);
        });

        $(".btnPartiallyApproveKEPlanDopustov").off("click");
        $(".btnPartiallyApproveKEPlanDopustov").on('click', function (row) {
            global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.btnPartiallyApproveKEPlanDopustov.click");
            let leto = row.target.getAttribute("data-leto");
            let sifraZaposleni = row.target.getAttribute("data-sifrazaposleni");
            let zaporednaStevilka = row.target.getAttribute("data-zaporednastevilka");
            let title = row.target.getAttribute("data-formtitle");

            _this.showPartiallyApproveAbsence({ leto: leto, sifraZaposleni: sifraZaposleni, zaporednaStevilka: zaporednaStevilka }, title);
        });

        $(".btnApproveKEPlanDopustov").off("click");
        $(".btnApproveKEPlanDopustov").on('click', function (row) {
            global.ConsoleLogDegug("SaopAbsenceConfirmationProcessedView.btnApproveKEPlanDopustov.click");
            let leto = row.target.getAttribute("data-leto");
            let sifraZaposleni = row.target.getAttribute("data-sifrazaposleni");
            let zaporednaStevilka = row.target.getAttribute("data-zaporednastevilka");
            let sifraVrsteDogodka = row.target.getAttribute("data-sifravrstedogodka");
            _this.showApproveAlert({ leto: leto, sifraZaposleni: sifraZaposleni, zaporednaStevilka: zaporednaStevilka, sifraVrsteDogodka: sifraVrsteDogodka }); 
        });

        $("body").off("click", "#absenceConfirmationPendingTable td:not(.td-action)");
        $("body").on("click", "#absenceConfirmationPendingTable td:not(.td-action)", function () {
            var dataColumn = $(this).closest("tr").find("td").eq(0);
            let dataValues = _this.getTableDataColumnValues(dataColumn);
            _this.saopAbsenceConfirmationDetailsView.showForm({ leto: dataValues.leto, sifraZaposleni: dataValues.sifraZaposleni, zaporednaStevilka: dataValues.zaporednaStevilka }, dataValues.title); 
        });
    }

    getTableDataColumnValues(dataColumn: JQuery<HTMLTableCellElement>): DataColumnAttributes {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.getTableDataColumnValues");

        let dataValues = new DataColumnAttributes();

        dataValues.leto = parseInt(dataColumn.attr("data-leto"));
        dataValues.sifraZaposleni = dataColumn.attr("data-sifrazaposleni");
        dataValues.zaporednaStevilka = parseInt(dataColumn.attr("data-zaporednastevilka"));
        dataValues.title = dataColumn.attr("data-formtitle");

        return dataValues;
    }

    showRejectForm(data: any, title: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.showRejectForm");

        this.saopAbsenceConfirmationRejectView.title = title;
        this.saopAbsenceConfirmationRejectView.editRecord(data);
    }

    showPartiallyApproveAbsence(data: any, title: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.showPartiallyApproveAbsence");

        this.saopAbsenceConfirmationPartiallyApproveView.title = title;
        this.saopAbsenceConfirmationPartiallyApproveView.editRecord(data);
    }

    approveAbsence(data: any): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.approveAbsence");
        global.ConsoleLogDegug(data);
        let _this = this;

        this._saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }
            _this.refreshData();
        };
        this._saopServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/ConfirmApproved"
            , data: data
            , type: "POST"
            , spinnerDivId: "#absenceConfirmationPendingLoading"
        });        
    }

    showApproveAlert(data: any): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.showApproveAlert");
        let _this = this;

        this._saopServicesCore.afterDone = function (response) {
            if (response.success == false) {
                let title = _this._translationService.translate("Potrdi");;

                let message = "";
                if (data.sifraVrsteDogodka.toLowerCase() == 't') {
                    message = _this._translationService.translate("Pozor, število dni predlaganega dopusta je večje kot število dni, ki ga imate na razpolago. Želite nadaljevati?");
                }
                else if (data.sifraVrsteDogodka.toLowerCase() == 'k') {
                    message = _this._translationService.translate("Pozor, vpisano število ur predlaganega koriščenja je večje od števila ur, ki jih imate na razpolago. Ali želite nadaljevati?");
                }

                _this.saopMessageDlg.onYes = _this.approveAbsence.bind(_this, data);
                _this.saopMessageDlg.onNo = _this.proceedNo.bind(_this);
                _this.saopMessageDlg.Show(message, title, 1, [SaopMsgDlgButton.mbYes, SaopMsgDlgButton.mbNo]);
            }
            else {
                _this.approveAbsence(data);
            }
        };
        this._saopServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/ConfirmApprovedAlert"
            , data: data
            , type: "GET"
            , spinnerDivId: "#absenceConfirmationPendingLoading"
        });  
    }

    proceedNo(): void {
        global.ConsoleLogDegug("approve.proceedNo");
    }


    private refreshData(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.refreshData");

        this.refreshDataExternal();
    }

    // ta metoda se lahko override z drugo metodo (npr. v DashboardAbsenceConfirmationView)
    refreshDataExternal(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.refreshDataExternal");

        this.refreshBaseData();
    }

    refreshBaseData(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.refreshBaseData");

        let _this = this;
        let data = null;

        // preberemo
        let autoShowDetailsValues = this.getAutoShowAbsenceDetailsValues();
        if (autoShowDetailsValues != null) {
            data = { year: autoShowDetailsValues.leto, sequenceNumber: autoShowDetailsValues.zaporednaStevilka, employeeId: autoShowDetailsValues.sifraZaposleni };
        }

        this._saopServicesCore.afterDone = function (response) {
            $("#absenceConfirmationPending").html(response);
            _this.bindGridEvents();
            _this.checkAutoShowAbsenceDetails();
        };
        this._saopServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/GetPendingAbsences"
            , data: data
            , type: "GET"
            , spinnerDivId: "#absenceConfirmationPendingLoading"
        });
    }

    checkAutoShowAbsenceDetails() {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.checkAutoShowAbsenceDetails");

        // preberemo podatke iz hidden input elementa
        let autoShowAbsenceDetailsValues = this.getAutoShowAbsenceDetailsValues();
        global.ConsoleLogDegug(autoShowAbsenceDetailsValues);

        // resetriamo vrednosti, da se ne bi okno za details ponovno pojavilo, ko se izvede refresh podatkov
        this.resetAutoShowAbsenceDetailsValues();

        if (autoShowAbsenceDetailsValues != null && autoShowAbsenceDetailsValues.hasPKValue) {
            if (!autoShowAbsenceDetailsValues.valid) {
                //ni veljavna, moramo prikazati obvestilo na pregledu
                let messages: string[] = [];
                messages.push(autoShowAbsenceDetailsValues.invalidMessage)
                this.saopMessageBanner.show(messages);
            }
            else {
                // poiscemo podatek iz pending absence tabele in ce obstaja, prikazemo details form
                var table, tr, i;
                table = document.getElementById(this._tableId.replace("#", ""));
                tr = table.getElementsByTagName("tr");
                // Loop through all table rows, and hide those who don't match the search query
                for (i = 0; i < tr.length; i++) {
                    let dataColumn = $(tr[i]).find("td").eq(0);
                    if (!isUnDefined(dataColumn)) {
                        let dataValues = this.getTableDataColumnValues(dataColumn);

                        if (this.compareAbsenceDetailsValuesAndTableDataValues(autoShowAbsenceDetailsValues, dataValues)) {
                            this.saopAbsenceConfirmationDetailsView.showForm({ leto: dataValues.leto, sifraZaposleni: dataValues.sifraZaposleni, zaporednaStevilka: dataValues.zaporednaStevilka }, dataValues.title); 
                            return
                        }
                    }
                }
            }
        }
    }

    getAutoShowAbsenceDetailsValues(): AutoShowAbsenceDetailsData {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.getAutoShowAbsenceDetailsValues");

        let dataValues = new AutoShowAbsenceDetailsData();

        let dataElement = $(this._autoShowAbsenceDetailsId);
        if (dataElement != null) {
            dataValues = dataElement.data('details');

            if (dataValues != null && dataValues.invalidMessage == "") {
                // ni nobenih napak, ki bi jih lahko vrnil "Index" action
                // preverimo, če je kaj zapisano v _pendingForm.cshtml
                // vrednost se postavi v "GetPendingAbsences" action
                let dataPendingInvalidMessageElement = $(this._autoShowAbsenceDetailsInvalidMessageId);
                if (dataPendingInvalidMessageElement != null) {
                    let invMessage = dataPendingInvalidMessageElement.data('invalidmessage');
                    if (invMessage != null && invMessage != "") {
                        dataValues.valid = false;
                        dataValues.invalidMessage = invMessage;
                    }
                }
            }
        }

        return dataValues
    }

    resetAutoShowAbsenceDetailsValues() {
        global.ConsoleLogDegug("SaopAbsenceConfirmationPendingView.resetAutoShowAbsenceDetailsValues");

        let dataElement = $(this._autoShowAbsenceDetailsId);
        if (dataElement != null) {
            dataElement.data('details', null);
        }

        let dataPendingInvalidMessageElement = $(this._autoShowAbsenceDetailsInvalidMessageId);
        if (dataPendingInvalidMessageElement != null) {
            dataPendingInvalidMessageElement.data('invalidmessage', null);
        }
    }

    // ce sta enaka, vrne true, sicer false
    compareAbsenceDetailsValuesAndTableDataValues(absenceDetailsValues: AutoShowAbsenceDetailsData, tableDataValues: DataColumnAttributes): boolean {
        if (absenceDetailsValues.leto === tableDataValues.leto && absenceDetailsValues.zaporednaStevilka === tableDataValues.zaporednaStevilka && absenceDetailsValues.sifraZaposleni === tableDataValues.sifraZaposleni) {
            return true;
        }

        return false;
    }


    filterZaposleniTableRows(filterZaposleni: string) {
        // Declare variables
        var table, tr, _rowId, i, txtValue;
        table = document.getElementById("absenceConfirmationPendingTable");
        tr = table.getElementsByTagName("tr");
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            _rowId = null;
            let _trId = $(tr[i]).attr("data-sifra-zaposleni");
            if (!isUnDefined(_trId)) {
                _rowId = _trId.toString()
            }

            if (_rowId) {
                txtValue = _rowId.toUpperCase();
                if (isEmpty(filterZaposleni)) {
                    $(tr[i]).addClass("element-hidden");
                } else if (filterZaposleni.toUpperCase().indexOf(txtValue) > -1) {
                    $(tr[i]).removeClass("element-hidden");
                } else {
                    $(tr[i]).addClass("element-hidden");
                }
            }
        }
    }
}


