﻿import { global } from "../Common/saop-common";
import { SaopServicesCore, SaopServicesCoreResponse } from "../Core/saop-services-core";
import { SaopTranslationService } from '../Core/saop-translate';

export interface SaopPasswordCriterias {
    pwdCheck:boolean;
    pwdLengthCheck:boolean;
    pwdLengthSetting:number;
    pwdComplexityCheck :boolean;
    pwdMinLength :boolean;
    pwdContainsUpperCase :boolean;
    pwdContainsLowerCase :boolean;
    pwdContainsNumbers :boolean;
    pwdContainsSpecialChars :boolean;
    pwdNotContainsUserData :boolean;
    pwdHistoryCheck:boolean;
    pwdNotUsedYet :boolean;
}

export class SaopChangePwdView {
    //
    private _translationService: SaopTranslationService;
    private _changePasswordForm :JQuery<HTMLElement>; 

    constructor(viewId:string,libName:string = "") {
        this._changePasswordForm = $(viewId);
        this._translationService = new SaopTranslationService();
        this.setTerminologyList();
    }

    setTerminologyList(){
        let _terminology: string[] = ['vsaj {0} znakov'];
        this._translationService.setTranslationsList(_terminology);
    }

    initView(): void {
        global.ConsoleLogDegug("SaopChangePwdView.initView");

        this.bindViewEvents();   
        
        this.checkPasswordStrength();
    }

    bindViewEvents(){
        global.ConsoleLogDegug("SaopChangePwdView.bindGridEvents");
        let _this = this;
        //
        let _NewPassword = this._changePasswordForm.find("#NewPassword");
        _NewPassword.off("change");
        _NewPassword.on('change', function (row) {
            _this.checkPasswordStrength();
        });         
    }

    fsetPwdClass(condition:boolean, divId:string):void {
        let okStatusCss = "left saop-cl-green saop-img-check-mark-solid si-s";
        let warringStatusCss = "left saop-cl-red saop-img-close-solid si-s";        
        let _targetDiv = this._changePasswordForm.find(divId);

        _targetDiv.removeAttr( "class" );
        // _targetDiv.removeClass(function(){
        //     return $(this).attr( "class" );
        // });

        if (condition == true) {
            _targetDiv.addClass(okStatusCss);
        } else {
            _targetDiv.addClass(warringStatusCss);
        }
    }

    setPwdComplexityDivVal(saopPasswordCriterias:SaopPasswordCriterias):void{
        this.fsetPwdClass(saopPasswordCriterias.pwdMinLength,"#pwdMinLength");
        this.fsetPwdClass(saopPasswordCriterias.pwdContainsUpperCase,"#pwdContainsUpperCase");
        this.fsetPwdClass(saopPasswordCriterias.pwdContainsLowerCase,"#pwdContainsLowerCase");
        this.fsetPwdClass(saopPasswordCriterias.pwdContainsNumbers,"#pwdContainsNumbers");
        this.fsetPwdClass(saopPasswordCriterias.pwdContainsSpecialChars,"#pwdContainsSpecialChars");
        this.fsetPwdClass(saopPasswordCriterias.pwdNotContainsUserData,"#pwdNotContainsUserData");
        this.fsetPwdClass(saopPasswordCriterias.pwdNotUsedYet,"#pwdNotUsedYet");
        //

        let _pwdCheckDiv = this._changePasswordForm.find("#pwdCheck");
        if (_pwdCheckDiv.length > 0) {
            if (saopPasswordCriterias.pwdCheck == false){
                _pwdCheckDiv.remove();
            }
        }

        let _pwdHistoryCheckDiv = this._changePasswordForm.find("#pwdHistoryCheck");
        if (_pwdHistoryCheckDiv.length > 0) {
            if (saopPasswordCriterias.pwdHistoryCheck == false){
                _pwdHistoryCheckDiv.remove();
            }
        }

        let _pwdComplexityDiv = this._changePasswordForm.find("#pwdComplexity");
        if (_pwdComplexityDiv.length > 0) {
            if (saopPasswordCriterias.pwdComplexityCheck == false){
                _pwdComplexityDiv.remove();
            }
        }

        let _pwdLengthCheckDiv = this._changePasswordForm.find("#pwdLengthCheck");
        if (_pwdLengthCheckDiv.length > 0) {
            let _pwdLengthSettingDiv = this._changePasswordForm.find("#pwdLengthSetting");
            if (saopPasswordCriterias.pwdLengthCheck == false){
                _pwdLengthCheckDiv.remove();
            } else {
                let _pwdLengthSettingCaption = this._translationService.translate("vsaj {0} znakov").replace("{0}",saopPasswordCriterias.pwdLengthSetting.toString());
                _pwdLengthSettingDiv.html(_pwdLengthSettingCaption);
            }
        }
        //
    }

    public checkPasswordStrength():void{
        let _this = this;
        let _newPassword = this._changePasswordForm.find("#NewPassword");
        let _password = _newPassword.val();

        let data = {password: _password};

        let _saopServicesCore = new SaopServicesCore(); 
        _saopServicesCore.afterSuccess = function (response:SaopServicesCoreResponse) {
           let _saopPasswordCriterias = response.data as SaopPasswordCriterias;
           _this.setPwdComplexityDivVal(_saopPasswordCriterias);
        };
        _saopServicesCore.ExecuteAction({
            action: "Settings/CheckPasswordStrength"
            , data: data
            , type: "POST"
        }); 
    }
    //
}


