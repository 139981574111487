﻿import { global, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";

export class SaopTranslation {
  public key: string = "#";
  public translation: string = null;
}

export class SaopTranslations {
  public translationsList:[SaopTranslation?] = [];
}


export interface SaopTranslationServiceParams {
  controller:string,
  action:string,
}


export class SaopTranslationService {
  private _params:SaopTranslationServiceParams;
  private _saopServicesCore: SaopServicesCore;
  //
  public _terminology:SaopTranslations;


  constructor(params:SaopTranslationServiceParams = null){
    if(!isUnDefined(params)){
      this._params = params;
    } else {
      this._params = {controller:"Translation",action:"GetTranslations"};
    }
    
    this._saopServicesCore = new SaopServicesCore();
    this._terminology = new SaopTranslations();
  }
  
  setTranslationsList(translations:string[]):any{
    let _this = this;
    translations.forEach(function (value) {
      _this._terminology.translationsList.push({key:value,translation:value});  
    });   
    return this.getTranslations(this._terminology);
  }

  getTranslations(translations:SaopTranslations):any{
    let jsonData = JSON.stringify(translations);
    let _data = {jsonInput:jsonData};
    let _response:any;
    this._saopServicesCore.afterDone = function (response) {
      _response = response;
    };
    this._saopServicesCore.ExecuteAction({
        action: this._params.controller+"/"+this._params.action+""
        , type: "POST"
        , data: _data
        , async: false
    });
    this._terminology.translationsList = _response.translationsList;
    return _response;
  }

  translate(key:string):string{
    let _tr = this._terminology.translationsList.find(e => e.key == key) as any;
    if (isUnDefined(_tr)) { 
      return key;
    } else {
      return _tr.translation;
    }
  }

  //
}






