﻿import { global, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopWebGrid, SaopWebGridProperties, SaopWebGridToolbar, SaopWebGridToolbarButton, SaopWebDataTable } from "../Components/saop-grid";
import { SaopEmployeeLeaveCalendar } from "../AbsenceConfirmation/saop-employeeLeaveCalendar-view";
import { SaopWebGridUnitOrgFilter } from "../Components/saop-grid-filter-UnitOrg";

export class KeEployeeLeaveListGrid {
    private libName: string;
    private _mainFormDiv: JQuery<HTMLElement>;
    private _searchValue: JQuery<HTMLElement>;

    private _selectOrgFilter: SaopWebGridUnitOrgFilter;

    //
    public webGrid1: SaopWebGrid;

    private _saopRegKeZapDopustiView: SaopEmployeeLeaveCalendar;

    constructor(libName: string) {
        this.libName = libName;

        this._saopRegKeZapDopustiView = new SaopEmployeeLeaveCalendar('#keEmployeeLeaveCalendar0', libName + ".saopRegKeZapDopustiView");

        this._selectOrgFilter = new SaopWebGridUnitOrgFilter(libName, "");
        this._selectOrgFilter.filterZaposleniTableRows = this.filterZaposleniTableRows.bind(this);
    }

    initView() {
        this._mainFormDiv = $("#KeEmployeeLeaveListBrowseForm");
        this.initWebGrid1();
        this._searchValue = this._mainFormDiv.find("#SearchValue");
        this.initFilterControls();
    }

    initWebGrid1(){
        let wgId = "#KeEmployeeLeaveList";
        let captionToolbar = $(wgId).attr("caption-toolbar");
        let toolbarItemsTemplateId = $(wgId).attr("toolbar-items-templateId");
        //
        this.webGrid1 = new SaopWebGrid(this.libName+".webGrid1");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar:SaopWebGridToolbar;
        let toolbarButtons:SaopWebGridToolbarButton[] = [];
        let dataTable:SaopWebDataTable;
        //

        let _this = this;
        //
        gridToolbar = { caption: captionToolbar, buttons: toolbarButtons, itemsTemplateId: toolbarItemsTemplateId };

        dataTable = { enabled: true }

        gridProperties = {
            gridId: wgId
            , togglePanel: { enabled: false }
            , toolbar: gridToolbar
            , dataTable: dataTable
            , css: "max-height-KeEmployeeLeaveList"
        };
        //
        this.webGrid1.init(gridProperties);

        $(document).on('click', '.categoryLink', function (e: any) {
            let _link = $("#" + e.target.id).attr("link");
            if (_link == null) {
                return;
            }

            const _data = JSON.parse(_link);
            _this.showEmployeeLeaveCalendar(_data.sifraZaposleni, _data.nazivZaposleni);
        });
    }

    initFilterControls(): void {
        let _this = this;
        this._searchValue.keyup(function () {
            let oTable = _this.webGrid1.dataTable;
            oTable.search($(this).val().toString()).draw() ;
        });

        this._selectOrgFilter.initFilterControls();
    }

    showEmployeeLeaveCalendar(sifraZaposleni: string, nazivZaposleni: string): void {
        global.ConsoleLogDegug("KeEployeeLeaveListGrid.showSaopAbsenceConfirmationCalendarView");

        this._saopRegKeZapDopustiView._sifraZaposleni = sifraZaposleni;
        this._saopRegKeZapDopustiView._nazivZaposleni = nazivZaposleni;
        this._saopRegKeZapDopustiView.setFullTitle();
        this._saopRegKeZapDopustiView.recordDetails({ sifraZaposleni: sifraZaposleni});
        this._saopRegKeZapDopustiView.SetToFullScreen();
    }

    filterZaposleniTableRows(filterZaposleni: string) {
        // Declare variables
        var table, tr, _rowId, i, txtValue;
        table = document.getElementById("KeEmployeeLeaveList");
        tr = table.getElementsByTagName("tr");
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            _rowId = null;
            let _trId = $(tr[i]).attr("data-sifra-zaposleni");
            if (!isUnDefined(_trId)) {
                _rowId = _trId.toString()
            }

            if (_rowId) {
                txtValue = _rowId.toUpperCase();
                if (isEmpty(filterZaposleni)) {
                    $(tr[i]).addClass("element-hidden");
                } else if (filterZaposleni.toUpperCase().indexOf(txtValue) > -1) {
                    $(tr[i]).removeClass("element-hidden");
                } else {
                    $(tr[i]).addClass("element-hidden");
                }
            }
        }
    }
}






