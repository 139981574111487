﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopWfSubmitResult } from "../core/saop-view-form";
import { SaopServicesCore } from "../core/saop-services-core";
import { SaopAbsenceConfirmationPartiallyApproveView } from "./saop-absenceConfirmation-partially-approve-view";
import { SaopAbsenceConfirmationRejectView } from "../AbsenceConfirmation/saop-absenceConfirmation-reject-view";
import { SaopTranslationService } from '../Core/saop-translate';
import { SaopMessageDlg, SaopMsgDlgButton } from '../Components/saop-message-dlg';

export class SaopAbsenceConfirmationDetailsEvents {
    private _viewId: string
    private _libName: string;
    private _rejectViewId: string
    private _rejectBtnId: string;
    private _partiallyApproveViewId: string;
    private _partiallyApproveBtnId: string;
    private _approveBtnId: string;
    private _saopServicesCore: SaopServicesCore;
    private saopAbsenceConfirmationRejectView: SaopAbsenceConfirmationRejectView;
    private saopAbsenceConfirmationPartiallyApproveView: SaopAbsenceConfirmationPartiallyApproveView;
    private saopMessageDlg: SaopMessageDlg;
    private _translationService: SaopTranslationService;

    constructor(viewId: string, libName: string = "") {
        this._viewId = viewId;
        this._libName = libName;
        this._rejectViewId = "#absenceConfirmationRejectView0";
        this._rejectBtnId = "#detailsKePlanDopustovZavrniBtn";
        this._partiallyApproveViewId = "#absenceConfirmationPartiallyApproveView0";
        this._partiallyApproveBtnId = "#detailsKePlanDopustovDelnoPotrdiBtn";
        this._approveBtnId = "#detailsKePlanDopustovPotrdiBtn";
        this._saopServicesCore = new SaopServicesCore();

        this.saopAbsenceConfirmationRejectView = new SaopAbsenceConfirmationRejectView(this._rejectViewId, this._libName + ".saopAbsenceConfirmationRejectView");
        this.saopAbsenceConfirmationRejectView.refreshData = this.refreshData.bind(this);

        this.saopAbsenceConfirmationPartiallyApproveView = new SaopAbsenceConfirmationPartiallyApproveView(this._partiallyApproveViewId, this._libName + ".saopAbsenceConfirmationPartiallyApproveView");
        this.saopAbsenceConfirmationPartiallyApproveView.refreshData = this.refreshData.bind(this);

        this.saopMessageDlg = new SaopMessageDlg({ data: null, parent: null })
        this._translationService = new SaopTranslationService(); 
        this.setTerminologyList();
    }

    setTerminologyList() {
        let dictionary: string[] = ["Potrdi"
            , "Pozor, število dni predlaganega dopusta je večje kot število dni, ki ga imate na razpolago. Želite nadaljevati?"
            , "Pozor, vpisano število ur predlaganega koriščenja je večje od števila ur, ki jih imate na razpolago. Ali želite nadaljevati?"];
        this._translationService.setTranslationsList(dictionary);
    }     

    init(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsEvents.init");
        let _this = this;

        $(this._rejectBtnId).click(function () {
            _this.showRejectView(_this._rejectBtnId);   
        });

        $(this._approveBtnId).click(function () {
            let opozoriloStr = $("#PrekoracitevOdsotnostiOpozorilo").val().toString();
            let opozorilo = opozoriloStr === "" ? false : true;

            if (opozorilo) {
                _this.showApproveAlert(opozoriloStr.toLowerCase());
            }
            else {
                _this.approve(_this._approveBtnId);
            }
        });

        $(this._partiallyApproveBtnId).click(function () {
            _this.showPartiallyApproveView(_this._partiallyApproveBtnId)
        });
    }

    showRejectView(elementId: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsEvents.showRejectView");

        let elementCtrl = $(elementId);
        let leto = elementCtrl.data("leto");
        let sifraZaposleni = elementCtrl.data("sifrazaposleni");
        let zaporednaStevilka = elementCtrl.data("zaporednastevilka");
        let title = elementCtrl.data("formtitle");

        this.saopAbsenceConfirmationRejectView.title = title;
        this.saopAbsenceConfirmationRejectView.editRecord({ leto: leto, sifraZaposleni: sifraZaposleni, zaporednaStevilka: zaporednaStevilka });
    }

    approve(elementId: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsEvents.approve");

        let elementCtrl = $(elementId);
        let leto = elementCtrl.data("leto");
        let sifraZaposleni = elementCtrl.data("sifrazaposleni");
        let zaporednaStevilka = elementCtrl.data("zaporednastevilka");

        let data = { leto: leto, sifraZaposleni: sifraZaposleni, zaporednaStevilka: zaporednaStevilka };
        global.ConsoleLogDegug(data);

        let _this = this;

        this._saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                $("#absenceConfirmationDetailsView0").modal('toggle'); 
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBanner").html(response.viewContent);
            }

            if (response.success) {
                _this.refreshData();
            }
        };
        this._saopServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/ConfirmApproved"
            , data: data
            , type: "POST"
            , spinnerDivId: "#absenceConfirmationPendingLoading"
        });        
    }

    showPartiallyApproveView(elementId: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsEvents.showPartiallyApproveView");

        let elementCtrl = $(elementId);
        let leto = elementCtrl.data("leto");
        let sifraZaposleni = elementCtrl.data("sifrazaposleni");
        let zaporednaStevilka = elementCtrl.data("zaporednastevilka");
        let title = elementCtrl.data("formtitle");

        this.saopAbsenceConfirmationPartiallyApproveView.title = title;
        this.saopAbsenceConfirmationPartiallyApproveView.editRecord({ leto: leto, sifraZaposleni: sifraZaposleni, zaporednaStevilka: zaporednaStevilka });
    }

    showApproveAlert(sifraVrsteDogodka: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsEvents.showApproveAlert");
        let _this = this;

        let title = this._translationService.translate("Potrdi");

        let message = "";
        if (sifraVrsteDogodka == 't') {
            message = this._translationService.translate("Pozor, število dni predlaganega dopusta je večje kot število dni, ki ga imate na razpolago. Želite nadaljevati?"); 
        }
        else if (sifraVrsteDogodka == 'k') {
            message = this._translationService.translate("Pozor, vpisano število ur predlaganega koriščenja je večje od števila ur, ki jih imate na razpolago. Ali želite nadaljevati?"); 
        }

        _this.saopMessageDlg.onYes = _this.proceedYes.bind(_this);
        _this.saopMessageDlg.onNo = _this.proceedNo.bind(_this);
        _this.saopMessageDlg.Show(message, title, 1, [SaopMsgDlgButton.mbYes, SaopMsgDlgButton.mbNo]);
    }

    proceedYes(): void {
        global.ConsoleLogDegug("approve.proceedYes");
        let _this = this;
        $(_this._approveBtnId).attr('type', 'submit');
        $(_this._approveBtnId).attr('data-modal', 'dismiss');

        $("#PrekoracitevOdsotnostiOpozorilo").val('')

        $(_this._approveBtnId).trigger("click");
    }

    proceedNo(): void {
        global.ConsoleLogDegug("approve.proceedNo");
    }

    private refreshData(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsEvents.refreshData");
        this.refreshFormDataExtended();
    }

    refreshFormDataExtended(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsEvents.refreshFormDataExtended");
    }
}


