﻿import { global } from "../Common/saop-common";
import { SaopRightSidebar,SaopRightSidebarView } from '../Components/saop-right-sidebar';
import { ObdobjefilterValues } from "../Components/saop-select-month-year";
import { SaopRegTodayEventsView } from "./saop-regTodayEvents-view"
import { SaopRegView } from "./saop-regUra-view";

export class SaopRegAttendanceView {
    public regTodayEventsView:SaopRegTodayEventsView;
    public regUraView:SaopRegView;
    public rightSidebar: SaopRightSidebar;
    public rightSidebarK: SaopRightSidebar;
    public rightMonthSidebar: SaopRightSidebar;
    public rightMonthSidebarK: SaopRightSidebar;
    //

    constructor(libName: string = "") {
        this.regTodayEventsView = new SaopRegTodayEventsView("#regDogodekView0",libName+".regTodayEventsView");
        this.regUraView = new SaopRegView(libName+".regUraView");
        this.rightSidebar = new SaopRightSidebar();
        this.rightSidebarK = new  SaopRightSidebar();
        this.rightMonthSidebar = new SaopRightSidebar();
        this.rightMonthSidebarK = new SaopRightSidebar();
    }

    onInitView():void{
        global.ConsoleLogDegug("onInitView");
        this.regTodayEventsView.initView();
        this.regUraView.initView();
        //
        let obdobjefilterValues: ObdobjefilterValues = new ObdobjefilterValues();

        this.rightSidebar.bindCarouselDiv("#right-sidebar-day", SaopRightSidebarView.AttendanceDay);
        this.rightMonthSidebar.bindCarouselDiv("#right-sidebar-month", SaopRightSidebarView.AttendanceMonth);

        this.refreshRightSidebarData(false);
        this.regTodayEventsView.afterRefreshData = this.afterRefreshMonthData.bind(this);
        this.regUraView.afterRefreshData = this.afterRefreshTodayData.bind(this);
        this.regUraView.onCloseView = this.onCloseRegUraDanView.bind(this);
    }

    afterRefreshTodayData(): void {
        global.ConsoleLogDegug("afterRefreshTodayData");
        // refresh "Evidenca prisotnosti - danasnji dan"
        this.regTodayEventsView.refreshListData(false);
        //refresh right sidebar
        this.refreshRightSidebarData(true);
    }

    afterRefreshMonthData(): void {
        global.ConsoleLogDegug("afterRefreshMonthData");
        this.regUraView.refreshRegUraBrowseView(false);
        // refresh "Evidenca prisotnosti - mesecna evidenca"
        this.refreshRightSidebarData(true);
    }    

    refreshRightSidebarData(monthRefresh: boolean): void {
        let obdobjefilterValues: ObdobjefilterValues = new ObdobjefilterValues();

        //refresh right sidebar
        this.rightSidebar.refreshRegUraDogodkiSummary("#regUraDogodkiUreSummary", obdobjefilterValues, "#saopLoadingSidebarr");
        this.rightSidebarK.refreshUreZaKoriscenjeSummary("#regUraDogodkiKoriscenjeSummary", "#saopLoadingSidebarr");
        //
        if (monthRefresh) {
            obdobjefilterValues = this.regUraView._saopSelectMonthYear.getSelectedMonthYear();
        }
        this.rightMonthSidebar.refreshRegUraDogodkiSummary("#regUraMonthEventsUreSummary", obdobjefilterValues, "#saopLoadingRegUraMonthEventsSidebarr");
        this.rightMonthSidebarK.refreshUreZaKoriscenjeSummary("#regUraMonthEventsKoriscenjeSummary", "#saopLoadingRegUraMonthEventsSidebarr");
        //    
    }

    refreshAllData(): void {
        global.ConsoleLogDegug("refreshAllData");
        // refresh "Evidenca prisotnosti - danasnji dan"
        this.regTodayEventsView.refreshListData(false);
        //
        this.regUraView.refreshRegUraBrowseView(false);
        //refresh right sidebar
        this.refreshRightSidebarData(true);
    }    

    onCloseRegUraDanView():void{
        global.ConsoleLogDegug("SaopRegAttendanceView.onCloseRegUraDanView");    
        this.refreshAllData();
    }

    //
}


