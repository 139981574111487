﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global,isUnDefined, formatDate, setModalFormZIndex, isEmpty } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopWebGrid, SaopWebGridProperties, SaopWebGridToolbar, SaopWebGridToolbarButton, SaopWebDataTable } from "../Components/saop-grid";

export class SaopRegDetailsTableView {
    private _libName: string;
    private _tableDivId: string;
    private webGrid: SaopWebGrid;

    constructor(libName: string = "", tableDivId: string) {
        this._libName = libName;
        this._tableDivId = tableDivId;
    } 

    bindGridEvents(): void {
        global.ConsoleLogDegug("SaopRegDetailsTableView.bindGridEvents");
        let _this = this;
        
        $("body").off("click", this._tableDivId + " td.td-action-details");
        $("body").on("click", this._tableDivId + " td.td-action-details", function () {
            let selectedRow = $(this).closest("tr");
            let selectedDay = selectedRow.find("td").eq(2).text()
            let actionDetailsColumn = selectedRow.find("td").eq(1);
            let datumKey = selectedRow.attr("data-datum");
            let sifraZaposleniKey = selectedRow.attr("data-sifrazaposleni");
            _this.showHideDetailsSubTable(selectedRow, actionDetailsColumn, selectedDay, datumKey, sifraZaposleniKey);
        });
    }

    private showHideDetailsSubTable(selectedRow: JQuery<HTMLTableRowElement>, actionDetailsColumn: JQuery<HTMLTableCellElement>, selectedDay: string, datumKey: string, sifraZaposleniKey: string): void {
        global.ConsoleLogDegug("SaopRegDetailsTableView.showHideDetailsSubTable");

        let iconElement = actionDetailsColumn.find("span");

        let rowDetailsId = "#detailRow" + datumKey;
        let rowDetailsIdElement = $(rowDetailsId);

        if (!this.isDetailsSubTableShowed(iconElement)) {
            // show details subtable
             //preverim, če tabela ze obstaja
            let detailRowDivId = "#detailRowDiv" + datumKey;
            let detailRowTableElement = $(detailRowDivId + " table");
            if (detailRowTableElement.length == 0) {
                // tabele ni, gremo na server po podatke za prikaz
                this.getDetailsSubTable(detailRowDivId, selectedDay, sifraZaposleniKey);
            }
        }

        // prikazi/skrij bottom border
        selectedRow.toggleClass("saop-table-row-border-bottom-none")

        // prikazi/skrij details tabelo
        rowDetailsIdElement.toggleClass("saop-table-row-hidden");
        rowDetailsIdElement.toggleClass("saop-table-row-showed");

        // sprememba ikone
        iconElement.toggleClass("saop-img-right");
        iconElement.toggleClass("saop-img-down");
    }

    private isDetailsSubTableShowed(iconElement: JQuery<HTMLSpanElement>): boolean {
        global.ConsoleLogDegug("SaopRegDetailsTableView.isDetailsSubTableShowed");

        return iconElement.hasClass("saop-img-down");
    }

    private getDetailsSubTable(detailRowDivId: string, selectedDay: string, sifraZaposleni: string): void {
        global.ConsoleLogDegug("SaopRegDetailsTableView.getDetailsSubTable");

        let _this = this;
        let saopServicesCore = new SaopServicesCore();
        let params = { SelectedDay: selectedDay, SifraZaposleni: sifraZaposleni };

        saopServicesCore.afterDone = function (response) {
            $(detailRowDivId).html(response);
            _this.initWebGrid();
        };
        saopServicesCore.ExecuteAction({
            action: "REGDayEvents/GetRegUraBrowseEventsTable"
            , data: params
            , type: "GET"
            //, spinnerDivId: "#attendanceAllEmployeesLoading"
        });
    }

    private initWebGrid() {
        //
        this.webGrid = new SaopWebGrid(this._libName);
        let gridProperties: SaopWebGridProperties;

        gridProperties = {
            gridId: "#saopWebGridDetails"
            , css: "table-column-holder-last"
            , toolbar: null
        };
        //
        this.webGrid.init(gridProperties);
    }
    //
}


