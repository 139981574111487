﻿/// <reference path="../@types/jHTree/index.d.ts" />

import { global } from "../Common/saop-common";
import { SaopMultiselect } from "../Components/saop-multiselect";
import { SaopServicesCore } from "../Core/saop-services-core";

class apiResponse {
    success: boolean;
    data: any;
    alertMessage: string;
}

export class SaopOrganigramView {
    private _libNameP: string;
    private _selectEnoteOrgStrukture: SaopMultiselect;
    private _enoteOrgStruktureDivId: string;
    private _selectZaposleni: SaopMultiselect;
    private _zaposleniDivId: string;
    private _treeChartDivId: string;

    constructor(viewId: string, libName: string = "") {
        this._libNameP = libName;
        this._enoteOrgStruktureDivId = "#EnoteOrgStruktureDiv";
        this._zaposleniDivId = "#ZaposleniDiv";
        this._treeChartDivId = "#treeChart";
    }

    initView(): void {
        global.ConsoleLogDegug("SaopOrganigramView.initView");

        this.initSelectEnoteOrgStrukture();

        this.initSelectZaposleni();
    }

    initSelectEnoteOrgStrukture(): void {
        global.ConsoleLogDegug("SaopOrganigramView.initSelectEnoteOrgStrukture");

        this._selectEnoteOrgStrukture = new SaopMultiselect();
        this._selectEnoteOrgStrukture.afterChangeCommonEvent = this.filterSelectEnoteOrgStrukture.bind(this);
        // init za metodo "afterChangeCommonEvent", da se že v "_selectEnoteOrgStrukture.init" metodi sprozi filterSelectEnoteOrgStrukture
        this._selectEnoteOrgStrukture.init("#EnoteOrgStruktureFilter", "Enota za prikaz");
        // prikazemo select div
        $(this._enoteOrgStruktureDivId).show();
    }

    initSelectZaposleni(): void {
        global.ConsoleLogDegug("SaopOrganigramView.initSelectZaposleni");

        this._selectZaposleni = new SaopMultiselect();
        this._selectZaposleni.init("#ZaposleniFilter", "Zaposleni");
        // init pred metodo "afterChangeCommonEvent", da se ne izvede metoda filterSelectZaposleni
        this._selectZaposleni.afterChangeCommonEvent = this.filterSelectZaposleni.bind(this);
        // prikazemo select div
        $(this._zaposleniDivId).show();
    }

    filterSelectEnoteOrgStrukture(element: any) {
        global.ConsoleLogDegug("SaopOrganigramView.filterSelectEnoteOrgStrukture");

        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraneEnoteOrgStrukture = <string>(element.val().toString());

        this.refreshData(izbraneEnoteOrgStrukture);
    }

    filterSelectZaposleni(element: any) {
        global.ConsoleLogDegug("SaopOrganigramView.filterSelectZaposleni");

        // odstranimo css klas za obrobo vsem elementom
        $(".tree li .trcont").removeClass("selected-employee");
        $(".tree li .tree-chart-header").removeClass("selected-employee-header");

        // preberemo, katere sifre zaposlenih je izbrana
        let izbranZaposleni = <string>(element.val().toString());
        if (izbranZaposleni != null && izbranZaposleni != "") {
            // oznacimo zaposlenega
            let zaposleniMainElement = $("#" + izbranZaposleni + " .trcont").first();
            let zaposleniHeaderElement = $("#" + izbranZaposleni + " .tree-chart-header").first();

            // dodamo css klas za obrobo
            if (zaposleniMainElement.length) {
                zaposleniMainElement.addClass("selected-employee");
            }
            // popravimo header style
            if (zaposleniHeaderElement.length) {
                zaposleniHeaderElement.addClass("selected-employee-header");
            }

            // preverimo, ce je potreben scroll
            let izvenHorizontal = this.checkHorizontalPositionOffset(zaposleniMainElement);
            let izvenVertical = this.checkVerticalPositionOffset(zaposleniMainElement);

            if (izvenHorizontal) {
                let length = 500
                for (var i = 0; i < length; i++) {
                    $(this._treeChartDivId).scrollLeft(i * 100);
                    if (!this.checkHorizontalPositionOffset(zaposleniMainElement)) {
                        break;
                    }
                }
                
            }
            if (izvenVertical) {
                let length = 500
                for (var i = 0; i < length; i++) {
                    $(this._treeChartDivId).scrollTop(i * 100);
                    if (!this.checkVerticalPositionOffset(zaposleniMainElement)) {
                        break;
                    }
                }
            }
        } 
    }

    checkHorizontalPositionOffset(element: JQuery<HTMLElement>): boolean {
        let treeChartOffset = $("#treeChart").offset();
        let w = Math.max(document.documentElement.clientWidth - treeChartOffset.left, window.innerWidth - treeChartOffset.left || 0)

        let leftPos = element.offset().left - treeChartOffset.left;
        if (leftPos < 0 || leftPos > w) {
            return true;
        }
        // left pristejemo se width in ponovno preverimo, ce je izved
        leftPos = leftPos + element.width();
        if (leftPos < 0 || leftPos > w) {
            return true;
        }

        return false;
    }

    checkVerticalPositionOffset(element: JQuery<HTMLElement>): boolean {
        let treeChartOffset = $("#treeChart").offset();
        let pageFooter = $("#site-footer");
        let h = Math.max(document.documentElement.clientHeight - treeChartOffset.top - pageFooter.height(), window.innerHeight - treeChartOffset.top - pageFooter.height() || 0)
        let d = $(document).scrollTop();

        let topPos = element.offset().top - treeChartOffset.top;
        //vertical
        if (topPos < 0 || topPos > h - d) {
            return true;
        }
        // top pristejemo se height in ponovno preverimo, ce je izved
        topPos = topPos + element.height();
        if (topPos < 0 || topPos > h - d) {
            return true;
        }

        return false;
    }

    refreshData(izbraneEnoteOrgStrukture: string): void {
        global.ConsoleLogDegug("SaopOrganigramView.refreshData");

        let _this = this;
        let params = { izbraneEnote: izbraneEnoteOrgStrukture };
        let saopServicesCore = new SaopServicesCore();

        saopServicesCore.afterDone = function (response) {
            let apiResult: apiResponse = response;

            if (apiResult.success) {
                // pobrisemo div
                $("#treeChart").html("");

                $("#treeChart").jHTree({
                    callType: 'obj',
                    structureObj: apiResult.data.items,
                    zoomer: false
                });

                $(_this._treeChartDivId).scrollLeft(0);

                // osvezimo se zaposlene
                _this.refreshSelectZaposleni(<string[]>apiResult.data.sifraZaposleniList);
            }

        };
        saopServicesCore.ExecuteAction({
            action: "Organization/GetOrganizationChartData"
            , data: params
            , type: "GET"
            , spinnerDivId: "#organigramLoading"
        });    
    }

    refreshSelectZaposleni(sifraZaposleniList: string[]): void {
        global.ConsoleLogDegug("SaopOrganigramView.refreshSelectZaposleni");

        let _this = this;
        let params = { sifreZaposleni: sifraZaposleniList.toString() };
        let saopServicesCore = new SaopServicesCore();

        saopServicesCore.afterDone = function (response) {
            $(_this._zaposleniDivId).html(response);

            _this.initSelectZaposleni();
        };
        saopServicesCore.ExecuteAction({
            action: "Organization/GetEmpoyeesFilter"
            , data: params
            , type: "GET"
            , spinnerDivId: "#organigramLoading"
        });    
    }
    //
}


