import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopViewForm, SaopWfAction } from "../Core/saop-view-form";
import { SaopUploadFiles } from "../Components/saop-upload-files";
import { SaopAttachmentList } from "../Components/saop-attachment-list";
import { SaopServicesCore } from "../Core/saop-services-core";
import { LIKVCakalnaVrstaComment } from "../LIKV/LIKVCakalnaVrsta-Comment";
import { LIKVNivojiPotrjevanjaModal } from "../LIKV/LIKVNivojiPotrjevanja-modal";


export class LIKVCakalnaVrstaView extends SaopViewForm {
  private _formId:string;
  private _viewId: string
  private _saopUploadFiles:SaopUploadFiles;
  private _saopAttachmentList: SaopAttachmentList;
  private _likvNivojiPotrjevanjaModal :LIKVNivojiPotrjevanjaModal;

  constructor(viewId:string,libName:string = ""){
    super(viewId,libName);
    this._viewId = viewId;
    this.title = this.translationService.translate("Potni nalog");
    this.addRecordEndpoint = "";
    this.editRecordEndpoint = "LikvConfirmations/Edit";
    this.deleteRecordEndpoint = ""; 
    this._saopUploadFiles = new SaopUploadFiles(viewId,libName);
    this._likvNivojiPotrjevanjaModal = new LIKVNivojiPotrjevanjaModal("#LIKVCakalnaVrstaView004", libName);
    this._likvNivojiPotrjevanjaModal.fullScreen = true;  
    //
    this.afterViewShowSuccess = this.initView;
    this.afterSubmitSuccess = this.initView;
    //this.viewShownComplete = this.shownComplete;
    this._saopAttachmentList = new SaopAttachmentList("#LikvCakalnaVrstaAttachments", libName + "._saopAttachmentList");
    this._saopAttachmentList.initComponent(
      {
          validationFingerprintController: "SPLDocument",
          validationFingerprintAction: "ValidateAttachmentFingerPrint",
          validationFingerprintBannerDivId: viewId + " #uploadFilesValidationsAjax",
          validationController: "SPLDocument",
          validationAction: "ValidateDownloadingDocumentAttachment",
          validationBannerDivId: viewId + " #uploadFilesValidationsAjax",
          downloadController: "SPLDocument",
          downloadAction: "DownloadDocumentAttachment",
          deletedAttachmentListId: viewId + " " + "#PriponkeBrisane"
      });

  }

  // shownComplete():void{
  //   console.log("shownComplete...");
  //   eval("CommonPointer['saopPdfPreview'].setDefaultScale(0.6);");
  // }

  setDictionary(){
    let dictionary:string[] = ["Potrjevanje"];
    this.setTerminologyList(dictionary);
  } 


  public initView():void{
    this.setDictionary();  
    this.LoadViewEvents();
    this.setTitleCss();
    this.enableMobileKomentar();
    this.setAttachmentCss();
    $(this._viewId + " #likvConfirmationsEditMainDiv").show();
  }

  public LoadViewEvents ():void{
    let _viewDiv = $(this.id);
    if (_viewDiv.length == 0) {return;}

    let _this = this;
    this._formId = "#mainLikvCakalnaVrstaEdit";
    //this._saopUploadFiles.initComponent({controller:"TravelOrder",action:"ValidateFiles",validateAjax:true,formId:this._formId,validationDivId:"#uploadFilesValidationsAjax"});
    this._saopUploadFiles.afterRefreshFilesList = this._saopUploadFiles.ajaxValidate;

    $('#btnOk').off('click');
    $('#btnOk').bind('click', 
      function() {
        let _result = _this._saopUploadFiles.ajaxValidate();
        return _result;        
      }          
    );    

    // let btnClose = $('#LikvConfirmationsEditView').find("#btnClose");
    // btnClose.off("click",_this.onCloseView);
    // btnClose.on("click",_this.onCloseView);   

    $('#LIKVCakalnaVrstaView001').on('hidden.bs.modal', function () {
      _this.onCloseView();
    });    

    //$('.saop-attachment-element').bind('click', this.RefreshAttachment  );     
    $(".saop-attachment-element").on("click", function (e:any) {
      var data = e.currentTarget;
      let _id = data.getAttribute("data-docId");
      let preview = <string>data.getAttribute("data-docpreview");
      let docpreview = preview === "true" ? true : false;
      _this.refreshAttachment(_id, docpreview);
    }); 

    $('#btnNext').off('click');
    $("#btnNext").on("click", function (e:any) {
        _this.MoveToNext();
    });  

    $('#btnPrevious').off('click');
    $("#btnPrevious").on("click", function (e:any) {
        _this.MoveToPrevious();
    }); 

    $('#btnDeny').off('click');
    $("#btnDeny").on("click", function (e:any) {

      let _idVrsteElement = $('#LikvConfirmationsEditView').find("#IDVrste");;
      let _idVrste = _idVrsteElement.val().toString();

      let _idElement = $('#LikvConfirmationsEditView').find("#ID");;
      let _id = _idElement.val().toString();

      let komentarElement = $('#LikvConfirmationsEditView').find("#desktopKomentar");
      let komentar = komentarElement.val();
      if (!komentarElement.is(":visible")) {
        komentar = $('#LikvConfirmationsEditView').find("#mobileKomentar").val();
      }

      let _idAkcije = -1;

        _this.DenyAndMoveToNext(_idVrste, _id, komentar.toString(), _idAkcije.toString());
    }); 

    $('#btnConfirm').off('click');
    $("#btnConfirm").on("click", function (e:any) {

      let _idVrsteElement = $('#LikvConfirmationsEditView').find("#IDVrste");;
      let _idVrste = _idVrsteElement.val().toString();

      let _idElement = $('#LikvConfirmationsEditView').find("#ID");;
      let _id = _idElement.val().toString();

      let komentarElement = $('#LikvConfirmationsEditView').find("#desktopKomentar");
      let komentar = komentarElement.val();
      if (!komentarElement.is(":visible")) {
        komentar = $('#LikvConfirmationsEditView').find("#mobileKomentar").val();
      }

      let _idAkcije = -1;

        _this.ConfirmAndMoveToNext(_idVrste, _id, komentar.toString(), _idAkcije.toString());
    });     

    $('#btnUncDocuments').off('click');
    $("#btnUncDocuments").on("click", function (e:any) {
      let _idVrsteElement = $('#LikvConfirmationsEditView').find("#IDVrste");;
      let _idVrste = _idVrsteElement.val().toString();
      let _params = { idVrste: _idVrste };
      //
      _this._likvNivojiPotrjevanjaModal.showForm(_params);     
    });     

    $('#btnUncDocumentsMB').off('click');
    $("#btnUncDocumentsMB").on("click", function (e:any) {
      let _idVrsteElement = $('#LikvConfirmationsEditView').find("#IDVrste");;
      let _idVrste = _idVrsteElement.val().toString();
      let _params = { idVrste: _idVrste };
      //
      _this._likvNivojiPotrjevanjaModal.showForm(_params);     
    });     

  }

  private refreshHtmlAttachment(htmlElement:string){
    $("#likvConfirmationsAttachment").html(htmlElement);
  }

  public refreshAttachment (docID:string, preview: boolean ):void {
    let _this = this;
    let _params = { documentID: docID, documentPreview: preview };
    let _saopServicesCore =  new SaopServicesCore();

    _saopServicesCore.afterDone = function(response){
      _this.refreshHtmlAttachment(response);
    };
    _saopServicesCore.ExecuteAction({
        action:"LikvConfirmations/RefreshAttachment" 
        ,data: _params
        ,type:"GET"
    });    
  }

  public MoveToNext ():void{

  }

  public MoveToPrevious ():void{

  }

  public ConfirmAndMoveToNext(idVrste:string, id:string, komentar: string, idAkcije: string):void{

  }

  public DenyAndMoveToNext(idVrste:string, id:string, komentar: string, idAkcije: string):void{

  }

  private setTitleCss(): void {
    // samo ce je mobile, se zmanjsa velikost naslova edit forme
    if (this.isMobileView()) {
      let titleElement = $(this._viewId + " .modal-title");
      titleElement.removeClass("saop-txt-1");
      titleElement.addClass("saop-txt-3");
      titleElement.addClass("align-self-center");
    }
  }

  private isMobileView(): boolean {
    let isMobile = $(this._viewId + " #mobileButtons").css("display") != "none";

    return isMobile;
  }

  private enableMobileKomentar(): void {
    // po defaultu je mobile komentar text area kontrola disabled, da se form ne pomakne navzdol na text area kontrola
    // in ostane na vrhu, zato ga moramo cez nekaj casa enabled
    let _this = this;

    setTimeout(function () {
        $(_this._viewId + " #mobileKomentar").prop("disabled", false);
    }, 1000);
  }

  private setAttachmentCss(): void {
    if (this.isMobileView()) {
      let likvConfirmationsAttachmentElement = $(this._viewId + " #likvConfirmationsAttachment");
      likvConfirmationsAttachmentElement.addClass("mobile-likvConfirmationsAttachment");
  
      let likvConfirmationsAttachmentRowElement = $(this._viewId + " #likvConfirmationsAttachmentRow");
      let attachmentRowCss = "mobile-likvConfirmationsAttachmentRow";
      if (likvConfirmationsAttachmentElement.length == 0) {
          attachmentRowCss = "mobile-likvConfirmationsNoAttachmentRow";
      }
      likvConfirmationsAttachmentRowElement.addClass(attachmentRowCss);

      let likvConfirmationsNoAttachment = $(this._viewId + " #likvConfirmationsNoAttachment");
      likvConfirmationsNoAttachment.addClass("mobile-likvConfirmationsNoAttachment");

      let _modalHeader = $(".modal-header");
      _modalHeader.css("height","20px");
      _modalHeader.css("padding","20px 20px 20px 20px");       
      _modalHeader.find(".modal-title").css("font-size","14px");
      _modalHeader.find(".modal-title").css("line-height","20px");
      _modalHeader.find(".close").css("padding","unset");
      //
      let _saopHorizontalScrollable = $(".saop-horizontal-scrollable");
      _saopHorizontalScrollable.find(".saop-scroll-col").css("padding-top","7px");
      //
      //$(this._viewId + " .saop-control-listBox-wider ").css("height","45px");
      $(this._viewId + " #EditInfoDoc .saop-modal-row ").css("padding-top","5px");
      $(this._viewId + " .modal-footer-buttons ").css("padding","5px 5px 5px 5px"); 
      $(this._viewId + " .saop-edit-form-panel-heading ").css("padding-top","7px"); 
      // 
      $(this._viewId + " #attachmentsMainDiv").css("padding-left","0px");

    }
  }

  public onCloseView():void {
    global.ConsoleLogDegug("LIKVCakalnaVrstaView.onCloseView");
    $(".pdf-view-container").remove();
  }

}





