﻿import { global } from "../Common/saop-common";


export class SaopViewSpinner {

  generateSpinnerDiv(spinnerId: string = '#saopLoading'): string {
    global.ConsoleLogDegug("generateSpinnerDiv " + spinnerId);
    let spinnrDiv =
      '<div id="' + spinnerId + '" style="display: none;" class=""> '
      + '	<div id="' + spinnerId + 'SpinnerBackgroung" class="spinner-background "/> '
      + '	<div id="' + spinnerId + 'Spinner" class="spinner spinner-center-dashboardSidebarr"> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '		<div class="spinner-blade"/> '
      + '	</div> '
      + '</div> ';

    return spinnrDiv;
  }

  showLoadingSaop(elementId: string = "#saopLoading") {
    global.ConsoleLogDegug("showLoadingSaop " + elementId);
    var obj = $(elementId);
    if (obj.length) {
      global.ConsoleLogDegug("Show... ");
      obj.show();
      //setModalFormZIndex(elementId);
    }
  }

  hideLoadingSaop(elementId: string = "#saopLoading") {
    global.ConsoleLogDegug("hideLoadingSaop " + elementId);
    var obj = $(elementId);
    if (obj.length)
      obj.hide();
  }

  // showLoadingSaop(elementId: string = "") {
  //   global.ConsoleLogDegug("showLoadingSaop " + elementId);
  //   if (!isEmpty(elementId)) {
  //     let elementDiv = $(elementId);
  //     if (elementDiv.length) {
  //       let spinnerID = elementId + "Loading";
  //       spinnerID = spinnerID.replace("#","");
  //       let spinnerDiv = $(spinnerID);
  //       if (!spinnerDiv.length) {
  //         let _div = this.generateSpinnerDiv(spinnerID);
  //         elementDiv.append(_div);
  //         spinnerDiv = $(spinnerID);
  //       }
  //       spinnerDiv.show();
  //     }
  //   }
  // }

  // hideLoadingSaop(elementId: string = "") {
  //   global.ConsoleLogDegug("hideLoadingSaop " + elementId);
  //   if (!isEmpty(elementId)) {
  //     let elementDiv = $(elementId);
  //     if (elementDiv.length) {
  //       let spinnerID = elementId + "Loading";
  //       let spinnerDiv = $(spinnerID);
  //       if (!spinnerDiv.length) {
  //         elementDiv.append(this.generateSpinnerDiv(spinnerID));
  //         spinnerDiv = $(spinnerID);
  //       }
  //       spinnerDiv.hide();
  //     }
  //   }
  // }
  // //
}
