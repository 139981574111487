﻿import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopViewForm } from "../Core/saop-view-form";
import { SaopMultiselect } from "../Components/saop-multiselect";
import { SaopServicesCore } from "../Core/saop-services-core";

export class OPNSelectPassengersView extends SaopViewForm {
  private _selectZaposleni: SaopMultiselect;

  constructor(viewId:string,libName:string = ""){
    global.ConsoleLogDegug("OPNPotniNalogiGoggleMapsView");
    super(viewId,libName);
    this.setDictionary();   
    this.addRecordEndpoint = "";
    this.editRecordEndpoint = "TravelOrder/GetSelectPassengersView";
    this.deleteRecordEndpoint = "";   
    this.fullScreen = true;
    this.fullTitle = this.translationService.translate("Nov vnos: Sopotnik");
    this.fullScreen = false;
    //
    this.afterViewShowSuccess = this.afterViewShowSuccessExt.bind(this);   
    //
    this._selectZaposleni = new SaopMultiselect(libName+"._selectZaposleni",{selectAll:false,selectAllOption:false,inputWidthPX:'260px'});
    this._selectZaposleni.afterChangeCommonEvent = this.refreshSelectedEmployeesList.bind(this);
  }

  setDictionary(){
    let dictionary:string[] = ["Nov vnos: Sopotnik"];
    this.setTerminologyList(dictionary);
}   

  public bindRemoveButtonEvents ():void{
    let _this = this;
    //
    $(this.id).find('.saop-remove-item-event').off('click');
    $(this.id).find('.saop-remove-item-event').bind('click', 
      function(row) {
        global.ConsoleLogDegug(".saop-remove-item-event.click");
        // //
        let _btnRemove = row.currentTarget;
        let _sifrazaposleni = _btnRemove.getAttribute("data-sifrazaposleni");
        //
        _this._selectZaposleni.unsetSelectValues([_sifrazaposleni]);
      }          
    );  

  }

  public afterViewShowSuccessExt(sender:any):void{
    global.ConsoleLogDegug(this.id + ".afterViewShowSuccessExt()");
    this._selectZaposleni.init("#opnModel_SelectedEmployees", "Zaposleni"); 
  }  
  
  public refreshSelectedEmployeesList():void {
    global.ConsoleLogDegug("regreshSelectedEmployeesList");

    let _selectedEmployeesList = $(this.id).find("#opnModel_SelectedEmployees").val() as [any];
    //
    $(this.id).find("#SelectedEmployeesList").val(_selectedEmployeesList);
    //
    var myJsonString = JSON.stringify(_selectedEmployeesList);
    this.refreshLinksData(myJsonString);
    //
  }

  refreshLinksData(jsonEmployeesList:string):void {
    global.ConsoleLogDegug("refreshLinksData");
    let _this = this;

    let _data = this.getData();
    Object.assign(_data, {"jsonEmployeesList":jsonEmployeesList});

    let _saopServicesCore = new SaopServicesCore();
    _saopServicesCore.afterDone = function(response){
      $(_this.id).find("#SelectedEmployeesListAjax").html(response);
      _this.bindRemoveButtonEvents();
    };
    _saopServicesCore.ExecuteAction({
        action:"TravelOrder/GetLinks" 
        ,data: _data
        ,type:"GET"
    });     
  }


  //
}






