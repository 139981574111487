﻿import { global } from "../Common/saop-common";
import { SaopViewForm } from "../Core/saop-view-form";
import { SaopAbsenceConfirmationDetailsEvents } from "./saop-absenceConfirmation-details-events";

export class SaopAbsenceConfirmationDetailsView extends SaopViewForm {
    private saopAbsenceConfirmationDetailsEvents: SaopAbsenceConfirmationDetailsEvents;

    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);
        this.setDictionary();   
        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "AbsenceConfirmation/GetDetails";
        this.deleteRecordEndpoint = "";

        this.saopAbsenceConfirmationDetailsEvents = new SaopAbsenceConfirmationDetailsEvents("", libName + ".saopAbsenceConfirmationDetailsEvents");
        this.saopAbsenceConfirmationDetailsEvents.refreshFormDataExtended = this.refreshFormData.bind(this);
    }  

    setDictionary() {
        this.setTerminologyList();
    }  

    showForm(data: any, title: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsView.showForm");

        this.title = title;
        this.recordDetails(data);

        this.viewShowComplete = this.initForm.bind(this);
    }

    initForm(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsView.initForm");
        this.saopAbsenceConfirmationDetailsEvents.init();
    }

    private refreshFormData(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsView.refreshFormData");
        this.refreshDataExtended();
    }

    refreshDataExtended(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationDetailsView.refreshDataExtended");
    }
}


