﻿import { global, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopMultiselect, SaopMultiselectOptions } from "../Components/saop-multiselect";
import { SaopServicesCore, SaopServicesCoreParams } from "../Core/saop-services-core";

export class SaopSelectDropDownItem {
    public key: string;
    public value: string;
}

export class SaopSelectDropDownItems {
    public downItems:[SaopSelectDropDownItem?] = [];
}

export class SaopSelectDropDown extends SaopMultiselect{
    //
    public dropDownContainerId:string; 
    public msDropDownContainer:JQuery<HTMLElement>;
    //
    public msAddButton: JQuery<HTMLElement>;
    public msEditButton: JQuery<HTMLElement>;    
    //

    constructor(libName:string = "", options:SaopMultiselectOptions = null) {
        global.ConsoleLogDegug("SaopMultiselect");
        if (options == null) {
            options = {templatesButton:""};
        } 

        if (isUnDefined(options.templatesButton) || options.templatesButton.length == 0){
            options.templatesButton = '<button type="button" class="multiselect dropdown-toggle pr-0" data-toggle="dropdown"><span class="multiselect-selected-text"></span><span class="saop-img-down si-s float-right"></span><span class="saop-img-close si-s float-right" style=" height: 5px; width: 10px; font-size: 15px; margin-top: 5px;"></span></button>';    
        }        

        super(libName,options);
    } 

    init(target: string = "", caption = "" ){
        
        //
        this.dropDownContainerId = target+"_DropDownContainer";
        this.msDropDownContainer = $(this.dropDownContainerId);
        if (this.msDropDownContainer.length > 0) {
            this.msAddButton = this.msDropDownContainer.find(".saop-img-plus");
            this.msEditButton = this.msDropDownContainer.find(".saop-img-edit");
        } 
        //        
        super.init(target, caption);
        //
        this.bindEvents();
    }

    public bindEvents ():void{
        let _this = this;
        if (this.msDropDownContainer.length > 0) {
            $(this.dropDownContainerId+" .saop-img-close").off("click");
            $(this.dropDownContainerId+" .saop-img-close").on("click", function (e) {
                global.ConsoleLogDegug("SaopMultiselect.clear.click");
                e.stopPropagation();
                if (_this.msDropDownContainer.length > 0){
                    _this.msDropDownContainer.find('input[type="radio"]').prop('checked', false);
                    _this.msDropDownContainer.find('.multiselect-selected-text').html("");
                    _this.setMsFloatingLabel(true);
                } else {
                    _this.selectedCB.multiselect('deselectAll');
                }
            });             
            //
            this.msAddButton.off("click");
            this.msAddButton.on("click", function (e) {
                global.ConsoleLogDegug("SaopMultiselect.msAddButton.click");
                _this.onAddBtnClick(e);
            });  
            //
            this.msEditButton.off("click");
            this.msEditButton.on("click", function (e) {
                global.ConsoleLogDegug("SaopMultiselect.msEditButton.click");
                _this.onEditBtnClick(e);
            });       
            //     
        }
    }

    setMsFloatingLabel(isEmpry:boolean){
        super.setMsFloatingLabel(isEmpry);
        if (isEmpry){
            if (this.msDropDownContainer.length > 0) {
                this.msAddButton.css("display","unset"); 
                this.msEditButton.css("display","none");
            }
        } else {        
            if (this.msDropDownContainer.length > 0) {
                this.msAddButton.css("display","none"); 
                this.msEditButton.css("display","unset");                
            }           
        }         
    }

    public onAddBtnClick(sender:any):void{
        global.ConsoleLogDegug("onAddBtnClick...");
    }  

    public onEditBtnClick(sender:any):void{
        global.ConsoleLogDegug("onEditBtnClick...");
    }  

    initAll(targetClass: string = ""){
         var _msList = document.querySelectorAll<HTMLElement>('.saop-dropdownselect');

         _msList.forEach(element => {
            let _input = new SaopMultiselect();
            let _id = "#"+element.id;
            _input.init(_id);
         });
    }

    getDropDownItemsData(_params:SaopServicesCoreParams, selectVal:string): void {
        global.ConsoleLogDegug("getDropDownItemsData");
        //
        let _this = this;
        let saopServicesCore = new SaopServicesCore();
    
        saopServicesCore.afterDone = function (response) {
            _this.refreshDropDownSelect(response, selectVal);
        };
    
        saopServicesCore.ExecuteAction({
            action: _params.action
            , data: _params.data
            , type: _params.type
        });    
    }

    refreshDropDownSelect(dropDown:[any], selectVal:string): void {
        let selectDivTemplate = "";

        //let _sifraRelacije = this.selectedCB.val();

        dropDown.forEach(function (value) {
            if (value.key == selectVal) {
                selectDivTemplate = selectDivTemplate + "\n" + "<option selected=\"selected\" value=\""+value.key+"\">"+value.key+" - "+value.value+"</option>"
            } else {
                selectDivTemplate = selectDivTemplate + "\n" + "<option value=\""+value.key+"\">"+value.key+" - "+value.value+"</option>"
            }
            
        });
        
        this.selectedCB.html(selectDivTemplate);

        let _selectDiv = this.selectedCB.clone();

        this.msDropDownContainer.find("#fLabel").html(_selectDiv);

        this.init(this._target, this._caption);
    }

    //
}






