import { global, setModalFormZIndex, isUnDefined } from "../Common/saop-common";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import { SaopServicesCore } from "../Core/saop-services-core";
import { LIKVCakalnaVrstaView } from "./LIKVCakalnaVrsta-view";
import { SaopWfAction } from "../Core/saop-view-form";
import { SaopMultiselect } from "../Components/saop-multiselect";
import { SaopPopover } from "../Components/saop-popover";
import { DataTables } from "../@types/data-tables/types";

export class LIKVCakalnaVrstaArhivGrid {
    private libName:string;
    private _saopServicesCore: SaopServicesCore;
    private _mainFormDiv:JQuery<HTMLElement>;
    private _searchValue:JQuery<HTMLElement>;
    private _selectedRowNo:number;
  
    //
    public webGrid1:SaopWebGrid;
    public _LIKVCakalnaVrstaView:LIKVCakalnaVrstaView;
    private _vrstaDokumenta: SaopMultiselect;  
    private _vrstaDokumentaDivId: string;     
    private _vrstaDokumentaMobile: SaopMultiselect;     
    private _izbranaVrstaDokumenta: string;
    private _izbranoLeto: number; 
    private _statusPotrjen: Boolean;
    private _statusZavrnjen: Boolean; 
    private _statusBrisan: Boolean;
    private _saopPopoverFilter: SaopPopover;         
    
    constructor(libName: string) {
        this.libName = libName;
        this._saopServicesCore = new SaopServicesCore();
        //
        this._LIKVCakalnaVrstaView = new LIKVCakalnaVrstaView("#LIKVCakalnaVrstaView001",this.libName+"._LIKVCakalnaVrstaView");
        this._LIKVCakalnaVrstaView.fullScreen = true;  
        this._LIKVCakalnaVrstaView.MoveToNext = this.MoveToNextExt.bind(this);
        this._LIKVCakalnaVrstaView.MoveToPrevious = this.MoveToPreviousExt.bind(this);        
//        this._vrstaDokumentaDivId = "#VrstaDokumentaDiv";  
        this._izbranoLeto = 0;      
        this._statusPotrjen = false;
        this._statusZavrnjen = false; 
        this._statusBrisan = false;
        this._saopPopoverFilter = new SaopPopover();
        this._saopPopoverFilter.afterPopoverShow = this.afterPopoverMobileFilterShow.bind(this);                        
     }  
      
      initView(){
        this._mainFormDiv = $("#LIKVCakalnaVrstaGridArchiveBrowseForm");
        this.initWebGrid1();
        this.initWebGrid1Listners();    
        this._searchValue = this._mainFormDiv.find("#SearchValue");   
        this.initFilterControls();  
//        this.initVrstaDokumenta();  
        this._saopPopoverFilter.bindPopoverEvents();  
        this._mainFormDiv.show();
      }    
      
      initWebGrid1(){
        let wgId = "#LIKVCakalnaVrstaGridArchive";
        let captionToolbar = $(wgId).attr("caption-toolbar");
        let toolbarItemsTemplateId = $(wgId).attr("toolbar-items-templateId");
        //
        this.webGrid1 = new SaopWebGrid(this.libName+".webGrid1");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar:SaopWebGridToolbar;
        let toolbarButtons:SaopWebGridToolbarButton[] = [];
        let dataTable:SaopWebDataTable;
        //
        gridToolbar = {id:"saopWebGridTB1",caption:captionToolbar,buttons:toolbarButtons,itemsTemplateId:toolbarItemsTemplateId};
       
        dataTable = { enabled:true, colSettings:true };
    
        gridProperties = {
            gridId:wgId
            ,togglePanel:{enabled:false}
            ,toolbar:gridToolbar
            ,dataTable:dataTable
          };        
        //
        this.webGrid1.init(gridProperties);   
        let _SearchValue2 = $('#collapsesaopWebGridTB1').find('#SearchValue2');
        if (_SearchValue2.length > 0) {
          this._izbranoLeto = _SearchValue2.val() as number;    
        } 
        let _applicationFilter = this._mainFormDiv.find("#ApplicationFilter"); 
        if (_applicationFilter.length > 0) {
          this._izbranaVrstaDokumenta = _applicationFilter.val() as string;    
        }  

          $(wgId + "_wrapper").css('max-height', 'calc(100vh - 350px)');
      } 
      
      public MoveToNextExt ():void{
        let _row = $('#LIKVCakalnaVrstaGridArchive > tbody > tr').eq(this._selectedRowNo + 1); // here we extrapolate the row data of selected index
        if (_row.length > 0) {
          this._selectedRowNo = this._selectedRowNo + 1; // here we increase the index of the selected row
          //
          let _idVrste = _row.attr("data-idvrste");
          let _id = _row.attr("data-id"); 
          let _vrstaDokumenta = _row.attr("data-vrstadokumenta");
          let _dokument = _row.attr("data-dokument");
          //
          // barvanje oznacene vrstrice
          this.webGrid1.markSelectedRow(<HTMLElement>(_row)[0]);
          this.editRecord(_vrstaDokumenta,_dokument,_idVrste,_id);
        }
      }

      public MoveToPreviousExt ():void{
        let _row = $('#LIKVCakalnaVrstaGridArchive > tbody > tr').eq(this._selectedRowNo - 1); // here we extrapolate the row data of selected index
        if (_row.length > 0) {
          this._selectedRowNo = this._selectedRowNo - 1; // here we decrease the index of the selected row
          //
          let _idVrste = _row.attr("data-idvrste");
          let _id = _row.attr("data-id"); 
          let _vrstaDokumenta = _row.attr("data-vrstadokumenta");
          let _dokument = _row.attr("data-dokument");
          //
          // barvanje oznacene vrstrice
          this.webGrid1.markSelectedRow(<HTMLElement>(_row)[0]);
          this.editRecord(_vrstaDokumenta,_dokument,_idVrste,_id);
        }
      }

    initWebGrid1Listners() {
        let _this = this;
        let _SearchValue2 = $('#collapsesaopWebGridTB1').find('#SearchValue2');
        if (_SearchValue2.length > 0) {
            _SearchValue2.off("change");
            _SearchValue2.on("change", _this.onSearchValue2Change.bind(_this));
        }

        let _applicationFilter = this._mainFormDiv.find("#ApplicationFilter");
        if (_applicationFilter.length > 0) {
            _applicationFilter.off("change");
            _applicationFilter.on("change", _this.onApplicationFilterChange.bind(_this));
        }

        let _statPotrjen = $('#collapsesaopWebGridTB1').find('#statusPotrjen');
        if (_statPotrjen.length > 0) {
            _statPotrjen.off("change");
            _statPotrjen.on("change", _this.onStatusPotrjenChange.bind(_this));
        }

        let _statZavrnjen = $('#collapsesaopWebGridTB1').find('#statusZavrnjen');
        if (_statZavrnjen.length > 0) {
            _statZavrnjen.off("change");
            _statZavrnjen.on("change", _this.onStatusZavrnjenChange.bind(_this));
        }

        let _statBrisan = $('#collapsesaopWebGridTB1').find('#statusBrisan');
        if (_statBrisan.length > 0) {
            _statBrisan.off("change");
            _statBrisan.on("change", _this.onStatusBrisanChange.bind(_this));
        }


        $("body").off("click", "#LIKVCakalnaVrstaGridArchive td:not(.td-action)");
        $("body").on("click", "#LIKVCakalnaVrstaGridArchive td:not(.td-action)", function () {
            let _i = $(this).closest('tr').index();
            _this._selectedRowNo = _i; // here we store the ID of the current row
            var data = $(this).closest("tr");
            let _idVrste = data.attr("data-IDVrste");
            let _id = data.attr("data-ID");
            let _vrstaDokumenta = data.attr("data-vrstadokumenta");
            let _dokument = data.attr("data-dokument");

            if (!isUnDefined(_idVrste) && !isUnDefined(_id)) {
                // barvanje oznacene vrstrice
                _this.webGrid1.markSelectedRow(<HTMLElement>(data)[0]);

                _this.editRecord(_vrstaDokumenta, _dokument, _idVrste, _id);
            }
        });

        //
    }    
      
      refreshData(htmlContent:string){
        this.webGrid1.replaceGridRows(htmlContent);
        this.initWebGrid1Listners();
      }

    refreshGridData(year: number, applicationCode: string = "", archive: boolean = true): void {
        global.ConsoleLogDegug("LIKVCakalnaVrstaGridArchive.refreshRows:");

        let _this = this;
        let saopServicesCore = new SaopServicesCore();
        let _statusFilter = 111;
        if (this._statusPotrjen)
            _statusFilter = _statusFilter + 100;
        if (this._statusZavrnjen == true)
            _statusFilter = _statusFilter + 10;
        if (this._statusBrisan == true)
            _statusFilter = _statusFilter + 1;
        let params = { year: year, applicationCode: applicationCode, archive: archive, statusFilter: _statusFilter };

        saopServicesCore.afterDone = function (response) {
            _this.refreshData(response);
        };
        saopServicesCore.ExecuteAction({
            action: "LikvConfirmations/IndexArchive"
            , data: params
            , type: "GET"
        });
    }

    editRecord(_vrstaDokumenta:string,_dokument: string,_idVrste:string,_id:string){
      let _fullTitle = this._LIKVCakalnaVrstaView.translationService.translate("Potrjevanje") + ": " + _vrstaDokumenta + " " + _dokument;
      this._LIKVCakalnaVrstaView.fullTitle = _fullTitle;
      this._LIKVCakalnaVrstaView.showViewForm(SaopWfAction.wfaEditRecord,{ queueId: _idVrste, threeId: _id, archive: true, fullView: true });     
    }   
  
    initFilterControls(): void {
      let _this = this;
      this._searchValue.keyup(function () {
          let oTable = _this.webGrid1.dataTable;
          oTable.search($(this).val().toString()).draw() ;           
      });    
    }

    // initVrstaDokumenta(): void {
    //   global.ConsoleLogDegug("LIKVCakalnaVrstaView.initVrstaDokumenta");
  
    //   this._vrstaDokumenta = new SaopMultiselect();
    //   this._vrstaDokumenta.afterChangeCommonEvent = this.filterVrstaDokumenta.bind(this);
    //   // init za metodo "afterChangeCommonEvent", da se že v "_vrstaDokumenta.init" metodi sprozi filterVrstaDokumenta
    //   this._vrstaDokumenta.init("#VrstaDokumentaFilter", "Vrsta dokumenta");
    //   // prikazemo select div
    //   $(this._vrstaDokumentaDivId).show();
    // }
  
    // filterVrstaDokumenta(element: any) {
    //   global.ConsoleLogDegug("LIKVCakalnaVrstaView.filterVrstaDokumenta");
  
    //   // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
    //   this._izbranaVrstaDokumenta = <string>(element.val().toString());
  
    //   this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta);
    // }  

    onSearchValue2Change(e:any):void{
      let _SearchValue2 = $('#collapsesaopWebGridTB1').find('#SearchValue2');
      if (_SearchValue2.length > 0) {
        this._izbranoLeto = _SearchValue2.val() as number;
      }      
      this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta);
    }  

    onApplicationFilterChange(e:any):void{
      let _applicationFilter = this._mainFormDiv.find("#ApplicationFilter");
      if (_applicationFilter.length > 0) {
        this._izbranaVrstaDokumenta = _applicationFilter.val() as string;
        if (this._izbranaVrstaDokumenta == "?" )
          this._izbranaVrstaDokumenta = "";
      }      
      this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta);
    }

    onStatusPotrjenChange(e:any):void{
      this._statusPotrjen = false;
      let _statPotrjen = $('#collapsesaopWebGridTB1').find('#statusPotrjen');
      if (_statPotrjen.is(":checked")){  
        this._statusPotrjen = _statPotrjen.is(":checked");
      }      
      this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta);
    }  

    onStatusZavrnjenChange(e:any):void{
      this._statusZavrnjen = false;
      let _statZavrnjen = $('#collapsesaopWebGridTB1').find('#statusZavrnjen');
      if (_statZavrnjen.is(":checked")){  
        this._statusZavrnjen = _statZavrnjen.is(":checked");
      }      
      this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta);
    } 

    onStatusBrisanChange(e:any):void{
      this._statusBrisan = false;
      let _statBrisan = $('#collapsesaopWebGridTB1').find('#statusBrisan');
      if (_statBrisan.is(":checked")){  
        this._statusBrisan = _statBrisan.is(":checked");
      }      
      this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta, false);
    }

    afterPopoverMobileFilterShow(element: HTMLElement): void {
      global.ConsoleLogDegug("LIKVCakalnaVrstaView.afterPopoverMobileFilterShow");

      let atrContent = element.getAttribute("data-popover-content");
      // preverimo če je popup likv filter
      if (atrContent === "#popOverLikvFilter") {

          // this._vrstaDokumentaMobile = new SaopMultiselect();
          // this._vrstaDokumentaMobile.init("div.popover.show #VrstaDokumentaFilterMobile", "Vrsta dokumenta");
          // // metoda this.setSelectSkupinaMobileFilterValues se mora izvesti pred this._selectSkupinaMobileFilter.afterChangeCommonEvent
          // // da se se ne izvede metoda this.selectSkupinaMobileFilterChanged, ker se v this.setSelectSkupinaMobileFilterValues
          // // nastavijo vrednosti v klasu SaopMultiselect in posledicno se klice tudi afterChangeCommonEvent event
          // this.setSelectVDMobileFilterValues();   
          // this._vrstaDokumentaMobile.afterChangeCommonEvent = this.filterVrstaDokumentaMobileChanged.bind(this);

          let _SearchValue2Mobile = $('div.popover.show').find('#SearchValue2Mobile');  
          if (_SearchValue2Mobile.length > 0) {
            _SearchValue2Mobile.off("change");
            _SearchValue2Mobile.on("change",this.onSearchValue2MobileChange.bind(this));
          }
          _SearchValue2Mobile.val(this._izbranoLeto);

          let _ApplicationFilterMobile = $('div.popover.show').find('#ApplicationFilterMobile');  
          if (_ApplicationFilterMobile.length > 0) {
            _ApplicationFilterMobile.off("change");
            _ApplicationFilterMobile.on("change",this.onApplicationFilterMobileChange.bind(this));
          }
          _ApplicationFilterMobile.val(this._izbranaVrstaDokumenta).change(); //prop('selected', true);

          let _statPotrjenMobile = $("div.popover.show").find('#statusPotrjenMobile');  
          if (_statPotrjenMobile.length > 0) {
            _statPotrjenMobile.off("change");
            _statPotrjenMobile.on("change",this.onStatusPotrjenMobileChange.bind(this));
          }
          _statPotrjenMobile.prop('checked', this._statusPotrjen);
          
          let _statZavrnjenMobile = $("div.popover.show").find('#statusZavrnjenMobile');  
          if (_statZavrnjenMobile.length > 0) {
            _statZavrnjenMobile.off("change");
            _statZavrnjenMobile.on("change",this.onStatusZavrnjenMobileChange.bind(this));
          }  
          _statZavrnjenMobile.prop('checked', this._statusZavrnjen);

          let _statBrisanMobile = $("div.popover.show").find('#statusBrisanMobile');
          if (_statBrisanMobile.length > 0) {
                _statBrisanMobile.off("change");
                _statBrisanMobile.on("change",this.onStatusBrisanMobileChange.bind(this));
            }

          // dodamo custom css za filter
          let filterPopoverDiv = $("div.popover.show");
          if (filterPopoverDiv != null) {
              let customCss = "calendar-filter";
              if (!filterPopoverDiv.hasClass(customCss)) {
                  filterPopoverDiv.addClass(customCss);
              }
          }

          // dodamo custom css za filter
          let filterPopoverBodyDiv = $("div.popover.show").find("div.popover-body");
          if (filterPopoverBodyDiv != null) {
              let customCss = "calendar-filter-body";
              if (!filterPopoverBodyDiv.hasClass(customCss)) {
                  filterPopoverBodyDiv.addClass(customCss);
              }
          }
      
          // prikazi filter okno
          let filterBody = $("div.popover.show").find("#popOverLikvFilterBody");
          filterBody.show();
      }
  }

//   setSelectVDMobileFilterValues() {
//     global.ConsoleLogDegug("LIKVCakalnaVrstaView.setSelectVDMobileFilterValues");
//         // preberemo vrednosti VD iz desktop Category element
//      let izbranaVrstaDokumenta = <string[]>(this._vrstaDokumenta.selectedCB.val());
//      this._vrstaDokumentaMobile.setSelectValues(izbranaVrstaDokumenta);
// }  

// filterVrstaDokumentaMobileChanged(element: any) {
//   global.ConsoleLogDegug("LIKVCakalnaVrstaView.filterVrstaDokumenta");

//   // preberemo, VD in jih shranimo v desktop Category element
//   let izbranaVrstaDokumenta = <string[]>(element.val());
//   this._vrstaDokumenta.setSelectValues(izbranaVrstaDokumenta);

//   this.refreshGridData(0, izbranaVrstaDokumenta.toString());  
// }

onSearchValue2MobileChange(e:any):void{
  let _SearchValue2 = $('div.popover.show').find('#SearchValue2Mobile');
  if (_SearchValue2.length > 0) {
    this._izbranoLeto = _SearchValue2.val() as number;
  }    
  $("#SearchValue2").val(this._izbranoLeto);    
  this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta);
}  

onApplicationFilterMobileChange(e:any):void{
  let _applicationFilter = $('div.popover.show').find('#ApplicationFilterMobile');
  if (_applicationFilter.length > 0) {
    this._izbranaVrstaDokumenta = _applicationFilter.val() as string;
    if (this._izbranaVrstaDokumenta == "?" )
      this._izbranaVrstaDokumenta = "";
  }  
  this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta);
}  

onStatusPotrjenMobileChange(e:any):void{
  this._statusPotrjen = false;
  let _statPotrjen = $("div.popover.show").find('#statusPotrjenMobile');
  if (_statPotrjen.is(":checked")){  
    this._statusPotrjen = _statPotrjen.is(":checked");
  } 
  $("#statusPotrjen").prop('checked', this._statusPotrjen);     
  this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta);
}  

onStatusZavrnjenMobileChange(e:any):void{
  this._statusZavrnjen = false;
  let _statZavrnjen = $("div.popover.show").find('#statusZavrnjenMobile');  
  if (_statZavrnjen.is(":checked")){  
    this._statusZavrnjen = _statZavrnjen.is(":checked");
  }      
  $("#statusZavrnjen").prop('checked', this._statusZavrnjen);    
  this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta);
    } 

    onStatusBrisanMobileChange(e: any): void {
        this._statusBrisan = false;
        let _statBrisan = $("div.popover.show").find('#statusBrisanMobile');
        if (_statBrisan.is(":checked")) {
            this._statusBrisan = _statBrisan.is(":checked");
        }
        $("#statusBrisan").prop('checked', this._statusBrisan);
        this.refreshGridData(this._izbranoLeto, this._izbranaVrstaDokumenta, false);
    }

    //
}