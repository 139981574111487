﻿
export interface SaopInputWFLProperties {
    id:string;
    placeholder:string;
    caption:string;
}

export class SaopInputWFL {
    private _inputTemplate:string;
    constructor(properties:SaopInputWFLProperties) {
        this._inputTemplate = 
        `
        <span class="saop-col-filter-group">
            <input autocomplete="off" class="form-control shadow-none saop-control saop-control-listBox saop-col-filter-input" style="width:95% !important;" id="${properties.id}" name="${properties.id}" placeholder="${properties.placeholder}" tabindex="0" type="text" value="">
            <i id="searchIcon" class="saop-img-search si-s form-control-feedback" style="cursor:pointer;top:5px;position:absolute;left: calc(100% - 40px) !important;"/>
        </span>        
        `;              
        // this._inputTemplate = 
        // `
        // <span class="has-float-label">
        //     <input autocomplete="off" class="form-control shadow-none saop-control saop-control-listBox" data-inputmask-alias="dd.mm.yyyy hh:mm" id="${properties.id}" name="${properties.id}" placeholder="${properties.placeholder}" tabindex="0" type="text" value="" disabled="">
        //     <label class="saop-floating-label saop-txt-7" for="${properties.id}">${properties.caption}</label>
        //     <i id="calendarIcon" class="saop-img-calendar si-s form-control-feedback" style="cursor:pointer;left:230px;top:5px;position:absolute;"/>
        // </span>        
        // `;        
    }

    getElement():JQuery<HTMLElement>{
        let _element = $(this._inputTemplate);
        return _element;
    }

    getInnerHtml():string{
        return this._inputTemplate;
    }

}





