﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global, isEmpty } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import * as moment from '../../node_modules/moment/moment';
import { SaopRegUraDogodekView } from "./saop-regUraDogodek-view";
import { SaopRegCurrentEventView } from './saop-regCurrentEvent-view'

export class SaopRegTodayEventsView extends SaopRegUraDogodekView {
    public regCurrentEventView:SaopRegCurrentEventView;
    //
    private _saopServicesCore :SaopServicesCore;

    constructor(viewId:string,libName:string = "") {
        super(viewId,libName);
        this._saopServicesCore = new SaopServicesCore();
        //
        this.refreshData = this.refreshListData.bind(this);
        //
        this.regCurrentEventView = new SaopRegCurrentEventView("#regAcctionButtonsView",libName+".regCurrentEventView");
        this.regCurrentEventView.afterSubmitSuccess = this.refreshListData.bind(this);   
    }

    initView(): void {
        global.ConsoleLogDegug("initView");
        this.initClock();
        this.onReadyInitRegUraDogodekView();
        this.onReadyInitDogodkiDanasnjiPartialView();        
    }

    bindGridEvents(){
        global.ConsoleLogDegug("bindGridEvents");
        let _this = this;
        //
        $("#regUraDogodekAddRecord").off("click");
        $("#regUraDogodekAddRecord").on('click', function(row){
            global.ConsoleLogDegug("regUraDogodekAddRecord.click");
            let currentDate = moment(new Date()).format("DD.MM.YYYY")
            _this.addRecord({ SelectedDay:currentDate});
        });         
        //
        $(".regDogodkiDanasnjiTable_edit").off("click");
        $(".regDogodkiDanasnjiTable_edit").on('click', function(row){
            global.ConsoleLogDegug("regDogodkiDanasnjiTable_edit.click");
            let _btnEdit = row.currentTarget;
            let btnEnabled = _btnEdit.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let zacetek = _btnEdit.getAttribute("data-zacetek");
                let konec = _btnEdit.getAttribute("data-konec");
                _this.editRecord({ idStart: zacetek, idEnd: konec },_this);
            }            
        });        
        //
        $(".regDogodkiDanasnjiTable_delete").off("click");
        $(".regDogodkiDanasnjiTable_delete").on('click', function(row){
            global.ConsoleLogDegug("regDogodkiDanasnjiTable_delete.click");
            let _btnDelete = row.currentTarget;
            let btnEnabled = _btnDelete.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let zacetek = _btnDelete.getAttribute("data-zacetek");
                let konec = _btnDelete.getAttribute("data-konec");
                // //
                let deleteModalFormTitle = _this.translationService.translate("Brisanje");
                let deleteModalFormBody = _this.translationService.translate("Ali ste prepričani, da želite izbrisati dogodek?");
                //
                _this.deleteRecord(deleteModalFormTitle,deleteModalFormBody,{ idStart: zacetek, idEnd: konec });
            }
        });         
    }

    afterRefreshData():void {
        global.ConsoleLogDegug("SaopRegTodayEventsView.afterRefreshListData");
    }

    refreshListData(trigger:boolean = true):void {
        global.ConsoleLogDegug("SaopRegTodayEventsView.refreshView");
        let _this = this;

        this._saopServicesCore.afterDone = function(response){
            $("#regTodayRecordForm").html(response);
            _this.bindGridEvents();
            _this.regCurrentEventView.bindFormAjaxEvents();   
        };
        this._saopServicesCore.ExecuteAction({
            action:"REGTodayEvents/MainFormPartial" 
            ,data:null
            ,type:"GET"
            ,spinnerDivId:"#regTodayRecordLoading"
        });       

        if (trigger && this.afterRefreshData != null){ this.afterRefreshData(); }
    }

    onReadyInitRegUraDogodekView(): void {
        global.ConsoleLogDegug("onReadyInitRegUraDogodekView");

        let message = <string>($("#OnLoadMessage").val());
        if (message) {
            alertify.message(message);
        }

        this.refreshListData();
    }

    initClock(): void {
        global.ConsoleLogDegug("onReadyInitDogodkiTekociPartialView");

        this.updateClock();
        this.updateClock1();
        this.updateClock2();

        setInterval(this.updateClock, 1000)
        setInterval(this.updateClock1, 1000)
        setInterval(this.updateClock2, 1000)
    }

    updateClock(): void {
        let clockElement = $("#clock");

        let currentDate = moment();
        let currentDate_ms = moment.duration(currentDate.format("HH:mm:ss")).asMilliseconds();

        let currentTimeString = moment.utc(Math.abs(currentDate_ms)).format("HH:mm:ss");
        clockElement.html(currentTimeString);
    }

    updateClock1(): void {
        //global.ConsoleLogDegug('SaopRegTodayEventsView.updateClock1');

        let clockElement1 = $("#clock1");

        let currentTime = moment();
        let currentTime_ms = moment.duration(currentTime.format("HH:mm:ss")).asMilliseconds();

        var saldoUreHHMM = $('#SaldoUreHHMM').val() + ":00";
        var saldoUreHHMM_ms = moment.duration(saldoUreHHMM).asMilliseconds();

        var uraZadnjegaDogodka = $('#UraZadnjegaDogodka').val();

        var result_ms = saldoUreHHMM_ms;
        if (uraZadnjegaDogodka !== undefined) {
            var uraZadnjegaDogodka_ms = moment.duration(uraZadnjegaDogodka.toString()).asMilliseconds();

            if (uraZadnjegaDogodka_ms > 0)
                result_ms = result_ms + (currentTime_ms - uraZadnjegaDogodka_ms);
        }

        var isNegative = result_ms < 0;
        var formattedDuration = moment.utc(Math.abs(result_ms)).format("H:mm");
        //var formattedDuration = moment.utc(Math.abs(result_ms)).format("H:mm:ss");
        isNegative = isNegative && formattedDuration !== '0:00';
        const result = isNegative ? `-${formattedDuration}` : `+${formattedDuration}`;

        clockElement1.html(result);
    }

    updateClock2(): void {
        //global.ConsoleLogDegug('SaopRegTodayEventsView.updateClock2');
        let clockElement2 = $("#clock2");

        let currentTime = moment();
        let currentTime_ms = moment.duration(currentTime.format("HH:mm:ss")).asMilliseconds();

        var saldoUreTekoceHHMM = $('#SaldoUreTekoceHHMM').val() + "";
        var saldoUreTekoceHHMM_list = saldoUreTekoceHHMM.split(":");
        let isNegative = Number(saldoUreTekoceHHMM_list[0]) < 0;
        var saldoUreTekoceHHMM_ms = (Math.abs(Number(saldoUreTekoceHHMM_list[0]) * 3600) + Math.abs(Number(saldoUreTekoceHHMM_list[1]) * 60)) * 1000;
        saldoUreTekoceHHMM_ms = isNegative ? saldoUreTekoceHHMM_ms * (-1) : saldoUreTekoceHHMM_ms;

        var uraZadnjegaDogodka = $('#UraZadnjegaDogodka').val();

        var result_ms = saldoUreTekoceHHMM_ms;
        if (uraZadnjegaDogodka !== undefined) {
            var uraZadnjegaDogodka_ms = moment.duration(uraZadnjegaDogodka.toString()).asMilliseconds();

            if (uraZadnjegaDogodka_ms > 0)
                result_ms = result_ms + (currentTime_ms - uraZadnjegaDogodka_ms);
        }

        isNegative = result_ms < 0;
        result_ms = Math.abs(result_ms);

        var hours = Math.floor(result_ms / 3600000);
        var minutes = Math.floor((result_ms - hours * 3600000) / 60000);
        //var seconds = Math.floor((result_ms - hours * 3600000 - minutes * 60000) / 1000);

        var hoursStr = hours;
        var minutesStr = (minutes < 10 ? "0" : "") + minutes;
        //var secondsStr = (seconds < 10 ? "0" : "") + seconds;

        var formattedDuration = hoursStr + ':' + minutesStr;
        //var formattedDuration = hoursStr + ':' + minutesStr + ':' + secondsStr;
        isNegative = isNegative && formattedDuration !== '0:00';
        const result = isNegative ? `-${formattedDuration}` : `+${formattedDuration}`;

        clockElement2.html(result);
    }

    onReadyInitDogodkiDanasnjiPartialView(): void {
        global.ConsoleLogDegug("onReadyInitDogodkiDanasnjiPartialView");

        $("#regDogodkiDanasnjiTable tbody tr").each(
            function (i, row) {
                // koda zakomentirana, ker ni potrebna.
                // Če ni zakomentirana, se pojavijo težave pri refresh view "Dogodki na dan", in se polje "Konec" obarva rdece zaradi spodnje kode

                //var $actualRow = $(row);

                //if ($actualRow.children('td:nth-child(2)').text() == "") {
                //    $(this).children('td:nth-child(6)').css("background-color", '#FAC0BE');
                //} else if ($actualRow.children('td:nth-child(1)').text() == "") {
                //    $(this).children('td:nth-child(5)').css("background-color", '#FAC0BE');
                //}
            });

        $("#webgrid1 th:nth-child(1)").hide();
        $("#webgrid1 th:nth-child(2)").hide();
    }

}


