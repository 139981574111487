﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";

export class DashboardMyGradesView {
    private _libName:string;
    private _saopServicesCore: SaopServicesCore;
    private _jeEnaSifraOcenObdobjaId: string = "#hiddenJeEnaSifraOcenObdobja"; 
    private _nazivOcenObdobjaId: string = "#hiddenNazivOcenObdobja"; 
    private _cardId: string = "#card5";
    //

    constructor(libName: string = "") {
        this._libName = libName;
        //
        this._saopServicesCore = new SaopServicesCore();
    }

    refreshGridData(): void {
        global.ConsoleLogDegug("DashboardMyGradesView.refreshGridData");
        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            $("#card5 .card-body").html(response);
            _this.init();
        };
        this._saopServicesCore.ExecuteAction({
            action:"Dashboard/MyGradesPartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingcard5"
        });   
    }    

    init(): void {
        global.ConsoleLogDegug("DashboardMyGradesView.init");

        let jeEnaSifraOcenObdobjaStr = $(this._cardId + " " + this._jeEnaSifraOcenObdobjaId).val().toString().toLowerCase();
        let jeEnaSifraOcenObdobja = jeEnaSifraOcenObdobjaStr === "true" ? true : false;
        if (jeEnaSifraOcenObdobja) {
            let nazivOcenObdobja = $(this._cardId + " " + this._nazivOcenObdobjaId).val().toString();

            let cardHeaderElement = $(this._cardId + " .card-header a.card-link");
            let cardHeaderText = cardHeaderElement.text().trim().replace("\n", "");

            cardHeaderElement.text(cardHeaderText + ": " + nazivOcenObdobja);
        }
    }
    //
}


