﻿import { global, setModalFormZIndex, isEmpty, isUnDefined,getRelativeSiteURL } from "../Common/saop-common";

export interface SaopViewFormBindingsParamsters {
    controller:string;
    action:string;
} 

export class SaopViewFormBindings {
    public id:string;
    public _formFrame:JQuery<HTMLElement>;    
    //
    private _libName:string;
    private _FbParams:SaopViewFormBindingsParamsters;
    private _websiteUrl:string;
    //
    public onSucccessCallback: string = "";
    public onErrorCallback: string = "";
    public onFailureCallback: string = "";    
    //
     
    constructor(viewId:string,libName:string = ""){
        global.ConsoleLogDegug("SaopViewFormBindings...");
        global.ConsoleLogDegug("viewId:"+viewId);
        this.id = viewId;
        this._libName = libName;
        this.onSucccessCallback = libName+".onSubmitSuccess(data,"+libName+");";
        this.onErrorCallback = libName + ".onSubmitError(data," + libName + ");"; 
        this.onFailureCallback = libName + ".onSubmitFailure(xhr," + libName + ");"; 
        //  
    } 

    public bindFormAjaxEvents(params:SaopViewFormBindingsParamsters = null): void {
        global.ConsoleLogDegug("bindViewEvents");
        this._FbParams = params;
        
        //
        this._formFrame = $(this.id);
        if (this._formFrame == null) { console.log(this.id+" not found!");};        
        //
        if(this._FbParams != null){
            this._websiteUrl = getRelativeSiteURL(this._FbParams.controller);
            if(isEmpty(this._FbParams.controller) == false){
                this._formFrame.attr("asp-controller", this._FbParams.controller);
            }
            if(isEmpty(this._FbParams.action) == false){
                this._formFrame.attr("asp-action", this._FbParams.action);
            }  
            if(isEmpty(this._FbParams.action) == false){
                var _action = this._websiteUrl+"/"+this._FbParams.action;
                this._formFrame.attr("action", _action);
            }                        
        }

        if (this.onSucccessCallback !== null) {
            this._formFrame.attr("data-ajax-success", this.onSucccessCallback);
        }        
        if (this.onSucccessCallback !== null) {
            this._formFrame.attr("data-ajax-success", this.onSucccessCallback);
        }
        if (this.onErrorCallback !== null) {
            this._formFrame.attr("data-ajax-error", this.onErrorCallback);
        }
        if (this.onFailureCallback !== null) {
            this._formFrame.attr("data-ajax-failure", this.onFailureCallback);
        }
    }    

    //  
}
