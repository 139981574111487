﻿import { global } from "../Common/saop-common";
import { KEGradesPeriodGrid } from "./KEGradePeriod-grid";

export class KEGradesPeriod {
    private libName: string;

    private keGradesPeriodGrid: KEGradesPeriodGrid;
    
    constructor(libName: string) {
        this.libName = libName; 

        this.keGradesPeriodGrid = new KEGradesPeriodGrid(this.libName + ".keGradesPeriodGrid");
        this.keGradesPeriodGrid.afterRefreshDataAfterEditViewExternal = this.refreshDataAfterEditView.bind(this);
    }  

    initView() {
        global.ConsoleLogDegug("KEGradesPeriod.initView");

        this.keGradesPeriodGrid.initView();
    }

    refreshDataAfterEditView(): void { 
        global.ConsoleLogDegug("KEGradesPeriod.refreshDataAfterEditView");
    }
}






