﻿import { global } from "../Common/saop-common";
import { SaopViewForm } from "../Core/saop-view-form";

export class KEMyGradeView extends SaopViewForm {

    constructor(viewId:string,libName:string = ""){
        super(viewId, libName);
        this.setDictionary(); 
        this.title = "";
        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "KeGrade/GetGradeDetails";
        this.deleteRecordEndpoint = ""; 
    }

    setDictionary(){
        this.setTerminologyList();
    } 

    public afterViewShowSuccess(sender:any):void{
        this.LoadViewEvents();
    }

    public LoadViewEvents ():void{
        let _viewDiv = $(this.id);
        if (_viewDiv.length == 0) {
            return;
        }
    }

    showForm(data: any, title: string): void {
        global.ConsoleLogDegug("KEMyGradeView.showForm");

        this.title = title;
        this.recordDetails(data);
        this.SetToFullScreen(); 

        this.viewShowComplete = this.initForm.bind(this);
    }

    initForm(): void {
        global.ConsoleLogDegug("KEMyGradeView.initForm");
    }
  //
}






