﻿import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopViewFormBindings } from "../Core/saop-view-form-bindings";
import { SaopWfSubmitResult } from "../Core/saop-view-form";


export interface SaopUploadFilesParams {
    controller:string,
    action:string,
    validateAjax?:boolean,
    formId?:string,
    validationDivId?:string,
    allowSelectMultiFiles?: boolean
}

export class SaopUploadFiles extends SaopViewFormBindings {
    private _clibName: string;
    private _siteUrl: string;
    private _params:SaopUploadFilesParams;
    private _popupViewId:string;
    private _popupView:JQuery<HTMLElement>;
    private _popupViewTitle:JQuery<HTMLElement>;
    private _viewModalBody:JQuery<HTMLElement>;
    private _btnSave:JQuery<HTMLElement>;
    private _btnCancel:JQuery<HTMLElement>;
    private _validations:JQuery<HTMLElement>;
    private contentElementId = ".saop-modal-body-element"; 
    private _saopServicesCore: SaopServicesCore;
    private _filesCount:number = 0;
    //
    public viewTitle:string = "Upload";

    constructor(viewId:string,libName:string = ""){
        super("#uploadSplDocumentsView",libName);
        this._clibName = libName;
        this._popupViewId = viewId;
        this.onErrorCallback = null; 
        this.onFailureCallback = null; 
        this._saopServicesCore = new SaopServicesCore();
    } 

    initComponent(params:SaopUploadFilesParams){ // use this method in case of no modal window
        global.ConsoleLogDegug("initComponent");
        let _this = this;
        this._params = params;
        this._siteUrl = getSiteAbsoluteURL(this._params.controller);  
        this.initInputControl(this._params.allowSelectMultiFiles);
        this.bindFormControlsEvents();
    }

    initView(params:SaopUploadFilesParams) { // use this method in case of modal window
        global.ConsoleLogDegug("initView");
        this._params = params;
        this._siteUrl = getRelativeSiteURL(this._params.controller);
        this.initInputControl(this._params.allowSelectMultiFiles);
        this.bindPopupControls();
        this.renderView();
    }

    initInputControl(allowSelectMultiFiles: boolean) {
        global.ConsoleLogDegug("SaopUploadFiles.initInputControl");
        let inputElement = $('.custom-file input');

        // po defaulti omogoča izbor multi datotek
        if (allowSelectMultiFiles == false) {
            inputElement.removeAttr("multiple");
        }
    }

    bindPopupControls(){
        global.ConsoleLogDegug("SaopUploadFiles.bindControls");
        this._popupView = $(this._popupViewId);
        this._popupViewTitle = $(""+this._popupViewId+" *> #exampleModalLongTitle");
        var contentSel = this._popupViewId + " " + this.contentElementId;
        this._viewModalBody = $(contentSel);         
        //
        this._popupViewTitle.html(this.viewTitle);
        //
    }

    bindFormControls(){
        global.ConsoleLogDegug("SaopUploadFiles.bindFormControls");
        this.bindFormAjaxEvents({controller:"SPLDocument",action:"UploadDocuments"});            
        this._btnSave = this._formFrame.find("#btnOk");
        this._btnCancel = this._formFrame.find("#btnCancel");
        this._validations = $("#uploadFilesValidationsAjax");  
        this.bindFormControlsEvents();     
    }

    bindFormControlsEvents(){
        global.ConsoleLogDegug("SaopUploadFiles.bindFormControlsEvents");
        let _this = this;
        $('.custom-file input').off("change");
        $('.custom-file input').change(function (e) {
            _this.refreshFileList(<HTMLInputElement>e.target);
        });        
    }

    refreshFileList(inputElement: HTMLInputElement): void {
        global.ConsoleLogDegug("SaopUploadFiles.refreshFileList");

        let files = inputElement.files;
        var file_list = "";

        this._filesCount = files.length;
        if (this._filesCount > 0) {
            file_list = "<div>";
            for (var i = 0; i < files.length; i++) {
                let fileElement = "<div style='display: flex;'>";
                fileElement = fileElement + "<div style='margin: auto; margin-left: 0px;'>" + files[i].name + "</div>";
                fileElement = fileElement + "<div class='saop-icon-pointer saop-img-trash si-s saop-cl-red' data-file-name='" + files[i].name + "' onclick = " + this._clibName + ".removeFile(this)></div>";
                fileElement = fileElement + "</div>";
                file_list = file_list + fileElement;
            }
            file_list = file_list + "</div>"
        }

        $('div.custom-file-list').removeClass("d-none");
        $('div.custom-file-list').addClass("d-block");

        $('.custom-file-list').html(file_list);

        this.afterRefreshFilesList();
    }

    removeFile(divElement: any): void {
        global.ConsoleLogDegug("SaopUploadFiles.removeFile");
        let fileName = divElement.getAttribute("data-file-name");

        let inputElementSelector = ".custom-file input";
        if (!isEmpty(this._params.formId)) {
            inputElementSelector = this._params.formId + " " + inputElementSelector;
        }

        let inputElement = <HTMLInputElement>$(inputElementSelector)[0];
        let files = inputElement.files

        const dt = new DataTransfer();

        for (var i = 0; i < files.length; i++) {
            if (files[i].name.toLowerCase() === fileName.toLowerCase()) {
            }
            else {
                dt.items.add(files[i]); 
            }
        }

        inputElement.files = dt.files;
        this.refreshFileList(inputElement);
    }

    afterRefreshFilesList(){

    }

    renderView(){
        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            _this._viewModalBody.html(response);
            _this.bindFormControls();
            _this._popupView.modal('show');
        };
        this._saopServicesCore.ExecuteAction({
            action: this._siteUrl+"/"+this._params.action
            ,type:"GET"
        });        
    }

    onSubmitSuccess(data: SaopWfSubmitResult): void {
        global.ConsoleLogDegug("SaopUploadFiles.onSubmitSuccess");
        if (data.success) {
            alertify.message(data.alertMessage);
            this._popupView.modal('hide');
            // just in case: odstrani, če je še vedno vidno, avtomatsko se ne zapre vedno :(
            $('.modal-backdrop').remove();
            $(document.body).removeClass("modal-open");            
        }
        else{
            //alertify.error(data.alertMessage);
            this._validations.html(data.viewContent);
            //this._btnSave.attr("disabled", "true");
        }
        this.afterSubmitSuccess(data);
    }    

    public afterSubmitSuccess(result: SaopWfSubmitResult): void {
        global.ConsoleLogDegug("SaopUploadFiles.afterExecuteSuccess");
    }        

    ajaxValidate():boolean{
        if (this._filesCount <= 0) { return; }
        if ((this._params.validateAjax == null) || (this._params.validateAjax == false) || isEmpty(this._params.validationDivId) || isEmpty(this._params.formId)) {
            return;
        }
        let _this = this;
        let _result = true;
        this._validations = $(this._params.validationDivId);  

        let _formE = $(this._params.formId)[0];
        let _form = (<HTMLFormElement>_formE);
        var formData = new FormData(_form);

        $.ajax({
            url: this._siteUrl+"/"+this._params.action,
            type: 'POST',
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            processData: false,
            success: function(data: SaopWfSubmitResult) {
                global.ConsoleLogDegug("success");
                if (data.success == false) {
                    _this._validations.html(data.viewContent);                
                    _this._validations.focus();
                    _result = false;
                }                 
            }
          });        
          return _result;
    }

    //
}






