﻿/// <reference path="../@types/alertify/index.d.ts" />
import { SaopAbsenceConfirmationPendingView } from "../AbsenceConfirmation/saop-absenceConfirmation-pending-view";
import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";

export class DashboardAbsenceConfirmationView {
    private _libName:string;
    private _saopServicesCore: SaopServicesCore;
    private absenceConfirmationPendingView: SaopAbsenceConfirmationPendingView;
    //

    constructor(libName: string = "") {
        this._libName = libName;
        //
        this._saopServicesCore = new SaopServicesCore();
        this.absenceConfirmationPendingView = new SaopAbsenceConfirmationPendingView("", libName +".absenceConfirmationPendingView");
        this.absenceConfirmationPendingView.refreshDataExternal = this.refreshGridData.bind(this);
    }

    refreshGridData(): void {
        global.ConsoleLogDegug("DashboardAbsenceConfirmationView.refreshGridData");
        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            $("#card3 .card-body").html(response);
            _this.absenceConfirmationPendingView.bindGridEvents();
        };
        this._saopServicesCore.ExecuteAction({
            action:"Dashboard/AbsenceConfirmationPartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingcard3"
        });   
    }    
   
    //
}


