﻿
/// <reference path="../@types/bootstrap-datetimepicker/index.d.ts" />
import { convertString2Date, global,isEmpty } from "../Common/saop-common";

export class SaopInputMask {

    init() {
        global.ConsoleLogDegug("SaopInputMask.init...");
        var inputDivs = document.querySelectorAll<HTMLElement>('input[data-inputmask-alias]');
        Inputmask().mask(inputDivs);

        $(".saop-decimal-2").inputmask({
            'alias': 'decimal',
            'radixPoint': ',',
            'digits': 2,
            'digitsOptional': false,
            'placeholder': '0,00'
        });

        $(".saop-decimal-3").inputmask({
            'alias': 'decimal',
            'radixPoint': ',',
            'digits': 3,
            'digitsOptional': false,
            'placeholder': '0,000'
        });

        inputDivs.forEach(element => {
            global.ConsoleLogDegug("element.init......................" + element.id);

            let _this = this;
            let spanDiv:any;
            let elementAtrr = $(element).attr("data-inputmask-alias");
            if (isEmpty(elementAtrr)) {return;};
            global.ConsoleLogDegug(elementAtrr);
            spanDiv = $(element).parent();
            if (spanDiv === null) { return;};
            if (spanDiv.hasClass("has-float-label")===false) { return;};
            global.ConsoleLogDegug(spanDiv.get(0).id);

            let languageAtrr = (String)($("#siteLanguage").val());
            global.ConsoleLogDegug("language:"+languageAtrr);
            languageAtrr = isEmpty(languageAtrr) ? "sl" :languageAtrr;
            global.ConsoleLogDegug("language:"+languageAtrr);

            if (elementAtrr === "hh:mm:ss") {
                let iconBtn = $(spanDiv).find("#timeIcon");
                global.ConsoleLogDegug("clock...");

                global.ConsoleLogDegug("clock.focus.init:" + element.id);
                $(element).focus(function (event: any) {
                    global.ConsoleLogDegug("call.clock.focus:" + event.target.id);

                    // ce je bil fokusiran z tab, damo mali timeout, ker drugace ne dela
                    let timeOut = 1;
                    if (_this.wasTabPressed(event)) {
                        timeOut = 200;
                    }

                    setTimeout(function () {
                        let inputCtrl: HTMLInputElement = event.target;
                        _this.inputSelectAllText(inputCtrl);
                    }, timeOut);
                });

                global.ConsoleLogDegug("clock.blur.init:" + element.id);
                $(element).blur(function (event: any) {
                    global.ConsoleLogDegug("call.clock.blur:" + event.target.id);
                    var controlValue = event.target.value;
                    if (controlValue.includes("h")) {
                        event.target.value = "";
                        return;
                    }
                    controlValue = controlValue.replace("mm", "00");
                    controlValue = controlValue.replace("m", "0");
                    controlValue = controlValue.replace("ss", "00");
                    controlValue = controlValue.replace("s", "0");
                    event.target.value = controlValue;
                    // prozimo input even, ker smo vpisali novo vrednost 
                    const e = new Event("input");
                    event.target.dispatchEvent(e);
                });

                global.ConsoleLogDegug("clock.click.init:" + element.id);
                $(iconBtn).click(function (event: any) {
                    global.ConsoleLogDegug("clockIcon.clicked:" + element.id);
                    var ctrlInputCtrl = $(element).get(0) as HTMLInputElement;
                    ctrlInputCtrl.focus();
                });
            } else if (elementAtrr === "dd.mm.yyyy"
                // || elementAtrr === "dd.mm.yyyy hh:mm" 
                // || elementAtrr === "dd.mm.yyyy hh:mm:ss"
            ) {

                let iconBtn = $(spanDiv).find("#calendarIcon");

                global.ConsoleLogDegug("calendar...");

                let datepickerStartDate = new Date();
                if ($(element).attr("data-enable-select-pastdates") != undefined) {
                    if (($(element).attr("data-enable-select-pastdates")).toLowerCase() == 'true') {
                        datepickerStartDate = null;
                    }
                }

                if ($(element).attr("data-min-start-date") != undefined) {
                    var datepickerStartDate1 = $(element).attr("data-min-start-date");
                    datepickerStartDate = convertString2Date(datepickerStartDate1, "dd.mm.yyyy");
                }

                let datepickerEndDate = null;
                if ($(element).attr("data-max-end-date") != undefined) {
                    datepickerEndDate = $(element).attr("data-max-end-date");
                    datepickerEndDate = convertString2Date(datepickerEndDate, "dd.mm.yyyy");
                }

                $(element).datepicker({
                    startDate: datepickerStartDate,
                    endDate: datepickerEndDate,
                    autoclose: true,
                    keyboardNavigation: false,
                    weekStart: 1,
                    format: "dd.mm.yyyy",
                    language: languageAtrr,
                    orientation: "bottom right",
                    showOnFocus:false
                    //forceParse:false,
                });

                $(element).focus(function (event: any) {
                    global.ConsoleLogDegug("calendar...focus:" + event.target.id);

                    // ce je bil fokusiran z tab, damo mali timeout, ker drugace ne dela
                    let timeOut = 1;
                    if (_this.wasTabPressed(event)) {
                        timeOut = 200;
                    }

                    setTimeout(function () {
                        let inputCtrl: HTMLInputElement = event.target;
                        _this.inputSelectAllText(inputCtrl);
                    }, timeOut);
                });

                $(iconBtn).click(function()
                {
                    global.ConsoleLogDegug("calendar...click");
                    var datepickerInputCtrl = $(element).get(0) as HTMLInputElement;
                    //datepickerInputCtrl.focus();
                    $(element).datepicker("show");
                    _this.inputSelectAllText(datepickerInputCtrl);
                });

            } else if (elementAtrr === "dd.mm.yyyy hh:mm") {
                global.ConsoleLogDegug("datetimepicker hh:mm ...");

                let iconBtn = $(spanDiv).find("#calendarIcon");
                //
                $(element)
                    .bind('keydown',function(e){

                        e.stopImmediatePropagation();
                    })
                    .datetimepicker({
                        format: 'DD.MM.YYYY H:mm',
                        locale: languageAtrr,
                        showTodayButton: true,
                    });

                $(element).on("dp.change", function (e) {
                    $(this).data("DateTimePicker").hide();
                    $(element).focus();
                    //
                    var datepickerInputCtrl = $(element).get(0) as HTMLInputElement;
                    datepickerInputCtrl.setSelectionRange(11,16,"forward");
                });

                $(element).off("focus");

                $(iconBtn).click(function()
                {
                    $(element).data("DateTimePicker").show();
                });

            } else if (elementAtrr === "dd.mm.yyyy hh:mm:ss") {
                global.ConsoleLogDegug("datetimepicker hh:mm:ss ...");

                let iconBtn = $(spanDiv).find("#calendarIcon");
                //
                $(element)
                    .bind('keydown',function(e){

                        e.stopImmediatePropagation();
                    })
                    .datetimepicker({
                        format: 'DD.MM.YYYY H:mm:ss',
                        locale: languageAtrr,
                        showTodayButton: true
                    });

                $(element).off("focus");

                // $(element).on("dp.change", function (e) {
                //     $(this).data("DateTimePicker").hide();
                //     //
                //     $(element).focus();
                //     var datepickerInputCtrl = $(element).get(0) as HTMLInputElement;
                //     var datepickerInputCtrlStrLength = datepickerInputCtrl.value.length;
                //     datepickerInputCtrl.setSelectionRange(11,3);                    
                // });                  

                $(element).click(function()
                {
                    $(element).data("DateTimePicker").hide();
                });

                //$(element).off("keydown");
                // .on('dp.hide', function(e){
                //     console.log('dp.hide');
                //   })   
                // .on('dp.show', function(e){
                //     console.log('dp.show');
                // });                
                //
                $(iconBtn).click(function()
                {
                    // global.ConsoleLogDegug("calendar...click");
                    // if ($(document).find(".datepicker-dropdown").is(":visible")) {
                    $(element).data("DateTimePicker").show();
                });

                // $(element).focus(function (event: any) {
                //     $(element).data("DateTimePicker").hide();                 
                // });                
            } else {
                console.log("not implemented...");
            }

        });

        this.initMultiDateSelectDatepicker();

        global.ConsoleLogDegug("initInputs...done");
    }

    private initMultiDateSelectDatepicker(): void {
        global.ConsoleLogDegug("SaopInputMask.initMultiDateSelectDatepicker...");

        var inputDivs = document.querySelectorAll<HTMLElement>('input[data-izbor-vec-dni]');

        inputDivs.forEach(element => {
            global.ConsoleLogDegug("element.init......................" + element.id);

            let spanDiv: any;
            spanDiv = $(element).parent();
            if (spanDiv === null) { return; };
            if (spanDiv.hasClass("has-float-label") === false) { return; };
            global.ConsoleLogDegug(spanDiv.get(0).id);

            let languageAtrr = (String)($("#siteLanguage").val());
            global.ConsoleLogDegug("language:" + languageAtrr);
            languageAtrr = isEmpty(languageAtrr) ? "sl" : languageAtrr;
            global.ConsoleLogDegug("language:" + languageAtrr);

            let iconBtn = $(spanDiv).find("#calendarIcon");

            global.ConsoleLogDegug("calendar...");

            let datepickerStartDate = new Date();
            if ($(element).attr("data-enable-select-pastdates") != undefined) {
                if (($(element).attr("data-enable-select-pastdates")).toLowerCase() == 'true') {
                    datepickerStartDate = null;
                }
            }

            if ($(element).attr("data-min-start-date") != undefined) {
                var datepickerStartDate1 = $(element).attr("data-min-start-date");
                datepickerStartDate = convertString2Date(datepickerStartDate1, "dd.mm.yyyy");
            }

            let datepickerEndDate = null;
            if ($(element).attr("data-max-end-date") != undefined) {
                datepickerEndDate = $(element).attr("data-max-end-date");
                datepickerEndDate = convertString2Date(datepickerEndDate, "dd.mm.yyyy");
            }

            $(element).datepicker({
                startDate: datepickerStartDate,
                endDate: datepickerEndDate,
                autoclose: false,
                multidate: true,
                keyboardNavigation: false,
                weekStart: 1,
                format: "dd.mm.yyyy",
                language: languageAtrr,
                orientation: "bottom right"
            });

            $(iconBtn).click(function () {
                global.ConsoleLogDegug("calendar...click");
                var datepickerInputCtrl = $(element).get(0) as HTMLInputElement;
                var datepickerInputCtrlStrLength = datepickerInputCtrl.value.length * 2;
                datepickerInputCtrl.focus();
                datepickerInputCtrl.setSelectionRange(datepickerInputCtrlStrLength, datepickerInputCtrlStrLength)
            });

        });

        global.ConsoleLogDegug("SaopInputMask.initMultiDateSelectDatepicker...done");
    }

    private inputSelectAllText(inputCtrl: HTMLInputElement): void {
        global.ConsoleLogDegug("SaopInputMask.inputSelectAllText...");

        inputCtrl.setSelectionRange(0, inputCtrl.value.length);
    }

    private wasTabPressed(event: any): boolean {
        global.ConsoleLogDegug("SaopInputMask.wasTabPressed...");

        let result = false;

        let code = (event.keyCode ? event.keyCode : event.which);
        if (code == 9) {
            result = true;
        }

        return result;
    }
}





