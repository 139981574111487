﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { DashboardTodayEventsView } from './saop-dashboard-todayEvents-view';
import { DashboardAbsencesView } from './saop-dashboard-absences-view';
import { DashboardAbsenceConfirmationView } from './saop-dashboard-absence-confirmation-view';
import { SaopRightSidebar, SaopRightSidebarView } from '../Components/saop-right-sidebar';
import { DashboardTravelOrderView } from './saop-dashboard-travelOrder-view';
import { DashboardInternalOrderView } from "./saop-dashboard-internalOrder-view";
import { DashboardMyGradesView } from "./saop-dashboard-myGrades-view";
import { DashboardLIKVConfirmationView } from "./saop-dashboard-LIKConfirmation-view";
//import { DashboardEmployeePresenceView } from "./saop-dashboard-employeePresence-view";

export class SaopDashboardView {
    private _libName:string;
    private _card1:JQuery<HTMLElement>;
    private _card2: JQuery<HTMLElement>;
    private _card3: JQuery<HTMLElement>;
    private _card4: JQuery<HTMLElement>;
    private _card5: JQuery<HTMLElement>;
    private _card6: JQuery<HTMLElement>;
    private _card7: JQuery<HTMLElement>;
    //private _card8: JQuery<HTMLElement>;

    //
    public dashboardTodayEvents:DashboardTodayEventsView;
    public dashboardAbsences: DashboardAbsencesView;
    public dashboardAbsenceConfirmationView: DashboardAbsenceConfirmationView;
    public dashboardTravelOrderView: DashboardTravelOrderView;
    public dashboardInternalOrderView: DashboardInternalOrderView;
    public dashboardMyGradesView: DashboardMyGradesView;
    public dashboardLIKVConfirmationView: DashboardLIKVConfirmationView;
    //public dashboardEmployeePresenceView: DashboardEmployeePresenceView;

    constructor(libName: string = "") {
        this._libName = libName;
        //
        this.dashboardTodayEvents = new DashboardTodayEventsView(this._libName + ".dashboardTodayEvents");
        this.dashboardAbsences = new DashboardAbsencesView(this._libName + ".dashboardAbsences");
        this.dashboardAbsenceConfirmationView = new DashboardAbsenceConfirmationView(this._libName + ".dashboardAbsenceConfirmationView");
        this.dashboardTravelOrderView = new DashboardTravelOrderView(this._libName + ".dashboardTravelOrderView");
        this.dashboardInternalOrderView = new DashboardInternalOrderView(this._libName + ".dashboardInternalOrderView");
        this.dashboardMyGradesView = new DashboardMyGradesView(this._libName + ".dashboardMyGradesView");
        this.dashboardLIKVConfirmationView = new DashboardLIKVConfirmationView(this._libName + ".dashboardLIKVConfirmationView");
        //this.dashboardEmployeePresenceView = new DashboardEmployeePresenceView(this._libName + ".dashboardEmployeeAttendanceView");
    }

    initDashboardView(): void {
        global.ConsoleLogDegug("initDashboardView");
        this._card1 = $("#card1");
        this._card2 = $("#card2");
        this._card3 = $("#card3");
        this._card4 = $("#card4");
        this._card5 = $("#card5");
        this._card6 = $("#card6");       
        this._card7 = $("#card7"); 
        //this._card8 = $("#card8");

        $("#site-main").addClass("site-main-with-formSidebarr");

        let _rightSidebar: SaopRightSidebar = new SaopRightSidebar();
        _rightSidebar.bindCarouselDiv("#right-sidebar-ajax", SaopRightSidebarView.Dashboard);
    }

    refreshAllData(): void {
        global.ConsoleLogDegug("SaopDashboardView.refreshAllData");
        if (this._card1.length){
            this.dashboardTodayEvents.refreshGridData();
        }
        if (this._card2.length){
            this.dashboardAbsences.refreshGridData();
        }
        if (this._card3.length) {
            this.dashboardAbsenceConfirmationView.refreshGridData();
        }
        if (this._card4.length) {
            this.dashboardInternalOrderView.refreshGridData();
        }

        if (this._card5.length) {
            this.dashboardMyGradesView.refreshGridData();
        }

        if (this._card6.length) {
            this.dashboardLIKVConfirmationView.refreshGridData();
        }       

        if (this._card7.length) {
            this.dashboardTravelOrderView.refreshGridData();
        }        

        //if (this._card8.length) {
        //    this.dashboardEmployeePresenceView.refreshGridData();
        //}        
    }

    //
}


