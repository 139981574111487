﻿import { global } from "../Common/saop-common";

export class SaopTogglePanel {

    init():void{
        global.ConsoleLogDegug("SaopTogglePanel.init...");
        this.setClickEventOnFrame("body");
    }

    setClickEventOnFrame(frameName:string = "body"):void {
        global.ConsoleLogDegug("SaopTogglePanel.setClickEventOnFrame...");

        let _frame = $(""+frameName+" *> .saop-toggle-panel");
        _frame.off("click");
        _frame.click(function () {
            //na klik se puščica obrne gor / dol
            var arrowDiv = $(this).find(".saop-toggle-arrow");

            for (var i = 0; i < arrowDiv.length; i++) {
                if (arrowDiv[i].classList.contains("saop-img-down")) {
                    arrowDiv[i].classList.remove("saop-img-down");
                    arrowDiv[i].classList.add("saop-img-up");
                } else {
                    arrowDiv[i].classList.remove("saop-img-up");
                    arrowDiv[i].classList.add("saop-img-down");
                }
            }
        });
    }

    //
}






