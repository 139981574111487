﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopRightSidebar } from '../Components/saop-right-sidebar';
import { NDKInternalOrdersActiveGrid, NDKInternalOrdersActiveGridOptions } from "../NDK/NDKInternalOrders-Active-grid";

export class DashboardInternalOrderView {
    private _libName:string;
    private _saopServicesCore: SaopServicesCore;
    private _addInternalOrderId: string = "#addInternalOrder";
    //
    public ndkInternalOrdersActiveGrid: NDKInternalOrdersActiveGrid;
    public rightSidebar: SaopRightSidebar;

    constructor(libName: string = "") {
        this._libName = libName;
        //
        this._saopServicesCore = new SaopServicesCore();

        let gridOptions = new NDKInternalOrdersActiveGridOptions();
        gridOptions.dashboard = true;
        gridOptions.showToolbar = false;
        gridOptions.showFilter = false;
        this.ndkInternalOrdersActiveGrid = new NDKInternalOrdersActiveGrid(this._libName + ".ndkInternalOrdersActiveGrid", gridOptions);
        this.ndkInternalOrdersActiveGrid.afterRefreshDataAfterEditViewExternal = this.afterRefreshDataAfterEditView.bind(this);

        this.rightSidebar = new SaopRightSidebar();
    }

    refreshGridData(): void {
        global.ConsoleLogDegug("DashboardInternalOrderView.refreshGridData");

        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            $("#card4 .card-body").html(response);
            _this.ndkInternalOrdersActiveGrid.initView();
            _this.bindAddEvent();
        };
        this._saopServicesCore.ExecuteAction({
            action:"Dashboard/InternalOrderPartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingcard4"
        });

        this.rightSidebar.refreshNdkInternalOrdersSummary("#ndkInternalOrdersSummary", "#saopLoadingSidebarr");
    }  

    afterRefreshDataAfterEditView(): void {
        global.ConsoleLogDegug("DashboardInternalOrderView.afterRefreshDataAfterEditView");

        this.rightSidebar.refreshNdkInternalOrdersSummary("#ndkInternalOrdersSummary", "#saopLoadingSidebarr");
    }

    bindAddEvent() {
        global.ConsoleLogDegug("DashboardInternalOrderView.bindGridEvents");
        let _this = this;

        $(this._addInternalOrderId).off("click");
        $(this._addInternalOrderId).on('click', function (row) {
            global.ConsoleLogDegug("DashboardInternalOrderView._addInternalOrder.click");
            _this.ndkInternalOrdersActiveGrid.addRecord();
        });
    }
   
    //
}


