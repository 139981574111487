﻿/// <reference path="../@types/alertify/index.d.ts" />
import { filterTableRows, global, isUnDefined } from "../Common/saop-common";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import { SaopServicesCore } from "../Core/saop-services-core";
import { KEGradePeriodView } from "./KEGradePeriod-view";
import { SaopTranslationService } from "../Core/saop-translate";
import { KEAllEmployeesGradesView } from "./KEAllEmployeesGrades-view";

class GradesPeriodData {
    sifraOcenObdobja: string;
    isStatusActive: boolean;
    fingerprint: string;
    formTitle: string;
}

class GradesViewSenderData {
    alertMessage: string;
    redirectTo: string;
    selectedSifraOcenObdobja: string;
    success: boolean;
    viewContent: string;
}

export class KEGradesPeriodGridOptions {
    showToolbar: boolean = true;
    showFilter: boolean = true;
}

export class KEGradesPeriodGrid {
    private libName: string;
    private _gridOptions: KEGradesPeriodGridOptions;
    //
    private divId: string = "#gradesPeriodDiv";
    private webGridId: string = "#gradesPeriodGrid";
    private webGridAktivnaRowsBodyId: string = "#gradesPeriodGridBodyAjax";
    public webGrid: SaopWebGrid;
    public keGradePeriodView: KEGradePeriodView;
    public keAllEmployeesGradesView: KEAllEmployeesGradesView;
    public formTranslationService: SaopTranslationService;
    private _searchValueId: string = "#GradesPeriodSearchValue";
    private _mainFormDiv: JQuery<HTMLElement>;
    private _searchValue: JQuery<HTMLElement>;

    constructor(libName: string, gridOptions: KEGradesPeriodGridOptions = null) {
        if (isUnDefined(gridOptions)) {
            gridOptions = new KEGradesPeriodGridOptions();
        }
        this._gridOptions = gridOptions;

        this.libName = libName;  
        this.formTranslationService = new SaopTranslationService();
    }  

    initView() {
        global.ConsoleLogDegug("KEGradesPeriodGrid.initView");

        this._mainFormDiv = $(this.divId);

        // prevajanje
        this.setFormTerminologyList();

        // refresh grid data
        this.refreshGradesPeriodGridData();

        // init edit view
        this.initEditView();
    }

    private setFormTerminologyList() {
        let terminology = ["Zapri"];

        this.formTranslationService.setTranslationsList(terminology);
    }     

    initEditView(): void {
        global.ConsoleLogDegug("KEGradesPeriodGrid.initEditView");

        this.keGradePeriodView = new KEGradePeriodView("#keGradesAdministrationView001", this.libName + ".keGradePeriodView");
        this.keGradePeriodView.fullScreen = true;
        this.keGradePeriodView.refreshData = this.refreshDataAfterEditView.bind(this);

        this.keAllEmployeesGradesView = new KEAllEmployeesGradesView("#keGradesAdministrationView002", this.libName + ".keAllEmployeesGradesView")
        this.keAllEmployeesGradesView.fullScreen = true;
    }

    refreshDataAfterEditView(sender: GradesViewSenderData): void { 
        global.ConsoleLogDegug("KEGradesPeriodGrid.refreshDataAfterEditView");

        // osvezi se tabela
        this.refreshGradesPeriodGridData(true, sender.selectedSifraOcenObdobja);
        // klice se external metoda, da se lahko osvezijo se drugi podatki npr. right summary data
        this.afterRefreshDataAfterEditViewExternal(sender);
    }

    public afterRefreshDataAfterEditViewExternal(sender: any): void {
        global.ConsoleLogDegug("KEGradesPeriodGrid.afterRefreshDataAfterEditViewExternal");
    }

    refreshGradesPeriodGridData(samoVrstice: boolean = false, selectedSifraOcenObdobja: string = ""): void {
        global.ConsoleLogDegug("KEGradesPeriodGrid.refreshGradesPeriodGridData");

        let _this = this;
        let params = { samoVrstice: samoVrstice, selectedSifraOcenObdobja: selectedSifraOcenObdobja };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.refreshGridData(response);
        };
        saopServicesCore.ExecuteAction({
            action:"KeGradesAdministration/GetGradesPeriodTable" 
            ,data:params
            ,type:"GET"
        });     
    }

    refreshGridData(htmlContent: string) {
        global.ConsoleLogDegug("KEGradesPeriodGrid.refreshGridData");

        if ($(this.webGridId).length) {
            $(this.webGridAktivnaRowsBodyId).html(htmlContent);
            this.initWebGridListners();
            if (!isUnDefined(this._searchValue) && this._searchValue.length) {
                this.executeFilterSearch(this._searchValue.val().toString());
            }
        }
        else {
            $(this.divId).html(htmlContent);
            this.getWebGridToolbarAndInitWebGrid(); 
        }
    }

    getWebGridToolbarAndInitWebGrid(): void {
        global.ConsoleLogDegug("KEGradesPeriodGrid.getWebGridToolbarAndInitWebGrid");

        let _this = this;
        let params = { javascriptKnjiznica: _this.libName };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.initWebGrid(response);
            _this.initFilterControls();  
        };
        saopServicesCore.ExecuteAction({
            action: "KeGradesAdministration/GetTableToolbar"
            , data: params
            , type: "GET"
        });
    }

    initWebGrid(toolbarTemplate: string = "") {
        global.ConsoleLogDegug("KEGradesPeriodGrid.initWebGrid");

        let captionToolbar = $(this.webGridId).attr("caption-toolbar");
        //
        this.webGrid = new SaopWebGrid(this.libName + ".webGrid");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar: SaopWebGridToolbar;
        let toolbarButtons: SaopWebGridToolbarButton[] = [];
        let dataTable: SaopWebDataTable;
        //
        if (this._gridOptions.showToolbar) {
            toolbarButtons.push({ id: "", caption: "", template: toolbarTemplate });
            gridToolbar = { id: "saopWebGridTB1", caption: captionToolbar, buttons: toolbarButtons };
        }

        dataTable = { enabled: true, ordering: true};

        gridProperties = {
            gridId: this.webGridId
            , togglePanel: { enabled: false }
            , toolbar: gridToolbar
            , dataTable: dataTable
        };
        //
        this.webGrid.init(gridProperties);

        this.initWebGridListners();

        $(this.divId).show();

        //this.setDashboardSpecificView();
    }

    initWebGridListners() {
        global.ConsoleLogDegug("KEGradesPeriodGrid.initWebGridListners");

        let _this = this;

        // prikaz full screen ko klikne na vrstico
        $("body").off("click", this.webGridId + " tr td:not(.td-action,.td-action-checkbox)");
        $("body").on("click", this.webGridId + " tr td:not(.td-action,.td-action-checkbox)", function () {
            let selectedRow = <HTMLElement>$(this).closest("tr")[0];
            let data = _this.getGradePeriodDataAttribute(selectedRow);

            // barvanje oznacene vrstrice
            _this.webGrid.markSelectedRow(selectedRow);

            if (!isUnDefined(data.sifraOcenObdobja)) {
                _this.showRecordDetails({ sifraOcenObdobja: data.sifraOcenObdobja, isStatusActive: data.isStatusActive, fingerprint: data.fingerprint }, data.formTitle);
            }
        });

        // prikaz ocen
        //če se naredi z "$("body").off("click", this.webGridId + " tr .td-action .btnVsiZaposleniPrikazOcen");"
        // so težave, ker se event 2x izvede (verjetno, ker je narejeno z gumbom in ne z div in ikono v njem)
        $(this.webGridId + " tr .td-action .btnVsiZaposleniPrikazOcen").off("click");
        $(this.webGridId + " tr .td-action .btnVsiZaposleniPrikazOcen").on('click', function (row) {
            let selectedRow = <HTMLElement>$(this).closest("tr")[0];
            let data = _this.getGradePeriodDataAttribute(selectedRow);

            // barvanje oznacene vrstrice
            _this.webGrid.markSelectedRow(selectedRow);

            let baseTitle = $(this).attr("data-editocenetitle");

            if (!isUnDefined(data.sifraOcenObdobja)) {
                let fullTitle = baseTitle + ": " + data.formTitle;
                _this.showAllEmployeesGrades({ sifraOcenObdobja: data.sifraOcenObdobja, isStatusActive: data.isStatusActive, fingerprint: data.fingerprint }, fullTitle);
            }
        });   

    }

    initFilterControls(): void {
        global.ConsoleLogDegug("KEGradesPeriodGrid.initFilterControls");

        this._searchValue = this._mainFormDiv.find(this._searchValueId);
        let _this = this;

        this._searchValue.off("keyup");
        this._searchValue.on("keyup", function () {
            _this.executeFilterSearch($(this).val().toString());
        });
    }

    executeFilterSearch(searchVal: string): void {
        global.ConsoleLogDegug("KEGradesPeriodGrid.executeFilterSearch");

        // tole ni delovalo: ko se je spremenilo leto, je se vedno filtriral stare podatke od prejsnjega leta
        //let oTable = this.webGrid.dataTable;
        //oTable.search(searchVal).draw();

        filterTableRows(searchVal, this.divId);
    }

    getGradePeriodDataAttribute(element: HTMLElement): GradesPeriodData {
        global.ConsoleLogDegug("KEGradesPeriodGrid.getGradePeriodDataAttribute");

        let data = new GradesPeriodData();

        data.sifraOcenObdobja = element.getAttribute("data-sifraocenobdobja");
        data.fingerprint = element.getAttribute("data-fingerprint");
        data.formTitle = element.getAttribute("data-formtitle");
        let isStatusActive = element.getAttribute("data-isstatusactive");
        data.isStatusActive = isStatusActive === "True" ? true : false;

        return data;
    }

    showRecordDetails(detailsData: any, title: string): void {
        global.ConsoleLogDegug("KEGradesPeriodGrid.showRecordDetails");

        this.keGradePeriodView.showForm(detailsData, title);
    }

    showAllEmployeesGrades(data: any, fullTitle: string): void {
        global.ConsoleLogDegug("KEGradesPeriodGrid.showAllEmployeesGrades");

        this.keAllEmployeesGradesView.showForm(data, fullTitle);
    }
}






