﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopAbsenceConfirmationPendingView } from "./saop-absenceConfirmation-pending-view";
import { SaopAbsenceConfirmationProcessedView } from "./saop-absenceConfirmation-processed-view";

export class SaopAbsenceConfirmationView {
    private _libName:string;
    
    public saopAbsenceConfirmationPendingView: SaopAbsenceConfirmationPendingView;
    public saopAbsenceConfirmationProcessedView: SaopAbsenceConfirmationProcessedView;

    constructor(viewId: string, libName: string = "") {
        this._libName = libName;
        //
        this.saopAbsenceConfirmationPendingView = new SaopAbsenceConfirmationPendingView(viewId, this._libName + ".saopAbsenceConfirmationPendingView")
        this.saopAbsenceConfirmationPendingView.refreshDataExternal = this.refreshAllData.bind(this);
        this.saopAbsenceConfirmationProcessedView = new SaopAbsenceConfirmationProcessedView(viewId, this._libName + ".saopAbsenceConfirmationProcessedView")
    }

    initView(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationView.initView");

        // form nima sidebar right, zato se dodan class, da btn ni viden tudi v mobile verziji
        $("#btnShowSidebarr").addClass("d-none-mobile");
    }

    // se uporabi v init metodi v AbsenceConfirmation index cshtml datoteki
    refreshAllData(): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationView.refreshAllData");
        this.saopAbsenceConfirmationPendingView.refreshBaseData();
        this.saopAbsenceConfirmationProcessedView.refreshData();
    }
}


