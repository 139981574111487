﻿import { setModalFormZIndex } from "../Common/saop-common";

export class SaopPopupDialogOptions {
    title: string;
    body: string;
    showCancelButton: boolean;
    confirmButtonCaption: string;
    cancelButtonCaption: string;
    confirmAction: string;
    confirmActiondata: object;
    onSuccess: (params:any) => any;
    constructor() {
        this.title = "";
        this.body = "body text";
        this.showCancelButton = true;
        this.confirmButtonCaption = "Ok";
        this.cancelButtonCaption = "Cancel";
        this.confirmAction = "";
        this.confirmActiondata = {};
        this.onSuccess = null;
    }
}

export class SaopPopupDialog {
    private _options:SaopPopupDialogOptions;


    constructor(options:SaopPopupDialogOptions) {
        this._options = options;
    }    
    
    save(callback: (n: number) => any) : void {
        callback(42);
    }

    ShowLoadingSaop() {
        var obj = $("#saopLoading");
        if (obj.length)
            obj.show();
    }

    HideLoadingSaop() {
        var obj = $("#saopLoading");
        if (obj.length)
            obj.hide();
    }    

    ShowPopupDialog():void {
        let _this = this;
        // Display info message to the user in a modal
        let titleDiv = $('#info-modal-title-div');
        let titleH = $('#info-modal-title');
        let confirmButton = $('#info-modal-btn-ok');
        let cancelButton = $('#info-modal-btn-cancel');

        cancelButton.html(this._options.cancelButtonCaption);

        // show or hide header div based on title property
        titleDiv.css("display", "");
        if (this._options.title) {
            titleH.html(this._options.title);
        }
        else {
            titleDiv.css("display", "none");
        }

        if (this._options.confirmButtonCaption){
            confirmButton.html(this._options.confirmButtonCaption);
            confirmButton.css("display", "");            
        } else {
            confirmButton.css("display", "none");
        }

        if (this._options.showCancelButton) {
            cancelButton.html(this._options.cancelButtonCaption);
            cancelButton.css("display", "");            
        } else {
            cancelButton.css("display", "none");
        }

        $('#info-modal-body').html(this._options.body);

        $("#info-modal-btn-ok").click(
            function () {
                _this.popupDialogConfirm();
            }
        );

        $('#info-modal').on('hidden.bs.modal', function (e) {
            $("#info-modal-btn-ok").unbind("click");
            $(this).modal('dispose');
        })

        $('#info-modal').modal('show');
        setModalFormZIndex('#info-modal');
    }    

    popupDialogConfirm() {
        let _this = this;
        let cancelButton = $('#info-modal-btn-cancel');

        if (this._options.confirmAction !== "") {
            $.ajax({
                url: this._options.confirmAction,
                type: "POST",
                data: this._options.confirmActiondata,
                beforeSend: function () { _this.ShowLoadingSaop(); },
                complete: function () { _this.HideLoadingSaop(); },
                success: function (result) {
                    // close dialog
                    cancelButton.trigger("click");

                    if(_this._options.onSuccess !== null){
                        _this._options.onSuccess(result);
                    }
                        
                },
                error: function (response) {
                    // close dialog
                    cancelButton.trigger("click");
                    alert(response.responseJSON.message);
                }
            });
        }
        else {
            //close dialog
            cancelButton.trigger("click");

            if (this._options.onSuccess != null) {
                this._options.onSuccess(null);
            }
        }
    }

    //
}





