﻿import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopViewForm,SaopWfAction } from "../Core/saop-view-form";
 import { SaopGoogleMaps }  from "../Components/saop-googlemaps";

export class OPNPotniNalogiGoggleMapsView extends SaopViewForm {
  private _parentViewId:string;
  //private _saopGoogleMaps:SaopGoogleMaps;

  constructor(viewId:string,libName:string = ""){
    global.ConsoleLogDegug("OPNPotniNalogiGoggleMapsView");
    super(viewId,libName);
    this.setDictionary();   
    this.addRecordEndpoint = "";
    this.editRecordEndpoint = "TravelOrder/GoogleMaps";
    this.deleteRecordEndpoint = "";   
    this.fullScreen = true;
    //
    //this._saopGoogleMaps = new SaopGoogleMaps();
    //
    this.onBtnOkClick = this.onBtnOkClickEx.bind(this);
    //this.viewShowComplete = this.initForm.bind(this);  
  }

  private setDictionary() {
    this.setTerminologyList();
  }  

  private initForm(): void {
    global.ConsoleLogDegug("OPNPotniNalogiGoggleMapsView.initForm");
    // this._saopGoogleMaps = new SaopGoogleMaps();
    // this._saopGoogleMaps.initView();
    this.loadRoutesList();
  }

  prepareView(parentViewId:string, title: string): void {
    global.ConsoleLogDegug("prepareView.showForm");
    this._parentViewId = parentViewId;
    this.title = title;
    let _googleMapsRoutesElement = $('#GoogleMapsRoutes');
    if (_googleMapsRoutesElement.length == 0){
      this.prepareViewForm(SaopWfAction.wfaRecordDestails, null, null);
    }
  }

  showForm(parentViewId:string): void {
    global.ConsoleLogDegug("OPNPotniNalogiGoggleMapsView.showForm");
    this._parentViewId = parentViewId;
    $('#opnPotniNalogiView003').modal('show');
    $('#opnPotniNalogiView003').find('#messageBanner').hide();
    this.initForm();
  }

  public loadRoutesList(): void {
    let _routesList = $(this._parentViewId).find('#DodatniOpisRelacije');
    let _routesListString = _routesList.val() as string;
    $('#GoogleMapsRoutes').find('#RoutesList').val(_routesListString);

    $('#GoogleMapsRoutes').find('#btnRoutesList').click();
    // let saopGoogleMaps = new SaopGoogleMaps(this.id);
    // if (_routesListString.length > 0) {
    //   let _routesArray = _routesListString.split(";");
    //   saopGoogleMaps.loadWaypoints(_routesArray);
    // } else {
    //   saopGoogleMaps.loadWaypoints(null);
    // }
  }

  public onBtnOkClickEx(sender: any): void {
    global.ConsoleLogDegug("OPNPotniNalogiGoggleMapsView.onBtnOkClickEx");
    let saopGoogleMaps = new SaopGoogleMaps(this.id);

    let _routesListString = saopGoogleMaps.getRoutes();

    //let _routesList = $(this._parentViewId).find('#DodatniOpisRelacije');
    let _routesList = $('#DodatniOpisRelacije');
     _routesList.val(_routesListString);
    //
     let _distanceString =  saopGoogleMaps.getDistance();
     //$(this._parentViewId).find('#PredvideniKm').val(_distanceString);
     $('#PredvideniKm').val(_distanceString);
  }  
  //
}






