﻿import { global, setModalFormZIndex,isEmpty,isUnDefined } from "../Common/saop-common";

export enum SaopMsgDlgType {
    mtInformation = 1
    ,mtWarning = 2
    ,mtError = 3
    //, mtConfirmation, mtCustom
}

export enum SaopMsgDlgButton {
    mbNone = 0
    ,mbOK = 1
    ,mbCancel = 2
    ,mbYes = 3
    ,mbNo = 4
}

export interface SaopMsgDlgEvent {
    parent:any;
    data:any;
}

type SaopMsgDlgButtons = Array<SaopMsgDlgButton>;



export class SaopMessageDlg {
    private _sender:SaopMsgDlgEvent;
    private _modalParent:boolean = false;
    //
    public onOk: (sender:any) => any = null;
    public onCancel: (sender:any) => any = null;
    public onYes: (sender:any) => any = null;
    public onNo: (sender:any) => any = null;
    public onHide: (sender:any) => any = null;

    constructor(sender:SaopMsgDlgEvent){
        this._sender = sender;
    }

    Show(Msg:string,Title:string,DlgType:SaopMsgDlgType = SaopMsgDlgType.mtInformation,Buttons:SaopMsgDlgButtons = [SaopMsgDlgButton.mbOK]):SaopMsgDlgButton{
        global.ConsoleLogDegug("message:"+Msg);
        global.ConsoleLogDegug("title:"+Title);

        let _this = this;
        this._modalParent = $("body").hasClass("modal-open");

        let titleDiv = $('#alert-modal-title-div');
        let bodyTag = $('#alert-modal-body');

        if (DlgType == SaopMsgDlgType.mtInformation){
            $("#alert-modal-title-div").css("background-color","white");
        } else if (DlgType == SaopMsgDlgType.mtError) {
            $("#alert-modal-title-div").css("background-color","#D82C25");
        } else {
            $("#alert-modal-title-div").css("background-color","silver");            
        }

        if (!isEmpty(Title)){
            titleDiv.html(Title);
        }
        
        bodyTag.html(Msg);

        if (Buttons !== null) {
            let _element_mbOK = Buttons.find(element => element === SaopMsgDlgButton.mbOK);
            if (isUnDefined(_element_mbOK)) {
                $("#btnAlertOK").remove();
            } else {
                $("#btnAlertOK").off("click");
                $("#btnAlertOK").click(
                    function () {
                        if (!isUnDefined(_this.onOk)){
                            _this.onOk(_this._sender);
                        }
                        return SaopMsgDlgButton.mbOK;
                    }
                );                
            }
            //
            let _element_mbCancel = Buttons.find(element => element === SaopMsgDlgButton.mbCancel);
            if (isUnDefined(_element_mbCancel)) {
                $("#btnAlertCancel").remove();
            } else {
                $("#btnAlertCancel").off("click");
                $("#btnAlertCancel").click(
                    function () {
                        if (!isUnDefined(_this.onCancel)){
                            _this.onCancel(_this._sender);
                        }
                        return SaopMsgDlgButton.mbCancel;
                    }
                );                 
            }
            //
            let _element_mbYes = Buttons.find(element => element === SaopMsgDlgButton.mbYes);
            if (isUnDefined(_element_mbYes)) {
                $("#btnAlertYes").remove();
            } else {
                $("#btnAlertYes").off("click");
                $("#btnAlertYes").click(
                    function () {
                        if (!isUnDefined(_this.onYes)){
                            _this.onYes(_this._sender);
                        }
                        return SaopMsgDlgButton.mbYes;
                    }
                );                 
            }            
            //
            let _element_mbNo = Buttons.find(element => element === SaopMsgDlgButton.mbNo);
            if (isUnDefined(_element_mbNo)) {
                $("#btnAlertNo").remove();
            } else {
                $("#btnAlertNo").off("click");
                $("#btnAlertNo").click(
                    function () {
                        if (!isUnDefined(_this.onNo)){
                            _this.onNo(_this._sender);
                        }
                        return SaopMsgDlgButton.mbNo;
                    }
                );                 
            }             
        } else {
            $("#btnAlertOK").remove();
            $("#btnAlertCancel").remove();
            $("#btnAlertYes").remove();
            $("#btnAlertNo").remove();
            return SaopMsgDlgButton.mbNone;
        }

        $('#alert-modal').on('hidden.bs.modal', function (e) {
            $("#alert-modal-btn-ok").unbind("click");
            $(this).modal('dispose');
            if (!isUnDefined(_this.onHide)){
                _this.onHide(_this._sender);
            }        
            if (_this._modalParent) {
                $("body").addClass("modal-open");
            }    
        })
    
        $('#alert-modal').modal('show');
        setModalFormZIndex('#alert-modal'); 
    }

    //
}





