﻿
export abstract class global {
    public static debugMode: boolean;
    public static siteBaseUrl: string;

    public static ConsoleLogDegug(message: any): void {
        if (this.debugMode) console.log(message);
    }

    public static GetClientLogModeStorageKeyValue(): string {
        return "SaopMojInfo.globalClientLogModeVal";
    }

    public static GetClientLogModeStorageValue(): boolean {
        let debugModeDefaultValue = false;

        var debugModeStringVal = ReadVariableFromStorage(global.GetClientLogModeStorageKeyValue());

        let debugModeVal = debugModeDefaultValue;
        if (debugModeStringVal) {
            debugModeVal = debugModeStringVal === "true" ? true : false;
        }

        return debugModeVal;
    }

    public static SetClientLogModeStorageValue(): void {
        let logMode = this.GetClientLogModeStorageValue();
        WriteVariableToStorage(global.GetClientLogModeStorageKeyValue(), !logMode);

        alertify.message("Client log mode enabled: " + !logMode);
    }

    public static getSiteBaseUrl(): string {
        return $("#siteBaseUrl").val() as string;
    }

    public static setHelpLink(): void {
        //nastavi help link, v odvisnosti od lokalizacije
        let svgHelp: any = document.getElementById('saop-help');

        if(svgHelp != null)
        {
            let language = svgHelp.getAttribute("data-language");

            //počakamo da se gneszdeni objekt naloži
            svgHelp.addEventListener("load", function () {
                if (svgHelp.contentDocument != null) {
                    let svgHelpLink = svgHelp.contentDocument.getElementById("saop-help-link");
                    if (isUnDefined(svgHelpLink) == false) {
                        if (language == "hr") {
                            svgHelpLink.setAttribute("href", "http://help.saop.hr/hr/mojinfo");
                        }
                        else if (language == "sr") {
                            svgHelpLink.setAttribute("href", "http://www.saop.rs/icenter/100/uputstva-za-upotrebu/");
                        }
                        else {
                            svgHelpLink.setAttribute("href", "https://si-icenter.knowledgeowl.com/help/mojinfo-navodila-za-uporabo-prenovljenega-portala");
                        }
                    }
                }
            });

            //zaradi braskalnik - mozilla firefox - izgleda, da je object ''loaded'' preden mu dodamo on loaded
            if($('#saop-help-link') != null)
            {
                const event = new Event('load');
                svgHelp.dispatchEvent(event);
            }
        }
    }
}

export function formatDate(date: Date, format: string): string {
    const to2 = (n: number): string => ((Math.abs(n) < 10 ? '0' : '') + Math.abs(n));
    return format.replace(/[ydmh]+/gi, (match: string): string => {
        switch (match) {
            case 'yyyy': return date.getFullYear().toString();
            case 'd': return date.getDate().toString();
            case 'dd': return to2(date.getDate());
            case 'm': return (date.getMonth() + 1).toString();
            case 'mm': return to2(date.getMonth() + 1);
            case 'H': return date.getHours().toString();
            case 'HH': return to2(date.getHours());
            case 'MM': return to2(date.getMinutes());
            default:
                return match;
        }
    });
}

export function convertString2Date(stringDate: string, format: string): Date {
    global.ConsoleLogDegug("convertString2Date");

    switch (format) {
        case "dd.mm.yyyy":
            let day = stringDate.substring(0, 2);
            let month = stringDate.substring(3, 5);
            let year = stringDate.substring(6, 10);

            return new Date(+year, +month - 1, +day);

        default:
            return null;
    }
}

export function setModalFormZIndex(modalFormId: string): void {
    global.ConsoleLogDegug("setModalFormZIndex");
    global.ConsoleLogDegug("modalFormId:" + modalFormId);
    var zIndex = 1050 + (10 * $('.modal:visible').length);

    $(modalFormId).css('z-index', zIndex);
    setTimeout(function () {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    }, 0);
}

export function isEmpty(str: string) {
    return (!str || str.length === 0);
}

export function isUnDefined(_obj: any) {
    return (!_obj || _obj === null || typeof _obj === "undefined");
}

export function isNotSet(_obj: any) {
    return (_obj === null || typeof _obj === "undefined");
}

export function ReadVariableFromStorage(variableName: string): string {
    return localStorage.getItem(variableName);
}

export function WriteVariableToStorage(variableName: string, value: any): void {
    localStorage.setItem(variableName, value);
}

export function filterTableRows(filterVal: string, idTableElement: string): void {
    global.ConsoleLogDegug("filterTableRows");

    let val = $.trim(filterVal).replace(/ +/g, ' ').toLowerCase();
    let rows = $(idTableElement + " tr:has(td)");

    rows.show().filter(function () {
        var _text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~_text.indexOf(val);
    }).hide();
}

export function setFirstFormControlToFocus(formId: string): void {
    global.ConsoleLogDegug("setFirstFormControlToFocus");

    // brez timeout funkcije focus ne dela :(
    setTimeout(function () {
        var firstFormControl = $(formId + " .form-control:visible:not(:disabled):first");
        if (firstFormControl) {
            firstFormControl.focus();
        }
    }, 500);
}

export function getRelativeSiteURL(controller: string): string {
    let _url = window.location.href;
    let iC = _url.indexOf(controller);
    if (iC > 0) {
        return _url.substring(0, iC) + controller;
    } else {
        return window.location.href;
    }
}

export function getSiteAbsoluteURL(relativePath: string): string {
    let controller = relativePath.split("/")[0];
    let method = relativePath.replace(controller, "").replace("//", "");

    // če relative path že vsebuje http, ga ne dodamo
    if (relativePath.indexOf("http") >= 0) {
        return relativePath;
    }

    return global.siteBaseUrl + "/" + controller + method;
}

export function relodeCurrentPage(): void {
    window.location.reload();
}