﻿import { global } from "../Common/saop-common";
import { SaopCheckboxSwitch } from "../Components/saop-checkbox-switch";
import { SaopViewForm } from "../core/saop-view-form";
import { SaopMultiselect } from "../Components/saop-multiselect";


export class SaopRegUraDogodekView extends SaopViewForm {
    private _viewId: string; 
    private _sifraDogodkaId: string = "#SifraDogodka";
    private _createRowActionId: string = "#CreateRowAction";
    private _eventStartId: string = "#EventStart";
    private _eventEndId: string = "#EventEnd";
    private _trajenjeMalicaId: string = "#TrajenjeMalica";
     
    private _cbvecDniCheckBoxMainControl: JQuery<HTMLElement>;
    private _cbvecDniCheckBoxControl: JQuery<HTMLElement>;
    private _enDatumDivControl: JQuery<HTMLElement>;
    private _vecDatumovDivControl: JQuery<HTMLElement>;
    private _izborVecDniControl: JQuery<HTMLInputElement>;
    private _selectZaposleni: SaopMultiselect;
    private _sifraLokacijeDivControl : JQuery<HTMLElement>;

    constructor(viewId:string,libName:string = ""){
        super(viewId,libName);
        this.setDictionary();                
        this.title = this.translationService.translate("Dogodek");
        this.addRecordEndpoint = "REGUraDogodek/CreateRegUraDogodek";
        this.editRecordEndpoint = "REGUraDogodek/EditRegUraDogodek";
        this.deleteRecordEndpoint = "REGUraDogodek/DeleteRegUraDogodek";   

        this._viewId = viewId;
        this.viewShowComplete = this.initForm.bind(this);
        this.afterSubmitSuccess = this.initForm.bind(this);
        //
        this._selectZaposleni = new SaopMultiselect(libName + "._selectZaposleni", { inputWidthPX: '260px' });
        this._selectZaposleni.afterChangeCommonEvent = this.filterSelectZaposleniData.bind(this);
        //this._selectZaposleni.init("#employeesFilter", "Išči");        
        //
    }

    setDictionary(){
        let dictionary:string[] = ["Dogodek","Ali ste prepričani, da želite izbrisati dogodek?"];
        this.setTerminologyList(dictionary);
    } 

    initForm() {
        this.onBtnCancel = this.emptyCheckboxHtml.bind(this);
        this.onSubmitSuccessComplete = this.emptyCheckboxHtml.bind(this);

        let _this = this;
        global.ConsoleLogDegug(this.id + ".initForm()");

        let saopCheckboxSwitch = new SaopCheckboxSwitch();
        saopCheckboxSwitch.init(this.id);

        this._cbvecDniCheckBoxMainControl = $("" + this._viewId + " *> #vecDniCheckBox");
        this._cbvecDniCheckBoxControl = $("" + this._viewId + " *> #cbvecDniCheckBox");
        this._izborVecDniControl = $("" + this._viewId + " *> #IzborVecDni");
        this._enDatumDivControl = $("" + this._viewId + " *> #enDatumDiv");
        this._vecDatumovDivControl = $("" + this._viewId + " *> #vecDatumovDiv");
        this._sifraLokacijeDivControl = $("" + this._viewId + " *> #SifraLokacije");

        this.setDatumControlsDisplay();

        $(this._cbvecDniCheckBoxControl).change(function () {
            _this.setDatumControlsDisplay();
        }); 

        this._selectZaposleni.init("#employeesFilter", "Zaposleni"); 
        let selected_options = $("#employeesFilter").attr("data-selected");
        if (selected_options !== undefined) {
            let array = selected_options.split(",");
            _this._selectZaposleni.setSelectValues(array);
        }

        this.toggleLocationInput();
        $(this._viewId + ' ' + this._sifraDogodkaId).off('change');
        $(this._viewId + ' ' + this._sifraDogodkaId).on('change', _this.onChangesifraDogodka.bind(this));

        $(this._viewId + ' ' + this._eventStartId).on('input', function () {
            _this.checkAndSetLunchEventEndTime();
        });
    }

    setDatumControlsDisplay(): void {
        let checked = this._cbvecDniCheckBoxControl.is(":checked");
        let checkedStringVal = checked ? "true" : "false";

        $(this._izborVecDniControl).val(checkedStringVal);

        if (this._enDatumDivControl != null && this._vecDatumovDivControl != null) {
            if (checked) {
                this._enDatumDivControl.hide();
                this._vecDatumovDivControl.show();
            }
            else {
                this._enDatumDivControl.show(); 
                this._vecDatumovDivControl.hide();
            }
        }
    }

    // potrebno, ker switch checkbox ne dela dobro, če je na isti html več isti edit form
    emptyCheckboxHtml(): void {
        global.ConsoleLogDegug(this.id + ".emptyCheckboxHtml()");
        this._cbvecDniCheckBoxMainControl.html("");
    }
    filterSelectZaposleniData(element: any) {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.filterSelectZaposleniData");
        
        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraneSifreZaposlenih = <string>(element.val().toString());

        console.log("ch...:"+izbraneSifreZaposlenih);

        let _SelectedEmployees = $("#regUraDogodekView > #SelectedEmployees");
        if (_SelectedEmployees.length > 0) {
            $("#regUraDogodekView > #SelectedEmployees").val(izbraneSifreZaposlenih);
        } else {
            console.log("regUraDogodekView.SelectedEmployees not found ...");
        }
           
    }

    onChangesifraDogodka(e: any): void {
        this.checkAndSetLunchEventEndTime();
        this.toggleLocationInput();
    }

    private checkAndSetLunchEventEndTime(): void {
        let _selectedValue = $(this._viewId + ' ' + this._sifraDogodkaId).val().toString();
        let createRowActionVal = $(this._viewId + ' ' + this._createRowActionId).val().toString().toLowerCase();
        let isCreateRowAction = createRowActionVal === "true" ? true : false;

        if (isCreateRowAction && _selectedValue == 'MAK') {
            let eventStart = $(this._viewId + ' ' + this._eventStartId).val().toString().trim()
            let uraStartArray = eventStart.split(':');

            if (uraStartArray.length == 3) {
                if (!isNaN(Number(uraStartArray[0])) && !isNaN(Number(uraStartArray[1])) && !isNaN(Number(uraStartArray[2]))) {
                    let datumZac = new Date(2023, 1, 1, Number((uraStartArray[0])), Number((uraStartArray[1])), Number((uraStartArray[2])))

                    let trajanjeMaliceUre = Number($(this._viewId + ' ' + this._trajenjeMalicaId).val().toString().replace(",","."));
                    if (isNaN(trajanjeMaliceUre)) {
                        trajanjeMaliceUre = 0.5;
                    }

                    let datumKon = new Date(datumZac.getTime() + (1000 * 60 * 60 * trajanjeMaliceUre));  // ms * sekunde * minute * ure

                    let ure = datumKon.getHours();
                    let minute = datumKon.getMinutes();
                    let sekunde = datumKon.getSeconds();
                    let uraKonecString = this.addLeadingZero(ure) + ":" + this.addLeadingZero(minute) + ":" + this.addLeadingZero(sekunde)

                    $(this._viewId + ' ' + this._eventEndId).val(uraKonecString);
                }
            }
        }
    }

    private toggleLocationInput(): void {
        global.ConsoleLogDegug("SaopRegUraDogodekView.toggleLocationInput");

        let _selectedValue = $(this._viewId + ' ' + this._sifraDogodkaId).val().toString();

        let show = _selectedValue === '?' ? "False" : $('#' + _selectedValue).val();

        global.ConsoleLogDegug(show);
        
        if (show === 'True' || _selectedValue === 'RD') {
            this._sifraLokacijeDivControl.parent().show();
        } else {
            this._sifraLokacijeDivControl.parent().hide();
        }
    }

    private addLeadingZero(stevilo: number): string {
        return (stevilo < 10 ? "0" : "") + stevilo.toString().trim();     
    }
    //  
}
