﻿
/// <reference path="../@types/bootstrap-datetimepicker/index.d.ts" />
import { convertString2Date, global,isEmpty } from "../Common/saop-common";

export class SaopSifranti {

    init() {
        global.ConsoleLogDegug("SaopSifranti.init...");
        var inputDivs = document.querySelectorAll<HTMLElement>('input[data-saopSifranti-inputmask]');

        inputDivs.forEach(element => {
            global.ConsoleLogDegug("element.init......................" + element.id);

            let _this = this;
            let elementAtrr = $(element).attr("data-saopSifranti-inputmask");
            if (isEmpty(elementAtrr)) {return;};
            global.ConsoleLogDegug(elementAtrr);
            //
            const artrrArray = elementAtrr.split("#");
            let _inputType = artrrArray[2];
            let _maskLength = parseInt(artrrArray[3]);
            if (_maskLength.toString() != artrrArray[3]) {
                _maskLength = 0;
            }
            let _inputLeadingChar = artrrArray[4];
            //
            if (_inputType == "N") {
                $(element).attr("type","text");
                $(element).on('keypress', function(event) {
                    let allowedChars ='1234567890';
                    const chr = String.fromCharCode(event.which);
                    if (allowedChars.indexOf(chr) < 0) {
                        return false;
                    }
                }); 
            }
            //
            let _readonlyAtrr = $(element).attr('readonly');
            let _readonly = false;
            if (isEmpty(_readonlyAtrr) == false && _readonlyAtrr == "readonly"){
                _readonly = true;
            }
            //
            let _disabledAtrr = $(element).attr('disabled');
            let _disabled = false;
            if (isEmpty(_disabledAtrr) == false && _disabledAtrr == "disabled"){
                _disabled = true;
            }            
            //
            $(element).off("focusout");
            if (_readonly == false && _maskLength > 0) {
                $(element).attr('maxlength',_maskLength.toString());
                $(element).on("focusout", function (event: any) {
                    var controlValue = event.target.value;
                    controlValue = _inputLeadingChar.repeat(_maskLength)+controlValue;
                    controlValue = controlValue.substring(controlValue.length - _maskLength);
                    event.target.value = controlValue;
                }); 
            }
            //
        });

        global.ConsoleLogDegug("initInputs...done");
    }

}





