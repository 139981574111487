﻿import { filterTableRows, global, isUnDefined } from "../Common/saop-common";
import { SaopWebDataTable, SaopWebGrid, SaopWebGridProperties, SaopWebGridToolbar, SaopWebGridToolbarButton } from "../Components/saop-grid";
import { SaopMessageDlg, SaopMsgDlgButton } from "../Components/saop-message-dlg";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopTranslationService } from "../Core/saop-translate";
import { SaopViewForm, SaopWfAction } from "../Core/saop-view-form";
import { KEEmployeeGradesEditView } from "./KEEmployeeGradesEdit-view";

class EmployeeGradesData {
    sifraOcenObdobja: string;
    sifraocenobdobjaFingerprint: string;
    sifraZaposleni: string;
    isOcenObdobjaStatusActive: boolean;
    fingerprint: string;
    formTitle: string
}

export class KEAllEmployeesGradesView extends SaopViewForm {
    private viewId: string;
    private libName: string;
    private _loadingSpinnerId: string = "allEmployeesGradesFormLoading";
    private _buttonCloseId: string = "btnClose";
    private divId: string = "#allEmployeesGradesForm";
    private webGridId: string = "#allEmployeesGradesGrid";
    private webGridRowsBodyId: string = "#allEmployeesGradesGridBodyAjax";
    private _massVnosBtnId: string = "#editMassAllEmployeesGrades";
    private _colCheckboxAllId: string = "#colCheckboxAll";
    private _keEmployeeGradesEditView: KEEmployeeGradesEditView;
    public webGrid: SaopWebGrid;
    public formTranslationService: SaopTranslationService;

    private _searchValueId: string = "#AllEmployeesGradesTableSearchValue";
    private _mainFormDiv: JQuery<HTMLElement>;
    private _searchValue: JQuery<HTMLElement>;

    constructor(viewId:string,libName:string = ""){
        super(viewId, libName);

        this.viewId = viewId;
        this.libName = libName; 

        this.setDictionary(); 
        this.title = "";
        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "KeAllEmployeesGradesAdministration/GetAllEmployeesGrades";
        this.deleteRecordEndpoint = ""; 

        this.formTranslationService = new SaopTranslationService();
    }

    setDictionary(){
        this.setTerminologyList();
    } 

    public afterViewShowSuccess(sender:any):void{
        this.LoadViewEvents();
    }

    public LoadViewEvents ():void{
        let _viewDiv = $(this.id);
        if (_viewDiv.length == 0) {
            return;
        }
    }

    showForm(data: any, fullTitle: string): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.showForm");

        this.fullTitle = fullTitle;
        this.recordDetails(data);
        this.SetToFullScreen(); 

        this.viewShowComplete = this.initForm.bind(this);
    }

    initForm(): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.initForm");

        this._mainFormDiv = $(this.divId);

        // prevajanje
        this.setFormTerminologyList();

        // init grid 
        this.getWebGridToolbarAndInitWebGrid();

        this.initEditView();
    }

    private setFormTerminologyList() {
        let terminology = ["Masovni vnos"];

        this.formTranslationService.setTranslationsList(terminology);
    }

    private getWebGridToolbarAndInitWebGrid(): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.getWebGridToolbarAndInitWebGrid");

        let _this = this;
        let isStatusActive = this._mainFormDiv.attr("data-isocenobdobjastatusactive");
        let isOcenObdobjaStatusActive = isStatusActive === "True" ? true : false;

        let params = { javascriptKnjiznica: _this.libName, IsOcenObdobjaStatusActive: isOcenObdobjaStatusActive };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.initWebGrid(response);
            _this.initFilterControls();
        };
        saopServicesCore.ExecuteAction({
            action: "KeAllEmployeesGradesAdministration/GetTableToolbar"
            , data: params
            , type: "GET"
        });
    }

    private initWebGrid(toolbarTemplate: string = "") {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.initWebGrid");

        let captionToolbar = $(this.webGridId).attr("caption-toolbar");
        //
        this.webGrid = new SaopWebGrid(this.libName + ".webGrid");
        this.webGrid.afterColumnCheckboxChanged = this.webGridAfterColumnCheckboxChanged.bind(this);
        let gridProperties: SaopWebGridProperties;
        let gridToolbar: SaopWebGridToolbar;
        let toolbarButtons: SaopWebGridToolbarButton[] = [];
        let dataTable: SaopWebDataTable;

        toolbarButtons.push({ id: "", caption: "", template: toolbarTemplate });
        gridToolbar = { id: "saopWebGridTB1", caption: captionToolbar, buttons: toolbarButtons };

        dataTable = { enabled: true, ordering: true };

        gridProperties = {
            gridId: this.webGridId
            , togglePanel: { enabled: false }
            , toolbar: gridToolbar
            , dataTable: dataTable
        };
        //
        this.webGrid.init(gridProperties);

        this.initWebGridListners();

        $(this.divId).show();
    }

    private initFilterControls(): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.initFilterControls");

        this._searchValue = this._mainFormDiv.find(this._searchValueId);
        let _this = this;

        this._searchValue.off("keyup");
        this._searchValue.on("keyup", function () {
            _this.executeFilterSearch($(this).val().toString());
        });
    }

    private executeFilterSearch(searchVal: string): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.executeFilterSearch");

        // tole ni delovalo: ko se je spremenilo leto, je se vedno filtriral stare podatke od prejsnjega leta
        //let oTable = this.webGrid.dataTable;
        //oTable.search(searchVal).draw();

        filterTableRows(searchVal, this.divId);
    }

    private refreshAllEmployeesGradesGridRowsData(sender: any): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.refreshAllEmployeesGradesGridRowsData");

        let _this = this;
        let params = { sifraOcenObdobja: sender.datasifraocenobdobja, isStatusActive: sender.dataisstatusactive, fingerprint: sender.datasifraocenobdobjafingerprint, selectedSifraZaposleni: sender.dataselectedsifrazaposleni };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.refreshGridRowsData(response);
        };
        saopServicesCore.ExecuteAction({
            action: "KeAllEmployeesGradesAdministration/GetAllEmployeesGradesTableRows"
            , data: params
            , type: "GET"
        });
    }

    private refreshGridRowsData(htmlContent: string) {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.refreshGridRowsData");

        if ($(this.webGridId).length) {
            $(this.webGridRowsBodyId).html(htmlContent);
            this.initWebGridListners();
            if (!isUnDefined(this._searchValue) && this._searchValue.length) {
                this.executeFilterSearch(this._searchValue.val().toString());
            }
        }
    }

    private initWebGridListners() {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.initWebGridListners");

        let _this = this;

        // prikaz edit screen ko klikne na vrstico
        $("body").off("click", this.viewId + " " + this.webGridId + " tr td:not(.td-action,.td-action-checkbox)");
        $("body").on("click", this.viewId + " " + this.webGridId + " tr td:not(.td-action,.td-action-checkbox)", function () {
            let selectedRow = <HTMLElement>$(this).closest("tr")[0];
            let data = _this.getDataAttribute(selectedRow);

            // barvanje oznacene vrstrice
            _this.webGrid.markSelectedRow(selectedRow);

            if (data.isOcenObdobjaStatusActive && !isUnDefined(data.sifraOcenObdobja) && !isUnDefined(data.sifraZaposleni)) {
                _this._keEmployeeGradesEditView.title = data.formTitle;
                _this._keEmployeeGradesEditView.showViewForm(SaopWfAction.wfaEditRecord, { sifraOcenObdobja: data.sifraOcenObdobja, sifraOcenObdobjaFingerprint: data.sifraocenobdobjaFingerprint, sifraZaposleniList: data.sifraZaposleni, isOcenObdobjaStatusActive: data.isOcenObdobjaStatusActive, fingerprintList: data.fingerprint }, null);
            }
        });

        // prikaz edit screen ko klikne na action stolpec
        $("body").off("click", this.viewId + " " + this.webGridId + " tr .td-action .KEAllEmployeesGradesGrid_edit");
        $("body").on("click", this.viewId + " " + this.webGridId + " tr .td-action .KEAllEmployeesGradesGrid_edit", function () {
            let selectedRow = <HTMLElement>$(this).closest("tr")[0];
            let data = _this.getDataAttribute(selectedRow);

            // barvanje oznacene vrstrice
            _this.webGrid.markSelectedRow(selectedRow);

            if (data.isOcenObdobjaStatusActive && !isUnDefined(data.sifraOcenObdobja) && !isUnDefined(data.sifraZaposleni)) {
                _this._keEmployeeGradesEditView.title = data.formTitle;
                _this._keEmployeeGradesEditView.showViewForm(SaopWfAction.wfaEditRecord, { sifraOcenObdobja: data.sifraOcenObdobja, sifraOcenObdobjaFingerprint: data.sifraocenobdobjaFingerprint, sifraZaposleniList: data.sifraZaposleni, isOcenObdobjaStatusActive: data.isOcenObdobjaStatusActive, fingerprintList: data.fingerprint }, null);
            }
        });

        $("body").off("click", this.viewId + " " + this.webGridId + " tr .saop-td-input-checkbox");
        $("body").on("click", this.viewId + " " + this.webGridId + " tr .saop-td-input-checkbox", function () {
            _this.webGridAfterColumnCheckboxChanged();
        });

        $("body").off("click", this.viewId + " " + this._colCheckboxAllId);
        $("body").on("click", this.viewId + " " + this._colCheckboxAllId, function () {
            _this.webGridAfterColumnCheckboxAllChanged();
        });

        $(this.viewId + " " + this._colCheckboxAllId).prop('checked', false);
        $(this.viewId + " " + _this._massVnosBtnId).prop('disabled', true);
    }

    getDataAttribute(element: HTMLElement): EmployeeGradesData {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.getDataAttribute");

        let data = new EmployeeGradesData();

        data.sifraOcenObdobja = element.getAttribute("data-sifraocenobdobja");
        data.sifraocenobdobjaFingerprint = element.getAttribute("data-sifraocenobdobjafingerprint");
        data.sifraZaposleni = element.getAttribute("data-sifrazaposleni");
        let isStatusActive = element.getAttribute("data-isocenobdobjastatusactive");
        data.isOcenObdobjaStatusActive = isStatusActive === "True" ? true : false;
        data.fingerprint = element.getAttribute("data-fingerprint");
        data.formTitle = element.getAttribute("data-formtitle");

        return data;
    }

    initEditView(): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.initEditView");

        
        this._keEmployeeGradesEditView = new KEEmployeeGradesEditView("#keGradesAdministrationView003", this.libName + "._keEmployeeGradesEditView");
        this._keEmployeeGradesEditView.fullScreen = true;
        this._keEmployeeGradesEditView.refreshData = this.refreshAllEmployeesGradesGridRowsData.bind(this);
    }

    webGridAfterColumnCheckboxChanged(): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.webGridAfterColumnCheckboxChanged");

        let _this = this;

        // disabled mass vnos gumba
        $(_this.viewId + " " + _this._massVnosBtnId).prop('disabled', true);

        // ali je kaksen checkobx oznacen? Da nastavimo gumb za masovno kpiranje enabled
        $(this.viewId + " " + this.webGridId + " tr td input.saop-td-input-checkbox").each(function () {
            let checked = $(this).is(":checked");
            if (checked) {
                $(_this.viewId + " " + _this._massVnosBtnId).prop('disabled', false);
            }
        });
    }

    private editMassData(): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.editMassData");

        let _this = this;
        let seznamSifraZaposleni: string[] = [];
        let seznamFingerprint: string[] = [];
        let dataSifraOcenObdobja: string = "";
        let dataSifraocenobdobjaFingerprint: string = "";
        let dataIsOcenObdobjaStatusActive: boolean = false;

        //poiscemo vse oznacene sifre zaposlenih
        $(this.viewId + " " + this.webGridId + " tr td input.saop-td-input-checkbox").each(function () {
            let checked = $(this).is(":checked");
            if (checked) {
                let selectedRow = <HTMLElement>$(this).closest("tr")[0];
                let data = _this.getDataAttribute(selectedRow);

                dataSifraOcenObdobja = data.sifraOcenObdobja;
                dataSifraocenobdobjaFingerprint = data.sifraocenobdobjaFingerprint;
                dataIsOcenObdobjaStatusActive = data.isOcenObdobjaStatusActive;

                seznamSifraZaposleni.push(data.sifraZaposleni);
                seznamFingerprint.push(data.fingerprint);
            }
        });

        if (seznamSifraZaposleni.length > 0) {
            _this._keEmployeeGradesEditView.title = this.formTranslationService.translate("Masovni vnos");
            _this._keEmployeeGradesEditView.showViewForm(SaopWfAction.wfaEditRecord, { sifraOcenObdobja: dataSifraOcenObdobja, sifraOcenObdobjaFingerprint: dataSifraocenobdobjaFingerprint, sifraZaposleniList: seznamSifraZaposleni.toString(), isOcenObdobjaStatusActive: dataIsOcenObdobjaStatusActive, fingerprintList: seznamFingerprint.toString() }, null);
        }
    } 

    webGridAfterColumnCheckboxAllChanged(): void {
        global.ConsoleLogDegug("KEAllEmployeesGradesView.webGridAfterColumnCheckboxAllChanged");

        let _this = this;

        let allCheckboxChecked = $(this.viewId + " " + this._colCheckboxAllId).is(":checked");
        $(_this.viewId + " " + _this._massVnosBtnId).prop('disabled', !allCheckboxChecked);
        
        // nastavimo vrednosti vseh checkboxov
        $(this.viewId + " " + this.webGridId + " tr td input.saop-td-input-checkbox").each(function () {
            $(this).prop('checked', allCheckboxChecked);
        });
    }
}






