﻿import { global } from "../Common/saop-common";

export class SaopPopover {
    private _querySelector: string;

    constructor(querySelector: string = "[data-toggle=popover]") {
        this._querySelector = querySelector;
    }

    bindPopoverEvents() {
        global.ConsoleLogDegug("SaopPopover.bindPopoverEvents");
        let _this = this;

        $("[data-toggle=popover]").popover({
            html: true,
            sanitize: false,
            trigger: 'click',
            content: function () {
                var content = $(this).attr("data-popover-content");
                return $(content).children(".popover-body").html();
            }
        });

        $('html').on('mouseup', function (e) {
            global.ConsoleLogDegug("mouseup!");
            if (!$(e.target).closest('.popover').length) {
                global.ConsoleLogDegug("mouseup.popover!");
                $('.popover').each(function () {
                    global.ConsoleLogDegug("mouseup.popover.hide!");
                    //$(this.previousSibling).popover('hide');
                    $(_this._querySelector).popover('hide');
                });
            }
        });

        // when popover's content is shown
        $("[data-toggle=popover]").on('shown.bs.popover', function (e: any) {
            // set what happens when user clicks on the button
            global.ConsoleLogDegug("set!");

            _this.afterPopoverShow(this);

            $(".popoverLink").off('click');
            $(".popoverLink").on('click', function () {
                global.ConsoleLogDegug("popoverLink clicked!!!");
                $("[data-toggle=popover]").popover('hide');
            });

            $(".popoverClose").off('click');
            $(".popoverClose").on('click', function () {
                global.ConsoleLogDegug("Close popover clicked!!!");
                $("[data-toggle=popover]").popover('hide');
            });
        });

    }

    afterPopoverShow(element: HTMLElement) {
        global.ConsoleLogDegug("SaopPopover.afterPopoverShow");
    }

    initSelfContainerPopover() {
        global.ConsoleLogDegug("SaopPopover.initSelfContainerPopover");

        $(this._querySelector).each(function () {
            let $elem = $(this);
            let elem = this;
            $elem.popover({
                container: elem,
                sanitize: false
            });
        });
    }

    enablePopover() {
        global.ConsoleLogDegug("SaopPopover.enablePopover");

        $(this._querySelector).each(function () {
            let $elem = $(this);
            $elem.popover('enable');
        });
    }

    disablePopover() {
        global.ConsoleLogDegug("SaopPopover.disablePopover");

        $(this._querySelector).each(function () {
            let $elem = $(this);
            $elem.popover('disable');
        });
    }
}






