﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopViewFormBindings } from "../Core/saop-view-form-bindings";
import { SaopViewForm } from "../core/saop-view-form";
import { SaopMultiselect } from "../Components/saop-multiselect";
import { SaopCheckboxSwitch } from "../Components/saop-checkbox-switch";
import { SaopMessageDlg, SaopMsgDlgButton } from '../Components/saop-message-dlg';

export interface SaopRegUraDogodekDeleteParamsters {
    DateStart:string;
    DateEnd:string;
} 

export class SaopRegUraDogodekDeleteView extends SaopViewFormBindings {
    private _viewForm:SaopViewForm;
    private _selectZaposleni: SaopMultiselect;
    private _cbSingleDaySelect:JQuery<HTMLElement>;
    private _multipleDaysDiv:JQuery<HTMLElement>;
    private _singleDayDiv:JQuery<HTMLElement>;  
    private _singleDaySelect:JQuery<HTMLElement>;  

    constructor(viewId:string,libName:string = ""){
        super(viewId,libName);
        this._viewForm = new SaopViewForm(viewId,libName+"._viewForm");
        this.setDictionary();                
        this._viewForm.fullTitle = this._viewForm.translationService.translate("Brisanje");
        this._viewForm.title = "";
        this._viewForm.addRecordEndpoint = "REGUraDogodek/DeleteRegUraDogodekPartial";
        this._viewForm.editRecordEndpoint = "";
        this._viewForm.deleteRecordEndpoint = "";           
        //
        this._viewForm.afterViewShowSuccess = this.afterViewShowSuccess.bind(this);   
    }

    setDictionary(){
        let dictionary:string[] = ["Brisanje","Ali ste prepričani, da želite izbrisati dogodke?"];
        this._viewForm.setTerminologyList(dictionary);
    }     

    public showForm(params:SaopRegUraDogodekDeleteParamsters = null):void {
        global.ConsoleLogDegug("showForm...");
        this._viewForm.addRecord(params);
    }

    public afterViewShowSuccess(sender:any):void{
        global.ConsoleLogDegug(this.id + ".afterViewShowSuccess()");
        let _this = this;
        //
        this._viewForm.refreshData = this.refreshData.bind(this);
        this._viewForm.onBtnOkClick = this.deleteDialog.bind(this);
        this._viewForm.afterSubmitSuccess = this.onValidationError.bind(this);
        //
        this._selectZaposleni = new SaopMultiselect();
        this._selectZaposleni.afterChangeCommonEvent = this.filterSelectZaposleniData.bind(this);            
        //
        this._singleDaySelect = $("#deleteRegUraDogodekPartial").find("#SingleDaySelect");
        if (this._singleDaySelect.length == 0) {
            console.error("Could not find control 'SingleDaySelect'... ");
        }        
        //
        this._cbSingleDaySelect = $("#deleteRegUraDogodekPartial").find("#cbSingleDaySelectSwitch");
        if (this._cbSingleDaySelect.length == 0) {
            console.error("Could not find control 'SingleDaySelect'... ");
        }
        //
        this._cbSingleDaySelect.off("change",_this.setCBSelectedDaysDisplay);
        this._cbSingleDaySelect.on("change",_this.setCBSelectedDaysDisplay.bind(this));      
        //
        this._multipleDaysDiv = $("#deleteRegUraDogodekPartial").find("#multipleDaysDiv");
        if (this._multipleDaysDiv.length == 0) {
            console.error("Could not find control 'multipleDaysDiv'... ");
        }        
        //
        this._singleDayDiv = $("#deleteRegUraDogodekPartial").find("#singleDayDiv");
        if (this._singleDayDiv.length == 0) {
            console.error("Could not find control 'singleDayDiv'... ");
        }         
        //
        this._selectZaposleni.init("#employeesFilter", "Zaposleni");
        let selected_options = $("#employeesFilter").attr("data-selected");
        if (selected_options !== undefined) {
            let array = selected_options.split(",");
            _this._selectZaposleni.setSelectValues(array);
        }
        //
        let saopCheckboxSwitch = new SaopCheckboxSwitch();
        saopCheckboxSwitch.init(this._viewForm.id);        

    }

    setCBSelectedDaysDisplay(): void {
        let checked = this._cbSingleDaySelect.is(":checked");
        let checkedStringVal = checked ? "true" : "false";
        $(this._cbSingleDaySelect).val(checkedStringVal);
        checkedStringVal = !checked ? "true" : "false";        
        $(this._singleDaySelect).val(checkedStringVal);

        if (this._singleDayDiv != null && this._multipleDaysDiv != null) {
            if (checked) {
                this._singleDayDiv.hide();
                this._multipleDaysDiv.show();
            }
            else {
                this._singleDayDiv.show(); 
                this._multipleDaysDiv.hide();
            }
        }
    }    

    filterSelectZaposleniData(element: any) {
        global.ConsoleLogDegug("SaopRegUraDogodekDeleteView.filterSelectZaposleniData");
        
        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraneSifreZaposlenih = <string>(element.val().toString());

        console.log("ch...:"+izbraneSifreZaposlenih);

        let _SelectedEmployees = $("#deleteRegUraDogodekPartial > #SelectedEmployees");
        if (_SelectedEmployees.length > 0) {
            $("#deleteRegUraDogodekPartial > #SelectedEmployees").val(izbraneSifreZaposlenih);
        } else {
            console.log("SelectedEmployees not found ...");
        }       
    }

    public deleteDialog(sender:any):void{
        global.ConsoleLogDegug("deleteDialog");
        let message = this._viewForm.translationService.translate("Ali ste prepričani, da želite izbrisati dogodke?");
        let title = this._viewForm.translationService.translate("Brisanje");
        let saopMessageDlg = new SaopMessageDlg({data:null,parent:null})  
        saopMessageDlg.onYes = this.onDeleteRecord.bind(this);
        saopMessageDlg.Show(message,title,1,[SaopMsgDlgButton.mbYes,SaopMsgDlgButton.mbNo]);
    }

    public onDeleteRecord(sender:any):void{
        $("#deleteRegUraDogodekPartial").submit();
    }

    public refreshData(sender:any):void {
        global.ConsoleLogDegug("SaopRegUraDogodekDeleteView.refreshData");
        this.afterDeleteSuccess(sender);
    }      

    public afterDeleteSuccess(sender:any):void {
        global.ConsoleLogDegug("SaopRegUraDogodekDeleteView.afterDeleteSuccess");
    }    

    public onValidationError(sender:any):void {
        global.ConsoleLogDegug("SaopRegUraDogodekDeleteView.onDeleteError");
        //this._viewForm.bindViewEvents();
        this.afterValidationError(sender);
    }   


    public afterValidationError(sender:any):void {
        global.ConsoleLogDegug("SaopRegUraDogodekDeleteView.AfterValidationError");
    }      
    //
}