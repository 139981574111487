﻿import { global } from "../Common/saop-common";
import { KEMyGradesGrid } from "./KEMyGrades-grid";

export class KEMyGrades {
    private libName: string;

    private keMyGradesGrid: KEMyGradesGrid;
    
    constructor(libName: string) {
        this.libName = libName; 

        this.keMyGradesGrid = new KEMyGradesGrid(this.libName + ".keMyGradesGrid");
        this.keMyGradesGrid.afterRefreshDataAfterEditViewExternal = this.refreshDataAfterEditView.bind(this);
    }  

    initView() {
        global.ConsoleLogDegug("KEMyGrades.initView");

        this.keMyGradesGrid.initView();
    }

    refreshDataAfterEditView(): void { 
        global.ConsoleLogDegug("KEMyGrades.refreshDataAfterEditView");
    }
}






