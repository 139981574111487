﻿import { filterTableRows, global, isUnDefined, setModalFormZIndex } from "../Common/saop-common";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopWfAction } from "../core/saop-view-form";
import { NDKInternalOrdersView } from "./NDKInternalOrders-view";

class ArchivedFilterValues {
    letoNarocila: number;
}

class InternaNarocilaData {
    letoNarocila: number;
    stevilkaNarocila: number;
    stevilkaVrstice: number;
    deleteFingerprint: string;
}

export class NDKInternalOrdersArchivedGrid {
    private libName: string;

    private buttonCopyDisabledDivId: string = "#copyArchivedInternalOrderDisabledDiv";
    private buttonCopyDivId: string = "#copyArchivedInternalOrderDiv";
    private divArhiviranaId: string = "#internaNarocilaArhiviranaDiv";
    private webGridArhiviranaId: string = "#NDKInternaNarocilaArhiviranaGrid";
    private webGridArhiviranaRowsBodyId: string = "#NDKInternaNarocilaArhiviranaGridBodyAjax";
    public webGrid: SaopWebGrid;
    public ndkInternalOrdersFullView: NDKInternalOrdersView;
    public letoFilterId: string = "#LetoNarocila";
    private _searchValueId: string = "#ArchivedTableSearchValue";
    private _mainFormDiv: JQuery<HTMLElement>;
    private _searchValue: JQuery<HTMLElement>;

    constructor(libName: string) {
        this.libName = libName; 
    }  

    initView() {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.initView");

        this._mainFormDiv = $(this.divArhiviranaId);

        // archived internal orders
        this.refreshArchiveInternalOrdersGridData();

        // init edit view
        this.initEditView();
    }

    initEditView(): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.initEditView");

        this.ndkInternalOrdersFullView = new NDKInternalOrdersView("#ndkInternalOrdersView002", this.libName + ".ndkInternalOrdersFullView");
        this.ndkInternalOrdersFullView.fullScreen = true;
    }

    refreshArchiveInternalOrdersGridData(letoNarocila: number = null, samoVrstice: boolean = false): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.refreshArchiveInternalOrdersGridData");

        let _this = this;
        let params = { letoNarocila: letoNarocila, samoVrstice: samoVrstice };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.setCopyButtonsVisibility(false);
            _this.refreshGridData(response);
        };
        saopServicesCore.ExecuteAction({
            action: "InternalOrder/GetArchivedInternalOrdersTable"
            , data: params
            , type: "GET"
        });
    }

    refreshGridData(htmlContent: string) {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.refreshGridData");

        if ($(this.webGridArhiviranaId).length) {
            $(this.webGridArhiviranaRowsBodyId).html(htmlContent);
            this.initWebGridListners();
            this.executeFilterSearch(this._searchValue.val().toString());
        }
        else {
            $(this.divArhiviranaId).html(htmlContent);
            this.getWebGridToolbarAndInitWebGrid(); 
        }
    }

    getWebGridToolbarAndInitWebGrid(): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.getWebGridToolbarAndInitWebGrid");

        let _this = this;
        let params = { javascriptKnjiznica: _this.libName };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.setCopyButtonsVisibility(false);
            _this.initWebGrid(response);
            _this.initFilterControls();    
        };
        saopServicesCore.ExecuteAction({
            action: "InternalOrder/GetArchivedTableToolbar"
            , data: params
            , type: "GET"
        });
    }

    initWebGrid(toolbarTemplate: string) {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.initWebGrid");

        let captionToolbar = $(this.webGridArhiviranaId).attr("caption-toolbar");
        //
        this.webGrid = new SaopWebGrid(this.libName + ".webGrid2");
        this.webGrid.afterColumnCheckboxChanged = this.webGridAfterColumnCheckboxChanged.bind(this);
        let gridProperties: SaopWebGridProperties;
        let gridToolbar: SaopWebGridToolbar;
        let toolbarButtons: SaopWebGridToolbarButton[] = [];
        let dataTable: SaopWebDataTable;
        //
        // id in caption je ze v template
        toolbarButtons.push({ id: "", caption: "", template: toolbarTemplate });
        //
        gridToolbar = { id: "saopWebGridTB2", caption: captionToolbar, buttons: toolbarButtons };

        dataTable = { enabled:true };

        gridProperties = {
            gridId: this.webGridArhiviranaId
            , toolbar: gridToolbar
            , dataTable: dataTable
        };
        //
        this.webGrid.init(gridProperties);

        this.initWebGridListners();
    }

    initWebGridListners() {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.initWebGridListners");

        let _this = this;

        // prikaz readonly full screen 
        $("body").off("click", this.webGridArhiviranaId + " tr td:not(.td-action,.td-action-checkbox)");
        $("body").on("click", this.webGridArhiviranaId + " tr td:not(.td-action,.td-action-checkbox)", function () {
            let selectedRow = <HTMLElement>$(this).closest("tr")[0];
            let data = _this.getDataValues(selectedRow);

            if (!isUnDefined(data.letoNarocila) && !isUnDefined(data.stevilkaNarocila) && !isUnDefined(data.stevilkaVrstice)) {
                _this.ndkInternalOrdersFullView.showViewForm(SaopWfAction.wfaEditRecord, { letoNarocila: data.letoNarocila, stevilkaNarocila: data.stevilkaNarocila, stevilkaVrstice: data.stevilkaVrstice });
            }
        });

        this.webGrid.initWebGridColumnCheckboxBindClickEvent();
    }

    webGridAfterColumnCheckboxChanged(): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.webGridAfterColumnCheckboxChanged");

        let checked = this.getCheckboxCheckValue(this.webGrid.markedColumnCheckbox);;
        this.setCopyButtonsVisibility(checked);
    }

    setCopyButtonsVisibility(checked: boolean): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.setCopyButtonsVisibility");

        let buttonCopyDisabledDivElement = this._mainFormDiv.find(this.buttonCopyDisabledDivId);
        let buttonCopyDivElement = this._mainFormDiv.find(this.buttonCopyDivId);

        if (checked == false) {
            buttonCopyDisabledDivElement.show();
            buttonCopyDivElement.hide();
        } else {
            buttonCopyDisabledDivElement.hide();
            buttonCopyDivElement.show();
        }
    }

    getDataValues(element: HTMLElement): InternaNarocilaData {
        let data = new InternaNarocilaData();

        data.letoNarocila = parseInt(element.getAttribute("data-letonarocila"));
        data.stevilkaNarocila = parseInt(element.getAttribute("data-stevilkanarocila"));
        data.stevilkaVrstice = parseInt(element.getAttribute("data-stevilkavrstice"));

        return data;
    }

    initFilterControls(): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.initFilterControls");

        this._searchValue = this._mainFormDiv.find(this._searchValueId);
        let _this = this;

        this._searchValue.off("keyup");
        this._searchValue.on("keyup", function () {
            _this.executeFilterSearch($(this).val().toString());
        });
    }

    executeFilterSearch(searchVal: string): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.executeFilterSearch");

        // tole ni delovalo: ko se je spremenilo leto, je se vedno filtriral stare podatke od prejsnjega leta
        //let oTable = this.webGrid.dataTable;
        //oTable.search(searchVal).draw();

        filterTableRows(searchVal, this.divArhiviranaId);
    }

    copyArchiveInternalOrder(): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.copyArchiveInternalOrder");

        this.copyArchiveInternalOrderExternal(this.webGrid.markedColumnCheckbox);
    }

    copyArchiveInternalOrderExternal(checkbox: JQuery<HTMLInputElement>): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.copyArchiveInternalOrderExternal");
    }

    archivedFiltersChanged(): void {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.archivedFiltersChanged");

        let archivedFilterValues = this.getArchivedFilterValues();

        this.refreshArchiveInternalOrdersGridData(archivedFilterValues.letoNarocila, true);
    }

    getArchivedFilterValues(): ArchivedFilterValues {
        global.ConsoleLogDegug("NDKInternalOrdersArchivedGrid.getFilterValues");

        let leto = null;
        let letoInput = $(this.letoFilterId)
        let letoStr = String(letoInput.val());
        if (letoStr.length == 4 || letoStr.length == 0) {
            if (letoStr.length == 4) {
                leto = +letoStr;
            }
        }

        let result = new ArchivedFilterValues();
        result.letoNarocila = leto;
        return result;
    }

    getCheckboxCheckValue(checkbox: JQuery<HTMLInputElement>): boolean {
        let checked = false;

        if (isUnDefined(checkbox)) {
            checked = false;
        } else {
            checked = checkbox.is(':checked');
        }

        return checked;
    }
  //
}






