﻿/// <reference path="../@types/alertify/index.d.ts" />
import { filterTableRows, global, isUnDefined } from "../Common/saop-common";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import { SaopServicesCore } from "../Core/saop-services-core";
import { KEMyGradeView } from "./KEMyGrade-view";
import { SaopTranslationService } from "../Core/saop-translate";

class MyGradeData {
    sifraOcenObdobja: string;
    sifraZaposleni: string;
    fingerprint: string;
    formTitle: string;
}

export class KEMyGradesGridOptions {
    showToolbar: boolean = true;
    showFilter: boolean = true;
}

export class KEMyGradesGrid {
    private libName: string;
    private _gridOptions: KEMyGradesGridOptions;
    //
    private divId: string = "#myGradesDiv";
    private webGridId: string = "#myGradesGrid";
    private webGridAktivnaRowsBodyId: string = "#myGradesGridBodyAjax";
    public webGrid: SaopWebGrid;
    public keMyGradeView: KEMyGradeView;
    public formTranslationService: SaopTranslationService;
    private _searchValueId: string = "#MyGradesSearchValue";
    private _mainFormDiv: JQuery<HTMLElement>;
    private _searchValue: JQuery<HTMLElement>;

    constructor(libName: string, gridOptions: KEMyGradesGridOptions = null) {
        if (isUnDefined(gridOptions)) {
            gridOptions = new KEMyGradesGridOptions();
        }
        this._gridOptions = gridOptions;

        this.libName = libName;  
        this.formTranslationService = new SaopTranslationService();
    }  

    initView() {
        global.ConsoleLogDegug("KEMyGradesGrid.initView");

        this._mainFormDiv = $(this.divId);

        // prevajanje
        this.setFormTerminologyList();

        // refresh active internal orders
        this.refreshMyGradesGridData();

        // init edit view
        this.initEditView();
    }

    private setFormTerminologyList() {
        let terminology = ["Interno naročilo za kopiranje ni izbrano."];

        this.formTranslationService.setTranslationsList(terminology);
    }     

    initEditView(): void {
        global.ConsoleLogDegug("KEMyGradesGrid.initEditView");

        this.keMyGradeView = new KEMyGradeView("#keMyGradeView001", this.libName + ".keMyGradeView");
        this.keMyGradeView.fullScreen = true;
        //this.keMyGradeView.refreshData = this.refreshDataAfterEditView.bind(this);
    }

    // metoda se izvede po edit, add ali delete metodi v this.ndkInternalOrdersView
    refreshDataAfterEditView(sender: any): void { 
        global.ConsoleLogDegug("KEMyGradesGrid.refreshDataAfterEditView");

        // osvezi se tabela
        this.refreshMyGradesGridData();
        // klice se external metoda, da se lahko osvezijo se drugi podatki npr. right summary data
        this.afterRefreshDataAfterEditViewExternal(sender);
    }

    public afterRefreshDataAfterEditViewExternal(sender: any): void {
        global.ConsoleLogDegug("KEMyGradesGrid.afterRefreshDataAfterEditViewExternal");
    }

    refreshMyGradesGridData(): void {
        global.ConsoleLogDegug("KEMyGradesGrid.refreshMyGradesGridData");

        let _this = this;

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.refreshGridData(response);
        };
        saopServicesCore.ExecuteAction({
            action:"KeGrades/GetMyGradesTable" 
            ,data:null
            ,type:"GET"
        });     
    }

    refreshGridData(htmlContent: string) {
        global.ConsoleLogDegug("KEMyGradesGrid.refreshGridData");

        if ($(this.webGridId).length) {
            $(this.webGridAktivnaRowsBodyId).html(htmlContent);
            this.initWebGridListners();
            if (!isUnDefined(this._searchValue) && this._searchValue.length) {
                this.executeFilterSearch(this._searchValue.val().toString());
            }
        }
        else {
            $(this.divId).html(htmlContent);
            this.getWebGridToolbarAndInitWebGrid(); 
        }
    }

    getWebGridToolbarAndInitWebGrid(): void {
        global.ConsoleLogDegug("KEMyGradesGrid.getWebGridToolbarAndInitWebGrid");

        let _this = this;
        let params = { javascriptKnjiznica: _this.libName };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            _this.initWebGrid(response);
            _this.initFilterControls();  
        };
        saopServicesCore.ExecuteAction({
            action: "KeGrades/GetTableToolbar"
            , data: params
            , type: "GET"
        });
    }

    initWebGrid(toolbarTemplate: string = "") {
        global.ConsoleLogDegug("KEMyGradesGrid.initWebGrid");

        let captionToolbar = $(this.webGridId).attr("caption-toolbar");
        //
        this.webGrid = new SaopWebGrid(this.libName + ".webGrid");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar: SaopWebGridToolbar;
        let toolbarButtons: SaopWebGridToolbarButton[] = [];
        let dataTable: SaopWebDataTable;
        //
        if (this._gridOptions.showToolbar) {
            toolbarButtons.push({ id: "", caption: "", template: toolbarTemplate });
            gridToolbar = { id: "saopWebGridTB1", caption: captionToolbar, buttons: toolbarButtons };
        }

        dataTable = { enabled: true, ordering: true};

        gridProperties = {
            gridId: this.webGridId
            , togglePanel: { enabled: false }
            , toolbar: gridToolbar
            , dataTable: dataTable
        };
        //
        this.webGrid.init(gridProperties);

        this.initWebGridListners();

        $(this.divId).show();

        //this.setDashboardSpecificView();
    }

    initWebGridListners() {
        global.ConsoleLogDegug("KEMyGradesGrid.initWebGridListners");

        let _this = this;

        // prikaz full screen ko klikne na vrstico
        $("body").off("click", this.webGridId + " tr td:not(.td-action,.td-action-checkbox)");
        $("body").on("click", this.webGridId + " tr td:not(.td-action,.td-action-checkbox)", function () {
            let selectedRow = <HTMLElement>$(this).closest("tr")[0];
            let data = _this.getMyGradeDataAttribute(selectedRow);

            // barvanje oznacene vrstrice
            _this.webGrid.markSelectedRow(selectedRow);

            if (!isUnDefined(data.sifraOcenObdobja) && !isUnDefined(data.sifraZaposleni)) {
                _this.showRecordDetails({ sifraOcenObdobja: data.sifraOcenObdobja, sifraZaposleni: data.sifraZaposleni, fingerprint: data.fingerprint }, data.formTitle);
            }
        });
    }

    initFilterControls(): void {
        global.ConsoleLogDegug("KEMyGradesGrid.initFilterControls");

        this._searchValue = this._mainFormDiv.find(this._searchValueId);
        let _this = this;

        this._searchValue.off("keyup");
        this._searchValue.on("keyup", function () {
            _this.executeFilterSearch($(this).val().toString());
        });
    }

    executeFilterSearch(searchVal: string): void {
        global.ConsoleLogDegug("KEMyGradesGrid.executeFilterSearch");

        // tole ni delovalo: ko se je spremenilo leto, je se vedno filtriral stare podatke od prejsnjega leta
        //let oTable = this.webGrid.dataTable;
        //oTable.search(searchVal).draw();

        filterTableRows(searchVal, this.divId);
    }

    getMyGradeDataAttribute(element: HTMLElement): MyGradeData {
        global.ConsoleLogDegug("KEMyGradesGrid.getMyGradeDataAttribute");

        let data = new MyGradeData();

        data.sifraOcenObdobja = element.getAttribute("data-sifraocenobdobja");
        data.sifraZaposleni = element.getAttribute("data-sifrazaposleni");
        data.fingerprint = element.getAttribute("data-fingerprint");
        data.formTitle = element.getAttribute("data-formtitle");

        return data;
    }

    showRecordDetails(detailsData: any, title: string): void {
        global.ConsoleLogDegug("KEMyGradesGrid.showRecordDetails");

        this.keMyGradeView.showForm(detailsData, title);
    }
}






