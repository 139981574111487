﻿import { global, isUnDefined } from "../Common/saop-common";
import { SaopMessageDlg, SaopMsgDlgButton } from "../Components/saop-message-dlg";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopTranslationService } from "../Core/saop-translate";
import { SaopViewForm } from "../Core/saop-view-form";
import { KEAllEmployeesGradesView } from "./KEAllEmployeesGrades-view";
import { KEGradeCriterion } from "./KEGradeCriterion";

class GradesPeriodDetailsData {
    sifraOcenObdobja: string;
    isStatusActive: boolean;
    fingerprint: string;
}

export class KEGradePeriodView extends SaopViewForm {
    private viewId: string;
    private libName: string;
    private _loadingSpinnerId: string = "gradePeriodDetailsLoading";
    private _buttonCloseId: string = "btnClose";
    private _keGradeCriterion: KEGradeCriterion;
    public keAllEmployeesGradesView: KEAllEmployeesGradesView;
    public formTranslationService: SaopTranslationService;

    constructor(viewId:string,libName:string = ""){
        super(viewId, libName);

        this.viewId = viewId;
        this.libName = libName; 

        this.setDictionary(); 
        this.title = "";
        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "KeGrade/GetGradePeriodDetails";
        this.deleteRecordEndpoint = ""; 

        this._keGradeCriterion = new KEGradeCriterion(viewId, libName + "._keGradeCriterion", "#" + this._loadingSpinnerId);
        this.formTranslationService = new SaopTranslationService();
    }

    setDictionary(){
        this.setTerminologyList();
    } 

    public afterViewShowSuccess(sender:any):void{
        this.LoadViewEvents();
    }

    public LoadViewEvents ():void{
        let _viewDiv = $(this.id);
        if (_viewDiv.length == 0) {
            return;
        }
    }

    showForm(data: any, title: string): void {
        global.ConsoleLogDegug("KEGradePeriodView.showForm");

        this.title = title;
        this.recordDetails(data);
        this.SetToFullScreen(); 

        this.viewShowComplete = this.initForm.bind(this);
    }

    initForm(): void {
        global.ConsoleLogDegug("KEGradePeriodView.initForm");

        // prevajanje
        this.setFormTerminologyList();

        this._keGradeCriterion.init();

        this.keAllEmployeesGradesView = new KEAllEmployeesGradesView("#keGradesAdministrationView002", this.libName + ".keAllEmployeesGradesView")
        this.keAllEmployeesGradesView.fullScreen = true;

        this.initEventListners();
    }

    private setFormTerminologyList() {
        let terminology = ["Opozorilo", "Ali ste prepričani, da želite nadaljevati? Če je ocenjevanje zaključeno ocen ni mogoče več urejati."];

        this.formTranslationService.setTranslationsList(terminology);
    }

    initEventListners() {
        global.ConsoleLogDegug("KEGradePeriodView.initEventListners");

        let _this = this;

        // prikaz full screen ko klikne na vrstico
        $("body").off("click", this.viewId + " #concludeGradePeriod");
        $("body").on("click", this.viewId + " #concludeGradePeriod", function () {
            let data = _this.getDataAttribute();

            if (!isUnDefined(data.sifraOcenObdobja)) {
                _this.showConcludeGradePeriodDialog(data);
            }
        });

        $("body").off("click", this.viewId + " #btnOcene");
        $("body").on("click", this.viewId + " #btnOcene", function () {
            let data = _this.getDataAttribute();
            var baseTitle = $(this).attr("data-editocenetitle");

            if (!isUnDefined(data.sifraOcenObdobja)) {
                var fullTitle = baseTitle + ": " + _this.title;
                _this.showAllEmployeesGrades({ sifraOcenObdobja: data.sifraOcenObdobja, isStatusActive: data.isStatusActive, fingerprint: data.fingerprint }, fullTitle);
            }
        });
    }

    getDataAttribute(): GradesPeriodDetailsData {
        global.ConsoleLogDegug("KEGradePeriodView.getDataAttribute");

        let data = new GradesPeriodDetailsData();

        data.sifraOcenObdobja = $(this.viewId + " #hiddenSifraOcenObdobja").val().toString();
        data.fingerprint = $(this.viewId + " #hiddenFingerprint").val().toString();
        let isStatusActive = $(this.viewId + " #hiddenIsStatusActive").val().toString().toLowerCase();
        data.isStatusActive = isStatusActive === "true" ? true : false;

        return data;
    }

    showConcludeGradePeriodDialog(data: any): void {
        global.ConsoleLogDegug("KEGradePeriodView.showConcludeGradePeriodDialog");

        let title = this.formTranslationService.translate("Opozorilo");
        let message = this.formTranslationService.translate("Ali ste prepričani, da želite nadaljevati? Če je ocenjevanje zaključeno ocen ni mogoče več urejati.");

        let saopMessageDlg = new SaopMessageDlg(data)
        saopMessageDlg.onYes = this.concludeGradePeriod.bind(this);
        saopMessageDlg.Show(message, title, 1, [SaopMsgDlgButton.mbYes, SaopMsgDlgButton.mbNo]);
    }

    concludeGradePeriod(data: GradesPeriodDetailsData): void {
        global.ConsoleLogDegug("KEGradePeriodView.concludeGradePeriod");

        let _this = this;
        let params = { sifraOcenObdobja: data.sifraOcenObdobja, isStatusActive: data.isStatusActive, fingerprint: data.fingerprint };

        let saopServicesCore = new SaopServicesCore();
        saopServicesCore.afterDone = function (response) {
            if (response.success) {
                alertify.message(response.alertMessage);
                // zapremo okno
                $(_this.viewId + " #" + _this._buttonCloseId).click();
                // poklice se metodo, da se lahko v extended klasu izvede osvezitev grida
                _this.refreshData(response);
            } else {
                alertify.error(response.alertMessage);
            }
        };
        saopServicesCore.ExecuteAction({
            action: "KeGrade/ConcludeGradePeriod"
            , data: params
            , type: "POST"
        });
    }

    showAllEmployeesGrades(data: any, fullTitle: string): void {
        global.ConsoleLogDegug("KEGradePeriodView.showAllEmployeesGrades");

        this.keAllEmployeesGradesView.showForm(data, fullTitle);
    }
}






