﻿import { global, isUnDefined, isEmpty } from "../Common/saop-common";
import { SaopWfAction } from "../Core/saop-view-form";
import { SaopMultiselectOptions } from "../Components/saop-multiselect";
import { SaopSelectDropDown } from "../Components/saop-select-dropdown";
import { OPNNalogeView } from "../OPN/OPNNaloge-view";
import { SaopServicesCore, SaopServicesCoreParams } from "../Core/saop-services-core";

export class OPNNalogeSelect extends SaopSelectDropDown {
  // private __target:string;
  // private __caption:string;  
  private __libName:string;
  public _opnNalogeView:OPNNalogeView;

  constructor(libName:string = "", options:SaopMultiselectOptions = null) {
    global.ConsoleLogDegug("OPNNalogeSelect");
    super(libName,options);
    this.__libName = libName;   
    //
    this._opnNalogeView = new OPNNalogeView("#opnTasksPopupForm",this.__libName  + "._opnNalogeView");
    this._opnNalogeView.fullScreen = false;   
    this._opnNalogeView.afterSubmitSuccess = this.refreshDataAfterSubmit.bind(this);     
  } 

  init(target: string = "", caption = "" ){
    // this._target = target;
    // this._caption = caption;
    //
    if (target.length == 0){
      target = "SifraNaloge";
    }
    if (caption.length == 0){
      caption = "Naloge";
    }
    super.init(target, caption);
    //
  }

  addRecord(){
    let _fullTitle = this._opnNalogeView.translationService.translate("Naloge");
    this._opnNalogeView.fullTitle = _fullTitle;
    this._opnNalogeView.showViewForm(SaopWfAction.wfaAddRecord,{ });     
  }  

  editRecord(_sifraNaloge:string){
    let _fullTitle = this._opnNalogeView.translationService.translate("Naloge");
    this._opnNalogeView.fullTitle = _fullTitle;
    this._opnNalogeView.showViewForm(SaopWfAction.wfaEditRecord,{ sifraNaloge: _sifraNaloge, fullView: false });     
  }  

  public onAddBtnClick(sender:any):void{
      global.ConsoleLogDegug("onAddBtnClick...SifraLokacije");
      this.addRecord();
  }  

  public onEditBtnClick(sender:any):void{
      global.ConsoleLogDegug("onEditBtnClick...SifraLokacije");
      let _sifraNaloge = this.selectedCB.val();
      this.editRecord(_sifraNaloge);    
  }  

  public refreshDataAfterSubmit(result: any): void {
    global.ConsoleLogDegug("submit.afterExecuteSuccess");
    //
    if ( (isUnDefined(result.success) == false) && (result.success == true) ) {
      let _sifraNaloge = "";
      if (isUnDefined(result.data) == false) {
        let jsonData = JSON.parse(result.data);
        _sifraNaloge = jsonData.SifraNaloge;
      }    
      //
      let _servicesParams:SaopServicesCoreParams = {
        action: "OpnTasks/GetList"
        , data: {sifraNaloge:_sifraNaloge}
        , type: "GET"
      };
  
      this.getDropDownItemsData(_servicesParams, _sifraNaloge);
    } 
  }

  //
}






