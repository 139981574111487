﻿import { SaopViewForm } from "../Core/saop-view-form";

export class SaopAbsenceConfirmationRejectView extends SaopViewForm {
    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);
        this.setDictionary();  
        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "AbsenceConfirmation/GetRejectedForm";
        this.deleteRecordEndpoint = "";
    }   

    setDictionary() {
        this.setTerminologyList();
    }  
}


