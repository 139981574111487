﻿import { global } from "../Common/saop-common";
import { SaopWfAction } from "../Core/saop-view-form";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import { OPNPotniNalogiView } from "../OPN/OPNPotniNalogi-view";
import { SaopServicesCore } from "../Core/saop-services-core";

export class OPNPotniNalogiArhivGrid {
  private libName:string;
  private _saopServicesCore: SaopServicesCore;
  //
  public webGrid2:SaopWebGrid;
  public opnPotniNalogiFullView:OPNPotniNalogiView;

  constructor(libName: string) {
    this.libName = libName;
    this._saopServicesCore = new SaopServicesCore();
  }  

  initView(){
    this.initWebGrid2();
    this.initWebGrid2Listners();  
    this.initEditView();
  }

  editRecord(letoPotnegaNaloga:string, stevilkaPotnegaNaloga:string){
    let _fullTitle = this.opnPotniNalogiFullView.translationService.translate("Uredi: Potni nalog")+" " +letoPotnegaNaloga+"-"+stevilkaPotnegaNaloga;
    this.opnPotniNalogiFullView.fullTitle = _fullTitle;
    this.opnPotniNalogiFullView.showViewForm(SaopWfAction.wfaEditRecord,{ letoPotnegaNaloga: letoPotnegaNaloga, stevilkaPotnegaNaloga: stevilkaPotnegaNaloga, fullView: true });     
  }

  initWebGrid2(){
    let wgId = "#OPNPotniNalogiGridArchive";
    let captionToolbar = $(wgId).attr("caption-toolbar")!;
    //
    this.webGrid2 = new SaopWebGrid(this.libName+".webGrid2");
    let gridProperties: SaopWebGridProperties;
    let gridToolbar:SaopWebGridToolbar;
    let dataTable:SaopWebDataTable;
    let toolbarItemsTemplateId = $(wgId).attr("toolbar-items-templateId");
    //
    gridToolbar = {id:"saopWebGridTB2",caption:captionToolbar, itemsTemplateId:toolbarItemsTemplateId};

    dataTable = { enabled:true,columnDefsSettings:[{orderable: true, targets:  "no-sort"}],order: [[ 2, "desc" ], [ 3, "desc" ]],};

    gridProperties = {
        gridId:wgId
        ,togglePanel:{enabled:false}
        ,toolbar:gridToolbar
        ,dataTable:dataTable
      };        
    //
    this.webGrid2.init(gridProperties);        
  }

  initWebGrid2Listners(){
    let _this = this;
    //
    let _SearchValue2 = $('#collapsesaopWebGridTB2').find('#SearchValue2');  
    if (_SearchValue2.length > 0) {
      _SearchValue2.off("change");
      _SearchValue2.on("change",_this.onSearchValue2Change.bind(_this));
    }
    //
    $("body").off("click", "#OPNPotniNalogiGridArchive td:not(.td-action)");
    $("body").on("click", "#OPNPotniNalogiGridArchive td:not(.td-action)", function () {
        var data = $(this).closest("tr");
        let letoPotnegaNaloga = data.attr("data-leto")!;
        let stevilkaPotnegaNaloga = data.attr("data-stevilka")!;
        _this.editRecord(letoPotnegaNaloga,stevilkaPotnegaNaloga);
    }); 
    
    $(".OPNPotniNalogiGridArchive_edit").off("click");
    $(".OPNPotniNalogiGridArchive_edit").on('click', function(row){
        global.ConsoleLogDegug("OPNPotniNalogiGridArchive_edit.click");
        $('#detailsOPNPotniNalogiForm').remove();
        let _btnEdit = row.currentTarget;
        let btnEnabled = _btnEdit.getAttribute("data-enabled")!;
        if (btnEnabled.toString().toLowerCase() == "true") {
            let letoPotnegaNaloga = _btnEdit.getAttribute("data-LetoPotnegaNaloga")!;
            let stevilkaPotnegaNaloga = _btnEdit.getAttribute("data-StevilkaPotnegaNaloga")!;
            _this.editRecord(letoPotnegaNaloga,stevilkaPotnegaNaloga);
        }            
    });        
  }

  initEditView(){
    this.opnPotniNalogiFullView = new OPNPotniNalogiView("#opnPotniNalogiView001",this.libName+".opnPotniNalogiFullView");
    this.opnPotniNalogiFullView.fullScreen = true;   
    this.opnPotniNalogiFullView.refreshData = this.refreshGridData.bind(this);
  } 

  refreshData(htmlContent:string){
    this.webGrid2.replaceGridRows(htmlContent);
    this.initWebGrid2Listners();
  }

  refreshGridData():void {
    let _this = this;

    let _SearchValue2 = $('#collapsesaopWebGridTB2').find('#SearchValue2');
    let _letoPotnegaNaloga = "";
    if (_SearchValue2.length > 0) {
      _letoPotnegaNaloga = _SearchValue2.val() as string;
    }

    this._saopServicesCore.afterDone = function(response){
      //#ToDo#
      //_this.opnPotniNalogiView.refreshElementData(response,"[data-stevilka=\"6\"]");
      _this.refreshData(response);
      //_this.webGrid1.refreshData();
      //_this.initWebGrid1Listners();
    };
    this._saopServicesCore.ExecuteAction({
        action:"TravelOrder/indexArchive" 
        ,data: {letoPotnegaNaloga : _letoPotnegaNaloga}
        ,type:"GET"
    });     
  }

  // onOdobrenaUporabaChange(e:any):void{
  //   let _selectedValue = e.value;  
  //   let _registrskaStevilkaPredlog = $('#RegistrskaStevilkaPredlog');
  //   if (_selectedValue == '1') {
  //     _registrskaStevilkaPredlog.show();
  //   } else {
  //     _registrskaStevilkaPredlog.hide();
  //   }
  // }

  onSearchValue2Change(e:any):void{
    this.refreshGridData();
  }  

  //
}






