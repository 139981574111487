﻿import { global } from "../Common/saop-common";
import { SaopWebDataTable, SaopWebGrid, SaopWebGridProperties, SaopWebGridToolbar, SaopWebGridToolbarButton } from "../Components/saop-grid";
import { SaopServicesCore } from "../Core/saop-services-core";

class DataAttributes {
    sifraKriterija: string;
    opis: string;
}

export class KEGradeCriterion {
    private _viewId: string;
    private _libName: string;
    private _spinnerDivId: string

    private webGridId: string = "#gradesCriterionGrid";
    private _rowDetailsPrefixId: string = "#detailRow";
    private _rowDetailsDivPrefixId: string = "#detailRowDiv";

    public webGrid: SaopWebGrid;

    constructor(viewId: string, libName: string = "", spinnerDivId: string = null) {
        this._viewId = viewId;
        this._libName = libName; 
        this._spinnerDivId = spinnerDivId;
    }  

    init() {
        global.ConsoleLogDegug("KEGradeCriterion.init");

        this.initWebGrid();
    }

    initWebGrid() {
        global.ConsoleLogDegug("KEGradeCriterion.initWebGrid");

        this.webGrid = new SaopWebGrid(this._libName + ".webGrid");
        let gridProperties: SaopWebGridProperties;
        let dataTable: SaopWebDataTable;

        dataTable = { enabled: true, ordering: false };

        gridProperties = {
            gridId: this.webGridId
            , dataTable: dataTable
        };
        //
        this.webGrid.init(gridProperties);

        this.initWebGridListners();
    }

    initWebGridListners() {
        global.ConsoleLogDegug("KEGradeCriterion.initWebGridListners");

        let _this = this;

        // prikaz dogodkov zaposlenega po vrsti dogodkov (sum ure)
        $("body").off("click", this._viewId + " " + this.webGridId + " tr.saop-table-row-master td.td-action-details");
        $("body").on("click", this._viewId + " " + this.webGridId + " tr.saop-table-row-master td.td-action-details", function () {
            let selectedRow = $(this).closest("tr");
            let actionDetailsColumn = selectedRow.find("td").eq(0);
            let dataAttributesValues = _this.getDataAttributesValues(selectedRow);

            _this.showHideDetailsSubTable(selectedRow, actionDetailsColumn, dataAttributesValues);
        });
    }

    getDataAttributesValues(row: JQuery<HTMLTableRowElement>): DataAttributes {
        global.ConsoleLogDegug("KEGradeCriterion.getDataAttributesValues");

        let dataValues = new DataAttributes();
        
        dataValues.sifraKriterija = row.attr("data-sifrakriterija");
        dataValues.opis = row.attr("data-opis");

        return dataValues;
    }

    showHideDetailsSubTable(selectedRow: JQuery<HTMLTableRowElement>, actionDetailsColumn: JQuery<HTMLTableCellElement>, dataAttributesValues: DataAttributes): void {
        global.ConsoleLogDegug("KEGradeCriterion.showHideDetailsSubTable");

        let iconElement = actionDetailsColumn.find("span");

        let rowDetailsId = this._rowDetailsPrefixId + dataAttributesValues.sifraKriterija;
        let rowDetailsIdElement = $(this._viewId + " " + rowDetailsId);

        if (!this.isDetailsSubTableShowed(iconElement)) {
            // show details subtable
            // preverim, če tabela ze obstaja
            let detailRowDivId = this._rowDetailsDivPrefixId + dataAttributesValues.sifraKriterija;
            let detailRowTableElement = $(this._viewId + " " + detailRowDivId + " table");
            if (detailRowTableElement.length == 0) {
                // tabele ni, gremo na server po podatke za prikaz
                this.getDetailsSubTable(detailRowDivId, dataAttributesValues.opis);
            }
        }

        // prikazi/skrij bottom border
        selectedRow.toggleClass("saop-table-row-border-bottom-none")

        // prikazi/skrij details tabelo
        rowDetailsIdElement.toggleClass("saop-table-row-hidden");
        rowDetailsIdElement.toggleClass("saop-table-row-showed");

        // sprememba ikone
        iconElement.toggleClass("saop-img-right");
        iconElement.toggleClass("saop-img-down");
    }

    isDetailsSubTableShowed(iconElement: JQuery<HTMLSpanElement>): boolean {
        global.ConsoleLogDegug("KEGradeCriterion.isDetailsSubTableShowed");

        return iconElement.hasClass("saop-img-down");
    }

    getDetailsSubTable(detailRowDivId: string, opis: string): void {
        global.ConsoleLogDegug("KEGradeCriterion.getDetailsSubTable");

        let _this = this;
        let saopServicesCore = new SaopServicesCore();
        let params = { opis: opis };

        saopServicesCore.afterDone = function (response) {
            $(_this._viewId + " " + detailRowDivId).html(response);
        };
        saopServicesCore.ExecuteAction({
            action: "KeGradeCriterion/GetGradeCriterionTableRowDetails"
            , data: params
            , type: "GET"
            , spinnerDivId: _this._spinnerDivId
        });
    }
}






