﻿/// <reference path="../@types/data-tables/types.d.ts" />
import { isUnDefined, global, formatDate } from "../Common/saop-common";
import { SaopTranslationService } from '../Core/saop-translate';


declare global {
  interface JQuery {
    treeTable(opts?: any):any;
  }
}

export interface SaopTreeTableParams {
  id:string;
  url:string
  columns: any;
  order:any;
} 

export class SaopTreeTable {
  private _translationService: SaopTranslationService;

  public libName: string;  

  constructor(libName: string) {
    this.libName = libName;
    this._translationService = new SaopTranslationService();
  }  

  setTerminologyList(){
    let _terminology: string[] = ['Ni podatkov za prikaz.','Urejanje stolpcev','Ponastavi stolpce','Shrani','Prekliči'];
    this._translationService.setTranslationsList(_terminology);
  }

  init(params:SaopTreeTableParams) {

    let _Msg_emptyTable = this._translationService.translate("Ni podatkov za prikaz.")

    $(params.id).treeTable({
      ajax: {
        url: params.url
        ,type:"GET",
        dataSrc:''
      },
      orderCellsTop: false,
      "paging": false,
      "autoWidth": true,     
      "collapsed": false,
      "ordering": false,
      "orderable": false,
      "columns": params.columns,
      initComplete: function () {
        $(params.id+"_filter").remove();
        $(params.id+"_info").remove();  
        $(params.id+"_length").remove();  
        $(params.id+"_paginate").remove();  
        $(params.id).css('width', '');
        //
      }      
      ,order: params.order
      ,language : {emptyTable:_Msg_emptyTable,zeroRecords:_Msg_emptyTable}
    });
  }
}






