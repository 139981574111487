﻿
export class SaopMessageBanner {
    private _messagesDivId: string = "#messagesDivId";
    private _messageBannerId: string = "#saopMessageBanner1";

    show(messages: string[]) {

        let htmlMessageString = "<ul>";
        messages.forEach((message) => {
            htmlMessageString = htmlMessageString + "<li>" + message + "</li>";
        });
        htmlMessageString = htmlMessageString + "</ul>";

        $(this._messagesDivId).html(htmlMessageString);
        $(this._messageBannerId).removeClass('message-banner-hide');
    }

    //
}





