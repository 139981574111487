import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopViewForm, SaopWfAction } from "../Core/saop-view-form";
import  {LIKVNivojiPotrjevanjaGrid } from "../LIKV/LIKVNivojiPotrjevanja-grid"

export class LIKVNivojiPotrjevanjaModal extends SaopViewForm {
  private _likvNivojiPotrjevanjaGrid:LIKVNivojiPotrjevanjaGrid;

  constructor(viewId:string,libName:string = ""){
    super(viewId,libName);
    this.setDictionary();  
    this.title = this.translationService.translate("Nivoji potrjevanja");
    this.fullTitle = this.title;
    this.addRecordEndpoint = "";
    this.editRecordEndpoint = "LikvConfirmations/IndexUncDocuments";
    this.deleteRecordEndpoint = ""; 
    //
    this.afterViewShowSuccess = this.initView;
    this.afterSubmitSuccess = this.initView;
    //
  }

  setDictionary(){
      let dictionary:string[] = ["Nivoji potrjevanja"];
      this.setTerminologyList(dictionary);
  } 

  public showForm(data:any):void{
    this.showViewForm(SaopWfAction.wfaEditRecord, data);
  }

  public initView():void{
    let _data = this.getData();
    this._likvNivojiPotrjevanjaGrid = new LIKVNivojiPotrjevanjaGrid(this._libName);
    this._likvNivojiPotrjevanjaGrid.initView(_data.idVrste);
  }

  //
}

