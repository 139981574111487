﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopUploadFiles } from "../Components/saop-upload-files";

export class SaopSplDocumentView {
    public saopUploadFiles:SaopUploadFiles;

    constructor(viewId: string, libName: string = "") {
        this.saopUploadFiles = new SaopUploadFiles(viewId,libName+".saopUploadFiles");
        this.saopUploadFiles.afterSubmitSuccess = this.afterSubmitSuccess;
    }

    initView(): void {
        global.ConsoleLogDegug("SaopSplDocumentView.initView");
        // form nima sidebar right, zato se dodan class, da btn ni viden tudi v mobile verziji
        $("#btnShowSidebarr").addClass("d-none-mobile");
        this.bindControls();
    }

    bindControls() {
        global.ConsoleLogDegug("SaopSplDocumentView.bindGridEvents");
        let _this = this;
        $(".btnUploadSPLDocuments").off("click");
        $(".btnUploadSPLDocuments").on('click', function (row) {
            global.ConsoleLogDegug("SaopSplDocumentView.btnUploadSPLDocuments.click");
            let title = row.target.getAttribute("data-formtitle");
            _this.saopUploadFiles.viewTitle = title;
            _this.saopUploadFiles.initView({controller:"SPLDocument",action:"GetUploadDocuments"});
        });
    }

    public afterSubmitSuccess(result:any): void {
        global.ConsoleLogDegug("afterExecuteSuccess");
        if(result.success)
            window.location.href = result.redirectTo;
        
    }  

    //
}


