﻿import { SaopViewForm } from "../Core/saop-view-form";

export class NDKInternalOrdersView extends SaopViewForm {

    constructor(viewId:string,libName:string = ""){
        super(viewId, libName);
        this.setDictionary(); 
        this.title = this.translationService.translate("Interno naročilo");
        this.addRecordEndpoint = "InternalOrder/CreateInternalOrder";
        this.editRecordEndpoint = "InternalOrder/EditInternalOrder";
        this.deleteRecordEndpoint = "InternalOrder/DeleteInternalOrder"; 
    }

    setDictionary(){
        let dictionary: string[] = ["Interno naročilo","Brisanje","Ali ste prepričani, da želite izbrisati interno naročilo?"];
        this.setTerminologyList(dictionary);
    } 
  
    public deleteRecord(deleteData:any):void{
        let deletTitle = this.translationService.translate("Brisanje");
        let deleteMessage = this.translationService.translate("Ali ste prepričani, da želite izbrisati interno naročilo?");    
        super.deleteRecord(deletTitle,deleteMessage,deleteData);
    }  

    public afterViewShowSuccess(sender:any):void{
        this.LoadViewEvents();
    }

    public LoadViewEvents ():void{
        let _viewDiv = $(this.id);
        if (_viewDiv.length == 0) {
            return;
        }
    }

  //
}






