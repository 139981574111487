﻿import { global } from "../Common/saop-common";
import { KEGradeCriterion } from "./KEGradeCriterion";

export class KEMyGradeDetails {
    private viewId: string;
    private libName: string;
    private _loadingSpinnerId: string = "gradeDetailsLoading";
    private _keGradeCriterion: KEGradeCriterion;

    constructor(viewId: string, libName: string = "") {
        this.viewId = viewId;
        this.libName = libName; 

        this._keGradeCriterion = new KEGradeCriterion(viewId, libName + "._keGradeCriterion", "#" + this._loadingSpinnerId);
    }  

    initView() {
        global.ConsoleLogDegug("KEMyGradeDetails.initView");

        this._keGradeCriterion.init();
    }
}






