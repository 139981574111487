﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopRightSidebar } from '../Components/saop-right-sidebar';
import { OPNPotniNalogiGrid, OPNPotniNalogiGridOptions } from "../OPN/OPNPotniNalogi-grid";

export class DashboardTravelOrderView {
    private _libName:string;
    private _saopServicesCore: SaopServicesCore;
    private _addTravelOrderId: string = "#addTravelOrder";
    //
    public opnPotniNalogiGrid: OPNPotniNalogiGrid;
    public rightSidebar: SaopRightSidebar;

    constructor(libName: string = "") {
        this._libName = libName;
        //
        this._saopServicesCore = new SaopServicesCore();

        let gridOptions = new OPNPotniNalogiGridOptions();
        gridOptions.dashboard = true;
        gridOptions.showToolbar = false;
        gridOptions.showFilter = false;
        this.opnPotniNalogiGrid = new OPNPotniNalogiGrid(this._libName + ".opnPotniNalogiGrid", gridOptions);
        this.opnPotniNalogiGrid.afterRefreshDataAfterEditViewExternal = this.afterRefreshDataAfterEditView.bind(this);
        this.opnPotniNalogiGrid.refreshData = this.refreshGridData.bind(this);

        this.rightSidebar = new SaopRightSidebar();
    }

    refreshGridData(): void {
        global.ConsoleLogDegug("DashboardTravelOrderView.refreshGridData");

        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            $("#card7 .card-body").html(response);
            //_this.opnPotniNalogiGrid.initView();
            //_this.opnPotniNalogiGrid.initWebGrid1();
            _this.opnPotniNalogiGrid.initWebGrid1Listners();
            _this.opnPotniNalogiGrid.initEditView();
            _this.bindAddEvent();
        };
        this._saopServicesCore.ExecuteAction({
            action:"Dashboard/TravelOrderPartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingcard7"
        });

        this.rightSidebar.refreshRegOPNPotniNalogiSummary("#opnPotniNalogiSummary", "#saopLoadingSidebarr");
    }  

    afterRefreshDataAfterEditView(): void {
        global.ConsoleLogDegug("DashboardTravelOrderView.afterRefreshDataAfterEditView");

        this.rightSidebar.refreshRegOPNPotniNalogiSummary("#opnPotniNalogiSummary", "#saopLoadingSidebarr");
    }

    bindAddEvent() {
        global.ConsoleLogDegug("DashboardTravelOrderView.bindGridEvents");
        let _this = this;

        $(this._addTravelOrderId).off("click");
        $(this._addTravelOrderId).on('click', function (row) {
            global.ConsoleLogDegug("DashboardTravelOrderView._addTravelOrder.click");
            _this.opnPotniNalogiGrid.addRecord();
        });
    }
   
    //
}


