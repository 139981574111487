﻿import { global,isEmpty,getSiteAbsoluteURL, isUnDefined } from "../Common/saop-common";
import { SaopViewSpinner } from '../Components/saop-view-spinner'

export interface SaopServicesCoreParams {
  action:string;
  async?:boolean
  type?:string;
  data?:any;
  spinnerDivId?:string;
  // contentType?: string | false | undefined;
  // dataType?: 'xml' | 'html' | 'script' | 'json' | 'jsonp' | 'text' | string | undefined;
  // processData?: boolean | undefined;
}

export enum SaopServicesCoreResult {
  success = 1
  , error = 2
}

export enum SaopServicesCoreHTTPResultStatusCode {
  ok = 200,
  unauthorized = 401,
  internalServerError = 500
}

export interface SaopServicesCoreResponse {
  response: SaopServicesCoreResult;
  data: any;
};

// function syncDelay(milliseconds:any){
//   var start = new Date().getTime();
//   var end=0;
//   while( (end-start) < milliseconds){
//       end = new Date().getTime();
//   }
//  }

export class SaopServicesCore {
  private _saopViewSpinner:SaopViewSpinner = new SaopViewSpinner();
  private _params:SaopServicesCoreParams;
  private _defaultParams:SaopServicesCoreParams;
  //  
  //public _result:SaopServicesCoreResponse = {response:null,data:null};  

  constructor(){
    this._defaultParams = {
      action:""
      ,type:"GET"
      ,async:true
      ,data:null
      ,spinnerDivId:""
    };
  }

  private onDone(result:any){
    global.ConsoleLogDegug("SaopServicesCore.onDone");
    this.afterDone(result);
    //this._saopViewSpinner.hideLoadingSaop(this._params.spinnerDivId);      
  }

  afterDone(result:any):void{
    global.ConsoleLogDegug("SaopServicesCore.afterDone");
  }  

  private onSuccess(result:any):void{
    global.ConsoleLogDegug("SaopServicesCore.success");
    let _result:SaopServicesCoreResponse = {response:null,data:null}; 
    if (typeof result.success === "undefined" || result.success === null) {
      _result.response = SaopServicesCoreResult.success;
    } else {
      _result.response = result.success == true ? SaopServicesCoreResult.success : SaopServicesCoreResult.error;
    }
    
    _result.data = result;
    this.afterSuccess(_result);
  }

  afterSuccess(result:any):void{
    global.ConsoleLogDegug("SaopServicesCore.afterSuccess");
  }  

  private onError(result:any):void{
    global.ConsoleLogDegug("SaopServicesCore.error");
    
    if (result.status == SaopServicesCoreHTTPResultStatusCode.unauthorized) {
      // redirect to login
      this.unAuthorizedRedirectToLoginPage();
      return;
    }

    if (result.status = SaopServicesCoreHTTPResultStatusCode.internalServerError && !isUnDefined(result.responseText)) {
      alert("Internal error!");
    }

    let _result:SaopServicesCoreResponse = {response:null,data:null}; 
    _result.response = SaopServicesCoreResult.error;
    _result.data = result;
    this.afterError(_result);
  }

  afterError(result:any):void{
    global.ConsoleLogDegug("SaopServicesCore.afterSuccess");
  } 

  ExecuteAction(params:SaopServicesCoreParams):void {
    global.ConsoleLogDegug("SaopServicesCore.ExecuteAction");
    let options = { ...this._defaultParams, ...params };
    params = options;
    this._params = params;
    let _this = this;
    let _ajaxAction = getSiteAbsoluteURL(params.action);

    //@Url.Action("crearCampManual", "DPS")

    try {
      $.ajax({
        async: params.async,
        url: _ajaxAction,
        type: !isEmpty(params.type) ? params.type : "POST",
        data: params.data,
        // contentType: params.contentType == null ? 'application/x-www-form-urlencoded; charset=UTF-8' : params.contentType,
        // dataType: params.type == null ? 'Intelligent Guess (xml, json, script, or html)' : params.type,  
        // processData : params.processData == null ? true : params.processData, 
        beforeSend: function () { _this._saopViewSpinner.showLoadingSaop(params.spinnerDivId); },
        complete: function () { _this._saopViewSpinner.hideLoadingSaop(params.spinnerDivId); },
        success: this.onSuccess.bind(_this),
        error: this.onError.bind(_this)
      }).done(function (result) {
        global.ConsoleLogDegug("Done...");
        _this.onDone(result);
      });
    }
    catch(err) {
      alert("Internal error!");
    }    

  
  }

  ExecuteBlobAction(params: SaopServicesCoreParams): void {
    global.ConsoleLogDegug("SaopServicesCore.ExecuteBlobAction");
    let options = { ...this._defaultParams, ...params };
    params = options;
    this._params = params;
    let _this = this;
    let _ajaxAction = getSiteAbsoluteURL(params.action);

    try {
      $.ajax({
        type: "POST",
        url: _ajaxAction,
        data: params.data,
        xhrFields: {
          responseType: 'blob' // to avoid binary data being mangled on charset conversion
        },
        success: function (blob, status, xhr) {
            // check for a filename
            var filename = "";
            var disposition = xhr.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }

            //if (typeof window.navigator.msSaveBlob !== 'undefined') {
            //    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            //    window.navigator.msSaveBlob(blob, filename);
            //} else
            //{
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);

            if (filename) {
                // use HTML5 a[download] attribute to specify filename
                var a = document.createElement("a");
                // safari doesn't support this yet
                if (typeof a.download === 'undefined') {
                    window.location.href = downloadUrl;
                } else {
                    a.href = downloadUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                }
            } else {
                window.location.href = downloadUrl;
            }

            setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
            //}
        },
        error: this.onError.bind(_this)
        }).done(function (result) {
          global.ConsoleLogDegug("Done...");
          _this.onDone(result);
      });
    }
    catch (err) {
      alert("Internal error!");
    }
  }
  
  public handleSubmitError(response: any): void {
    global.ConsoleLogDegug("SaopServicesCore.HandleSubmitError");

    if (response.status == SaopServicesCoreHTTPResultStatusCode.unauthorized) {
        this.unAuthorizedRedirectToLoginPage();
        return;
    }

    // display error
    console.log("response", response);
    alert(response.statusText);
  }

  public unAuthorizedRedirectToLoginPage(): void {
    global.ConsoleLogDegug("SaopServicesCore.redirectToLoginPage");

    window.location.reload();
  }
 
  //
}
