﻿import { SaopViewForm } from "../Core/saop-view-form";
import { global } from "../Common/saop-common";


export class SaopRegAttendanceAllEmployeesExcessHoursView extends SaopViewForm {
    private _formId: string;
    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);

        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "REGAttendanceAllEmployeesExcessHours/EditAllEmployeesExcessHours";
        this.deleteRecordEndpoint = ""; 

    }
}


