﻿
export interface SaopInputWFLProperties {
    id:string;
    placeholder:string;
    caption:string;
}

export class SaopselectWFL {
    private _inputTemplate:string;
    constructor(properties:SaopInputWFLProperties) {
        this._inputTemplate = 
        `
        <span class="has-float-label saop-dropdown selectElement">
            <select class="form-control shadow-none saop-control saop-child-select" data-chosen="T" id="${properties.id}" name="${properties.id}" tabindex="0" style="height: 30px;padding: 0.075rem 0.75rem;">
                <option value=""/>
            </select>
            <label class="saop-floating-label" for="${properties.id}">${properties.caption}</label>
            <i class="select-dropdown saop-img-down si-s"/>
        </span>     
        `;                    
    }

    getElement():JQuery<HTMLElement>{
        let _element = $(this._inputTemplate);
        return _element;
    }

    getInnerHtml():string{
        return this._inputTemplate;
    }

}





