﻿import { global, isUnDefined, setModalFormZIndex } from "../Common/saop-common";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebGridToolbarButton,SaopWebDataTable} from "../Components/saop-grid";
import { SaopWfAction } from "../Core/saop-view-form";
import { OPNPotniNalogiView } from "../OPN/OPNPotniNalogi-view";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopRightSidebar,SaopRightSidebarView } from '../Components/saop-right-sidebar';

export class OPNPotniNalogiGridOptions {
    dashboard: boolean = false;
    showToolbar: boolean = true;
    showFilter: boolean = true;
}

export class OPNPotniNalogiGrid {
    private libName: string;
    private _gridOptions: OPNPotniNalogiGridOptions;
    private _saopServicesCore: SaopServicesCore;
    //
    public webGrid1:SaopWebGrid;
    public opnPotniNalogiMinView:OPNPotniNalogiView;
    public opnPotniNalogiFullView:OPNPotniNalogiView;
    public rightSidebar: SaopRightSidebar;

    constructor(libName: string, gridOptions: OPNPotniNalogiGridOptions = null) {
        if (isUnDefined(gridOptions)) {
            gridOptions = new OPNPotniNalogiGridOptions();
        }
        this._gridOptions = gridOptions;

        this.libName = libName;
        this._saopServicesCore = new SaopServicesCore();
    }

    initView() {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.initView");

        this.initWebGrid1();
        this.initWebGrid1Listners();  
        this.initEditView();
        this.initRightSidebar();
    }

    initRightSidebar() {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.initRightSidebar");

        this.rightSidebar = new SaopRightSidebar();
        this.rightSidebar.bindCarouselDiv("#right-sidebar-ajax",SaopRightSidebarView.TravelOrders);
        this.rightSidebar.refreshRegOPNPotniNalogiSummary("#opnPotniNalogiSummary","#saopLoadingSidebarr");    
    }

    initWebGrid1() {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.initWebGrid1");

        let wgId = "#OPNPotniNalogiGrid";
        let captionToolbar = $(wgId).attr("caption-toolbar");
        let captionAddButton = $(wgId).attr("caption-addButton"); 
        //
        this.webGrid1 = new SaopWebGrid(this.libName+".webGrid1");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar:SaopWebGridToolbar;
        let toolbarButtons:SaopWebGridToolbarButton[] = [];
        let dataTable:SaopWebDataTable;
        //
        toolbarButtons.push({id:"addTo",caption:captionAddButton,onClickEvent:this.libName+".addRecord();"});        
        gridToolbar = {id:"saopWebGridTB1",caption:captionToolbar,buttons:toolbarButtons};
   
        dataTable = { enabled:true,columnDefsSettings:[{orderable: true, targets:  "no-sort"}],order: [[ 2, "desc" ], [ 3, "desc" ]],colSettings:true};

        gridProperties = {
            gridId:wgId
            ,togglePanel:{enabled:false}
            ,toolbar:gridToolbar
            ,dataTable:dataTable
            };        
        //
        this.webGrid1.init(gridProperties);   
    }

    initWebGrid1Listners() {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.initWebGrid1Listners");

        let _this = this;
        $("body").off("click", "#OPNPotniNalogiGrid td:not(.td-action)");
        $("body").on("click", "#OPNPotniNalogiGrid td:not(.td-action)", function () {
            var data = $(this).closest("tr");
            let letoPotnegaNaloga = data.attr("data-leto");
            let stevilkaPotnegaNaloga = data.attr("data-stevilka");
            _this.editRecord(letoPotnegaNaloga,stevilkaPotnegaNaloga);
        }); 
    
        $(".OPNPotniNalogiGrid_edit").off("click");
        $(".OPNPotniNalogiGrid_edit").on('click', function(row){
            global.ConsoleLogDegug("OPNPotniNalogiGrid_edit.click");
            $('#detailsOPNPotniNalogiForm').remove();
            let _btnEdit = row.currentTarget;
            let btnEnabled = _btnEdit.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let letoPotnegaNaloga = _btnEdit.getAttribute("data-LetoPotnegaNaloga");
                let stevilkaPotnegaNaloga = _btnEdit.getAttribute("data-StevilkaPotnegaNaloga");
                _this.editRecord(letoPotnegaNaloga,stevilkaPotnegaNaloga);
            }            
        });    

        $(".OPNPotniNalogiGrid_delete").off("click");
        $(".OPNPotniNalogiGrid_delete").on('click', function(row){
            global.ConsoleLogDegug("OPNPotniNalogiGrid_delete.click");
            let _btnDelete = row.currentTarget;
            let btnEnabled = _btnDelete.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let letoPotnegaNaloga = _btnDelete.getAttribute("data-LetoPotnegaNaloga");
                let stevilkaPotnegaNaloga = _btnDelete.getAttribute("data-StevilkaPotnegaNaloga");
                _this.opnPotniNalogiMinView.deleteRecord({ letoPotnegaNaloga: letoPotnegaNaloga, stevilkaPotnegaNaloga: stevilkaPotnegaNaloga });
            }
        });        
    }

    addRecord() {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.addRecord");

        $('#detailsOPNPotniNalogiFullForm').remove();
        this.opnPotniNalogiMinView.showViewForm(SaopWfAction.wfaAddRecord, null, null);
    }  

    editRecord(letoPotnegaNaloga: string, stevilkaPotnegaNaloga: string) {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.editRecord");

        let _fullTitle = this.opnPotniNalogiFullView.translationService.translate("Uredi: Potni nalog")+" " +letoPotnegaNaloga+"-"+stevilkaPotnegaNaloga;
        this.opnPotniNalogiFullView.fullTitle = _fullTitle;
        this.opnPotniNalogiFullView.showViewForm(SaopWfAction.wfaEditRecord,{ letoPotnegaNaloga: letoPotnegaNaloga, stevilkaPotnegaNaloga: stevilkaPotnegaNaloga, fullView: true });     
    }

    initEditView() {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.initEditView");

        this.opnPotniNalogiFullView = new OPNPotniNalogiView("#opnPotniNalogiView001",this.libName+".opnPotniNalogiFullView");
        this.opnPotniNalogiFullView.fullScreen = true;   
        this.opnPotniNalogiFullView.refreshData = this.refreshGridData.bind(this);
        //
        this.opnPotniNalogiMinView = new OPNPotniNalogiView("#opnPotniNalogiView002",this.libName+".opnPotniNalogiMinView");
        this.opnPotniNalogiMinView.fullScreen = false;   
        this.opnPotniNalogiMinView.refreshData = this.refreshGridData.bind(this);
    } 

    refreshData(htmlContent: string) {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.refreshData");

        if(!this._gridOptions.dashboard)
            this.webGrid1.replaceGridRows(htmlContent);

        this.initWebGrid1Listners();

        if (!this._gridOptions.dashboard)
            this.rightSidebar.refreshRegOPNPotniNalogiSummary("#opnPotniNalogiSummary","#saopLoadingSidebarr");  
    }

    refreshGridData(): void {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.refreshGridData");

        let _this = this;

        this._saopServicesCore.afterDone = function (response) {
            //#ToDo#
            //_this.opnPotniNalogiView.refreshElementData(response,"[data-stevilka=\"6\"]");
            _this.refreshData(response);
            //_this.webGrid1.refreshData();
            //_this.initWebGrid1Listners();
        };
        this._saopServicesCore.ExecuteAction({
            action: "TravelOrder/Index"
            , data: null
            , type: "GET"
        });  

    }

    public afterRefreshDataAfterEditViewExternal(sender: any): void {
        global.ConsoleLogDegug("OPNPotniNalogiGrid.afterRefreshDataAfterEditViewExternal");
    }
}






