﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopRegAbsenceEventsView } from "./saop-regAbsenceEvents-view";
import { SaopRegAbsenceCalendarEventsView } from "./saop-regAbsenceCalendarEvents-view";

export class SaopRegAbsenceMainView {
    public regAbsenceEventsView: SaopRegAbsenceEventsView;
    public regAbsenceCalendarEventsView: SaopRegAbsenceCalendarEventsView;

    constructor(libName: string = "") {
        this.regAbsenceEventsView = new SaopRegAbsenceEventsView("#regKeZapDopustiView0", libName + ".regAbsenceEventsView");
        this.regAbsenceEventsView.afterRefreshAllData = this.afterRefreshEventsData.bind(this);

        this.regAbsenceCalendarEventsView = new SaopRegAbsenceCalendarEventsView("#regKeZapDopustiView0", libName + ".regAbsenceCalendarEventsView");
        this.regAbsenceCalendarEventsView.afterRefreshAllData = this.afterRefreshCalendarData.bind(this);
    }

    initView(): void {
        global.ConsoleLogDegug("SaopRegAbsenceMainView.initView");
        $("#site-main").addClass("site-main-with-formSidebarr");
        this.regAbsenceEventsView.refreshAllData(false);
        this.regAbsenceCalendarEventsView.refreshAllData(false);
    }

    afterRefreshEventsData(): void {
        global.ConsoleLogDegug("SaopRegAbsenceMainView.afterRefreshEventsData");
        // po refresu "absence events tab" potrebno tudi refresh "calendar tab"
        this.regAbsenceCalendarEventsView.refreshAllData(false);
    }

    afterRefreshCalendarData(): void {
        global.ConsoleLogDegug("SaopRegAbsenceMainView.afterRefreshCalendarData");
        // po refresu "calendar tab" potrebno tudi refresh "absence events tab"
        this.regAbsenceEventsView.refreshAllData(false);
    }
}


