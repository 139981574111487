﻿/// <reference path="../@types/typings.d.ts" />

export class SaopUtils {
    checkCompatibility():boolean {
        return SaopCommon.checkCompatibility();
    }
}





